import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { list, remove, status } from '../../../api/Category'

/* Pagination */
import { Pagination } from '../../../components'

/* Helpers */
import { utils } from '../../../helpers'

/* SweetAlert */
import Swal from "sweetalert2"

/* React Router */
import { Link } from "react-router-dom"

/* Constants */
import { env } from '../../../constants'

/* Switch */
import Switch from "react-switch"

/* Permission */
import { Permission } from "../../Auth"


/* Page Categories */
class Categories extends Component {

    constructor() {
        super()

        this.state = {
            loading: true,
            data: [],
            page: 1,
            count: 0,

            permission: false
        }
    }


    componentDidMount = () => {
        this.load()
    }

    /* Load */
    load = (page = 1) => {

        this.setState({ page, loading: true })

        list({ page }).then(response => {
            if (response.status === 200) {
                this.setState({
                    data: response.data.categories,
                    count: response.data.pagination.totalItems,
                    loading: false
                })
            } else if (response.status === 403) {
                this.setState({ permission: true })
            }
        })
    }

    /* Draw Top */
    _top = () => {

        const { count } = this.state

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/category.png" alt="Category" />
                    <p>Категории</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

                <div className="content-create">
                    <Link className="content-create-button" to="/category/create">Добавить</Link>
                </div>

            </div>
        )
    }


    /* Remove */
    remove = async (item) => {

        Swal.fire({
            title: 'Вы действительно хотите удалить?',
            text: "Невозможно восстановить после удаления",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f44336',
            confirmButtonText: 'Да, удалить!',
            cancelButtonText: 'Нет'
        }).then((result) => {
            if (result.isConfirmed) {
                remove({ categoryID: item.id }).then(response => {
                    if (response.status === 200) {

                        const { data } = this.state

                        const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))

                        if (index > -1) {
                            data.splice(index, 1)
                            this.setState({ data })
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Успешна удалена',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Что-то пошло не так! Попробуйте позднее',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }).catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Что-то пошло не так! Попробуйте позднее',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }
        })
    }


    /* Show Image */
    image = img => {
        if (img) {
            Swal.fire({
                imageUrl: `${env.mediapoint}categories/${img}`,
                imageHeight: 300,
                imageAlt: 'Category',
                confirmButtonColor: '#000',
                confirmButtonText: 'Закрыть'
            })
        }
    }


    /* Change visible */
    change = (id, value) => {

        const { data } = this.state

        status({ categoryID: id, visible: value ? 1 : 0 }).then(response => {
            if (response.status === 200) {
                const index = data.findIndex(e => parseInt(e.id) === parseInt(id))

                if (index > -1) {
                    data[index].visible = value ? 1 : 0
                    this.setState({ data })
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Статус успешно изменен',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }


    /* Draw Content */
    _content = () => {

        const { data, loading, page } = this.state

        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        return (
            <div className="content-data-box">
                {data.map((item, index) =>
                    <div className="content-box-body" key={`${index}`}>
                        <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                        <div className="content-item">{utils.check(item.name)}</div>
                        <div className="content-item">{utils.check(item.slug)}</div>
                        <div className="content-item" onClick={() => this.image(item.image)}>
                            {item.image ? <img src={`${env.mediapoint}categories/${item.image}`} alt="Category" /> : "-"}
                        </div>
                        <div className="content-item content-buttons name">

                            <Switch
                                onChange={value => this.change(item.id, value)}
                                checked={parseInt(item.visible) === 1}
                                onColor="#b1e17a"
                                onHandleColor="#8bc34a"
                                offHandleColor="#fff"
                                offColor="#d7d7d7"
                                handleDiameter={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                height={20}
                                width={40}
                            />

                            <Link className="content-item-button" to={`/category/edit/${item.id}`}>Редактировать</Link>
                            <div className="content-item-button delete" onClick={() => this.remove(item)}>Удалить</div>
                        </div>
                    </div>
                )}
            </div>
        )
    }


    render = () => {

        const { count, page, permission } = this.state

        if (permission) {
            return <Permission />
        }

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">

                    <div className="content-box-head">
                        <div className="content-head number">#</div>
                        <div className="content-head">Название</div>
                        <div className="content-head">Key</div>
                        <div className="content-head">Картинка</div>
                        <div className="content-head name"></div>
                    </div>

                    {this._content()}

                    <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page)} />

                </div>

            </div>
        )
    }

}

export default Categories