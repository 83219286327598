import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { remove, act, export_file } from '../../api/Report'


/* SweetAlert */
import Swal from "sweetalert2"

/* React Router */
import { Link } from "react-router-dom"

/* Helpers */
import { utils } from '../../helpers'

/* Permission */
import { Permission } from "../Auth"

/* File download */
const FileDownload = require('js-file-download');

let filterTypes = {
    "username": "simple",
    "usertype": "simple",
    "totalOrderAmount": "simple",
    "totalIncomeAmount": "simple",
    "totalCashOrder": "simple",
    "totalPaymentTotal": "simple",
    "totalDebt": "simple",
    "usermaxdebt": "simple"
}

/* Page Products */
class Report extends Component {

    constructor() {
        super()

        this.state = {
            loading: true,
            data: [],
            page: 1,
            search: "",
            count: 0,

            permission: false,

            filterData: { key: null, type: null }
        }
    }


    componentDidMount = () => {
        this.load()
    }

    /* Load */
    load = (page = 1, text = '') => {

        const { filterData } = this.state

        this.setState({ page, loading: true })

        act({ page, search: text, filterData }).then(response => {
            if (response.status === 200) {
                console.log(response.data)
                this.setState({ data: response.data.data, loading: false })
            } else if (response.status === 403) {
                this.setState({permission: true})
            }
        })
    }

    /* Search action */
    search = text => {
        this.setState({ search: text })

        if (text !== "") {
            this.setState({ loading: true })

            if (this._timeout) {
                clearTimeout(this._timeout)
            }

            this._timeout = setTimeout(() => {
                this.load(1, text.trim())
            }, 1000)
        }
    }

    /* Export file */
    exportFile = () => {
        export_file().then(response => {
            if (response.status === 200) {
                FileDownload(response.data, 'report.pdf');
            }
        })
    }

    filterColumn = (type) => {

        let tempFilterTypes = {
            "username": "simple",
            "usertype": "simple",
            "totalOrderAmount": "simple",
            "totalIncomeAmount": "simple",
            "totalCashOrder": "simple",
            "totalPaymentTotal": "simple",
            "totalDebt": "simple",
            "usermaxdebt": "simple"
        }

        if (filterTypes[type] === 'simple') {
            tempFilterTypes[type] = 'asc'
            filterTypes = tempFilterTypes
            this.setState({ filterData: { key: type, type: "asc" } })
        } else if (filterTypes[type] === 'asc') {
            tempFilterTypes[type] = 'desc'
            filterTypes = tempFilterTypes
            this.setState({ filterData: { key: type, type: "desc" } })
        }
        else {
            filterTypes[type] = 'simple'
            this.setState({ filterData: { key: null, type: null } })
        }
        setTimeout(() => {
            this.load(1)
        }, 1000)
    }

    getFilterColor = (type) => {
        let color = ''
        if (filterTypes[type] === 'asc') {
            color = 'green'
        } else if (filterTypes[type] === 'desc') {
            color = 'red'
        }
        return color
    }


    /* Draw Top */
    _top = () => {

        const { count, search } = this.state

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/product.png" alt="Product" />
                    <p>Бухгалтерия</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

                <div className="content-create">
                    <Link className="content-create-button" to="/report/create">Добавить</Link>
                </div>

                <div className="content-search">
                    <img src="/pictures/search.png" alt="Search" />
                    <input value={search} onChange={event => this.search(event.target.value)} placeholder="Поиск" />
                </div>

                <div className="content-create">
                    <div className="content-create-button blue" onClick={() => this.exportFile()}>Отчет</div>
                </div>

            </div>
        )
    }



    /* Remove */
    remove = (item) => {

        Swal.fire({
            title: 'Вы действительно хотите удалить?',
            text: "Невозможно восстановить после удаления",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f44336',
            confirmButtonText: 'Да, удалить!',
            cancelButtonText: 'Нет'
        }).then((result) => {
            if (result.isConfirmed) {
                remove({ reportID: item.id }).then(response => {
                    if (response.status === 200) {

                        const { data } = this.state

                        const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))

                        if (index > -1) {
                            data.splice(index, 1)
                            this.setState({ data })
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Успешно удалено',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Что-то пошло не так! Попробуйте позднее',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }).catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Что-то пошло не так! Попробуйте позднее',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }
        })
    }


    /* Draw Content */
    _content = () => {

        const { data, loading, page } = this.state

        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        return (
            <div className="content-data-box">
                {data.map((item, index) =>
                    <div className="content-box-body" key={`${index}`}>
                        <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                        <div className="content-item">{utils.check(item.user.name)}</div>
                        <div className="content-item">{item.user ? item.user.type === 1 ? 'Оптовик' : 'Роз.клиент' : ""}</div>
                        <div className="content-item">{utils.convertor(item.totalOrderAmount)}</div>
                        <div className="content-item">{utils.convertor(item.totalIncomeAmount)}</div>
                        <div className="content-item">{utils.convertor(item.totalCashOrder)}</div>
                        <div className="content-item">{utils.convertor(item.totalPaymentTotal)}</div>
                        <div className="content-item">{utils.convertor(item.totalDebt)}</div>
                        <div className="content-item">{utils.check(item.user.maxDebt)}</div>
                        {/* <div className="content-item content-buttons name">
                            <Link className="content-item-button" to={`/report/edit/${item.id}`}>Редактировать</Link>
                            <div className="content-item-button delete" onClick={() => this.remove(item)}>Удалить</div>
                        </div> */}
                    </div>
                )}
            </div>
        )
    }


    render = () => {

        const { permission } = this.state

        if (permission) {
            return <Permission/>
        }

        return (
            <div className="content">

                {this._top()}

                <div className="content-box report">

                    <div className="content-box-head report">
                        <div className="content-head number">#</div>
                        <div className={`content-head clickable ${this.getFilterColor('username')}`} onClick={() => this.filterColumn('username')}>Партнер</div>
                        <div className={`content-head clickable ${this.getFilterColor('usertype')}`} onClick={() => this.filterColumn('usertype')}>Тип партнера</div>
                        <div className={`content-head clickable ${this.getFilterColor('totalOrderAmount')}`} onClick={() => this.filterColumn('totalOrderAmount')}>Сумма продажи</div>
                        <div className={`content-head clickable ${this.getFilterColor('totalIncomeAmount')}`} onClick={() => this.filterColumn('totalIncomeAmount')}>Сумма прихода</div>
                        <div className={`content-head clickable ${this.getFilterColor('totalCashOrder')}`} onClick={() => this.filterColumn('totalCashOrder')}>Кассовый ордер</div>
                        <div className={`content-head clickable ${this.getFilterColor('totalPaymentTotal')}`} onClick={() => this.filterColumn('totalPaymentTotal')}>Сумма оплаты</div>
                        <div className={`content-head clickable ${this.getFilterColor('totalDebt')}`} onClick={() => this.filterColumn('totalDebt')}>Остаток суммы</div>
                        <div className={`content-head clickable ${this.getFilterColor('usermaxdebt')}`} onClick={() => this.filterColumn('usermaxdebt')}>Макс долг</div>
                        {/* <div className="content-head name"></div> */}
                    </div>

                    {this._content()}

                    {/* <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page, search)} /> */}

                </div>

            </div>
        )
    }

}

export default Report