import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { list, remove, status, file, import_file } from '../../../api/Remainder'

/* Pagination */
import { Pagination } from '../../../components'

/* SweetAlert */
import Swal from "sweetalert2"

/* React Router */
import { Link } from "react-router-dom"

/* Constants */
import { env } from '../../../constants'

/* File download */
import FileDownload from "js-file-download"

/* Permission */
import { Permission } from "../../Auth"


/* Page Remainder */
class Remainder extends Component {

    constructor() {
        super()

        this.state = {
            loading: true,
            data: [],
            page: 1,
            count: 0,
            search: "",

            permission: false
        }
    }


    componentDidMount = () => {
        this.load()
    }

    /* Load */
    load = (page = 1) => {

        const { search } = this.state
        this.setState({ page, loading: true })

        list({ page: page, search }).then(response => {
            if (response.status === 200) {
                this.setState({
                    data: response.data.stocks,
                    count: response.data.pagination.totalItems,
                    loading: false
                })
            } else if (response.status === 403) {
                this.setState({ permission: true })
            }
        })
    }


    search = text => {
        this.setState({ search: text }, () => {
            if (text !== "") {
                this.setState({ loading: true })

                if (this._timeout) {
                    clearTimeout(this._timeout)
                }

                this._timeout = setTimeout(() => {
                    this.load(1)
                }, 1000)
            }
            else {
                this.load(1)
            }
        })
    }


    export = () => {
        file().then(response => {
            if (response.status === 200) {
                FileDownload(response.data, 'report.xlsx');
            }
        })
    }

    import = async () => {
        const { value: file } = await Swal.fire({
            title: 'Добавить',
            input: 'file',
            showCancelButton: true,
            inputAttributes: {
                'accept': '.xls, .xlsx',
                'aria-label': 'Upload your Excel file'
            },
            confirmButtonColor: 'blue',
            confirmButtonText: 'Добавить',
            cancelButtonColor: 'red',
            cancelButtonText: 'Назад'
        })
        if (file) {
            let data = new FormData()
            data.append('file', file)
            import_file(data).then(response => {
                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Успешно добавлен',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Что-то пошло не так! Попробуйте позднее',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        }
    }

    /* Draw Top */
    _top = () => {

        const { count, search } = this.state

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/category.png" alt="Category" />
                    <p>Остаток товара</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

                <div className="content-create">
                    <Link className="content-create-button" to="/remainder/create">Добавить</Link>
                </div>

                <div className="content-search">
                    <img src="/pictures/search.png" alt="Search" />
                    <input value={search} onChange={event => this.search(event.target.value)} placeholder="Поиск товара (Название, артикул, код)" />
                </div>

                <div className="content-create">
                    <div className="content-create-button blue" onClick={() => this.export()}>EXPORT</div>
                </div>
                <div className="content-create">
                    <div className="content-create-button blue" onClick={() => this.import()}>IMPORT</div>
                </div>

            </div>
        )
    }


    /* Remove */
    remove = async (item) => {

        Swal.fire({
            title: 'Вы действительно хотите удалить?',
            text: "Невозможно восстановить после удаления",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f44336',
            confirmButtonText: 'Да, удалить!',
            cancelButtonText: 'Нет'
        }).then((result) => {
            if (result.isConfirmed) {
                remove({ stockID: item.id }).then(response => {
                    if (response.status === 200) {

                        const { data } = this.state

                        const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))

                        if (index > -1) {
                            data.splice(index, 1)
                            this.setState({ data })
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Успешна удалена',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Что-то пошло не так! Попробуйте позднее',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }).catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Что-то пошло не так! Попробуйте позднее',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }
        })
    }


    /* Show Image */
    image = img => {
        if (img) {
            Swal.fire({
                imageUrl: `${env.mediapoint}categories/${img}`,
                imageHeight: 300,
                imageAlt: 'Category',
                confirmButtonColor: '#000',
                confirmButtonText: 'Закрыть'
            })
        }
    }


    /* Change visible */
    change = (id, value) => {

        const { data } = this.state

        status({ stockID: id, visible: value ? 1 : 0 }).then(response => {

            if (response.status === 200) {
                const index = data.findIndex(e => parseInt(e.id) === parseInt(id))

                if (index > -1) {
                    data[index].visible = value ? 1 : 0
                    this.setState({ data })
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Статус успешно изменен',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }


    /* Draw Content */
    _content = () => {

        const { data, loading, page } = this.state

        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        return (
            <div className="content-data-box">
                {data.map((item, index) => {
                    const color = item.count <= item.minCount ? 'red' : item.count >= item.maxCount ? 'green' : ''
                    return (<div className={`content-box-body ${color}`} key={`${index}`}>
                        <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                        <div className="content-item">{item.product.name}</div>
                        <div className="content-item">{item.warehouse.name}</div>
                        <div className="content-item">{item.product.article}</div>
                        <div className="content-item">{item.product.code}</div>
                        <div className="content-item">{item.count}</div>
                        <div className="content-item content-buttons name">

                            <Link className="content-item-button" to={`/remainder/edit/${item.id}`}>Редактировать</Link>
                            <div className="content-item-button delete" onClick={() => this.remove(item)}>Удалить</div>
                        </div>
                    </div>)
                }
                )}
            </div>
        )
    }


    render = () => {

        const { count, page, permission } = this.state

        if (permission) {
            return <Permission />
        }

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">

                    <div className="content-box-head">
                        <div className="content-head number">#</div>
                        <div className="content-head">Продукт</div>
                        <div className="content-head">Склад</div>
                        <div className="content-head">Артикул</div>
                        <div className="content-head">Код</div>
                        <div className="content-head">Кол-во</div>
                        <div className="content-head name"></div>
                    </div>

                    {this._content()}

                    <Pagination size={20} page={page} total={count} onPageChange={page => this.load(page)} />

                </div>

            </div>
        )
    }

}

export default Remainder