import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { list, remove, status } from '../../../api/User'

/* Pagination */
import { Pagination } from '../../../components'

/* Helpers */
import { utils } from '../../../helpers'

/* React Router */
import { Link } from "react-router-dom"

/* SweetAlert */
import Swal from "sweetalert2"
import { env } from "../../../constants"

/* Switch */
import Switch from "react-switch"

/* Permission */
import { Permission } from "../../Auth"

/* Axios */
import axios from "axios"

/* Moment */
import moment from "moment"
import 'moment/locale/ru'
moment.locale('ru')


/* Page Users */
class Partners extends Component {

    constructor() {
        super()

        this.state = {
            loading: true,
            data: [],
            page: 1,
            search: "",
            count: 0,

            permission: false,
        }
    }


    componentDidMount = () => {
        this.load()
    }

    /* Load */
    load = (page = 1, text = '') => {

        this.setState({ page, loading: true })

        list({ page, search: text }).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data.data, count: response.data.pagination.totalItems, loading: false })
            } else if (response.status === 403) {
                this.setState({ permission: true })
            }
        })
    }

    /* Search action */
    search = text => {
        this.setState({ search: text })

        if (text !== "") {
            this.setState({ loading: true })

            if (this._timeout) {
                clearTimeout(this._timeout)
            }

            this._timeout = setTimeout(() => {
                this.load(1, text.trim())
            }, 1000)
        }
    }


    /* Draw Top */
    _top = () => {

        const { count, search } = this.state

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/user.png" alt="User" />
                    <p>Пользователи</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

                <div className="content-create">
                    <Link className="content-create-button" to="/partner-create">Добавить</Link>
                </div>

                <div className="content-search">
                    <img src="/pictures/search.png" alt="Search" />
                    <input value={search} onChange={event => this.search(event.target.value)} placeholder="Поиск" />
                </div>

            </div>
        )
    }



    /* Edit */
    edit = async (item) => {

        Swal.fire({
            title: 'Посмотреть',
            html: `
                <div class="content-layout-content">
                    <p><b>Имя: </b> ${utils.check(item.name)}</p>
                    <p><b>Email: </b> ${utils.check(item.email)}</p>
                    <p><b>Телефон: </b> ${utils.formatter(item.phone, "+# (###) ###-##-##")}</p>
                    <p><b>Изображение: </b> ${utils.check(item.image)}</p>
                    <p><b>День рождение: </b> ${item.birthday ? moment(item.birthday).format("Do MMMM YYYY") : "-"}</p>
                    <p><b>Пол: </b> ${utils.gender(item.gender)}</p>
                    <p><b>Дата регистрации: </b> ${item.createdAt ? moment(item.createdAt).format("Do MMMM YYYY, hh:mm") : "-"}</p>
                </div>
            `,
            focusConfirm: false,
            confirmButtonColor: '#000',
            confirmButtonText: 'Закрыть',
        })
    }

    /* Change visible */
    change = (id, value) => {

        if (value) {
            Swal.fire({
                title: "Введите H&K Номер партнера",
                input: "text",
                inputAttributes: { autocapitalize: "off" },
                showCancelButton: true,
                cancelButtonText: "Отмена",
                cancelButtonColor: "#999",
                confirmButtonText: "Искать",
                confirmButtonColor: "#03a9f4",
                showLoaderOnConfirm: true,
                preConfirm: async (partnerCode) => {
                    return axios.post(`${env.startpoint}user/find-partner`, { partnerCode }).then(response => {
                        if (response.status === 200) {
                            if (response.data.dwxh === "000001") {
                                return Swal.showValidationMessage(`Невозможно добавить, так как это розничный клиент!`)
                            }
                            return response.data
                        }
                        else {
                            return Swal.showValidationMessage(`Что-то пошло не так! Попробуйте позднее...`)
                        }
                    }).catch(data => {
                        console.log(data.response.data)
                        if (data.response.status === 404) {
                            return Swal.showValidationMessage(`Партнер не найден!`)
                        }
                        else {
                            return Swal.showValidationMessage(`Что-то пошло не так! Попробуйте позднее...`)
                        }
                    })
                }
            }).then(result => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Добавить партнера?",
                        html: `<b>${result.value.dwxh}</b><br /> ${result.value.dwmc}`,
                        showCancelButton: true,
                        cancelButtonText: "Отмена",
                        cancelButtonColor: "#999",
                        confirmButtonText: "Добавить",
                        confirmButtonColor: "#03a9f4",
                    }).then(c => {
                        if (c.isConfirmed) {
                            this.changeStatus(id, value, result.value.dwxh)
                        }
                    })
                }
            })
        }
        else {
            this.changeStatus(id, value, null)
        }
    }


    changeStatus = (id, value, partnerCode = null) => {

        const { data } = this.state

        status({ userID: id, type: value ? 1 : 0, partnerCode }).then(response => {
            if (response.status === 200) {

                const index = data.findIndex(e => parseInt(e.id) === parseInt(id))

                if (index > -1) {
                    data[index].type = value ? 1 : 0
                    data[index].partnerCode = partnerCode
                    this.setState({ data })
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Статус успешно изменен',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }


    /* Remove */
    remove = async (item) => {

        Swal.fire({
            title: 'Вы действительно хотите удалить?',
            text: "Невозможно восстановить после удаления",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f44336',
            confirmButtonText: 'Да, удалить!',
            cancelButtonText: 'Нет'
        }).then((result) => {
            if (result.isConfirmed) {
                remove({ id: item.id }).then(response => {
                    if (response.status === 200) {

                        const { data } = this.state

                        const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))

                        if (index > -1) {
                            let newData = data
                            newData.splice(index, 1)
                            this.setState({ data: newData })
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Успешно удалено',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Что-то пошло не так! Попробуйте позднее',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }).catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Что-то пошло не так! Попробуйте позднее',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }
        })
    }



    /* Show Image */
    image = img => {
        if (img) {
            Swal.fire({
                imageUrl: `${env.mediapoint}users/${img}`,
                imageHeight: 300,
                imageAlt: 'Category',
                confirmButtonColor: '#000',
                confirmButtonText: 'Закрыть'
            })
        }
    }


    /* Draw Content */
    _content = () => {

        const { data, loading, page } = this.state

        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        return (
            <div className="content-data-box">
                {data.map((item, index) =>
                    <div className="content-box-body" key={`${index}`}>
                        <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                        <div className="content-item">
                            <b>{utils.check(item.name)}</b>
                            {item.image && <img onClick={() => this.image(item.image)} className="ava" src={`${env.mediapoint}/users/${item.image}`} alt="User" />}

                            {item.partnerCode ? <b style={{ paddingLeft: 10 }}>(Оптовик {item.partnerCode})</b> : "" }

                        </div>
                        <div className="content-item">{utils.check(item.email)}</div>
                        <div className="content-item">
                            {utils.formatter(item.phone, "+# (###) ###-##-##")}
                        </div>
                        <div className="content-item">
                            <Switch
                                onChange={() => this.change(item.id, !item.type)}
                                checked={parseInt(item.type) === 1}
                                onColor="#b1e17a"
                                onHandleColor="#8bc34a"
                                offHandleColor="#fff"
                                offColor="#d7d7d7"
                                handleDiameter={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                height={20}
                                width={40}
                            />
                        </div>
                        <div className="content-item content-buttons name">
                            <div className="content-item-button" onClick={() => this.edit(item)}>Посмотреть</div>
                            <div className="content-item-button delete" onClick={() => this.remove(item)}>Удалить</div>
                        </div>
                    </div>
                )}
            </div>
        )
    }


    render = () => {

        const { count, page, search, permission } = this.state

        if (permission) {
            return <Permission />
        }

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">

                    <div className="content-box-head">
                        <div className="content-head number">#</div>
                        <div className="content-head">Имя</div>
                        <div className="content-head">Email</div>
                        <div className="content-head">Телефон</div>
                        <div className="content-head">Оптовик</div>
                        <div className="content-head name"></div>
                    </div>

                    {this._content()}

                    <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page, search)} />

                </div>

            </div>
        )
    }

}

export default Partners