const menu = [
    {
        name:"Приход",
        level: 1,
        submenu: [
            {
                name:'Оприходование',
                image: '/pictures/menu/income.png',
                link: "/incomings",
            }
        ]
    },
    {
        name:"Продажи",
        level: 2,
        submenu: [
            {
                name:'Сегодняшние заказы',
                image: '/pictures/menu/sells.png',
                link: "/sells",
            },
            {
                name:'Резерв',
                image: '/pictures/menu/sells.png',
                link: "/rezerv",
            },
            {
                name:'Все заказы',
                image: '/pictures/menu/sells.png',
                link: "/sells-all",
            },
            {
                name:'Возврат',
                image: '/pictures/menu/sells.png',
                link: "/refund",
            },
        ]
    },
    // {
    //     name:"Продажи",
    //     level: 2,
    //     submenu: [
    //         {
    //             name:'Список отходов',
    //             image: '/pictures/menu/cart.png',
    //             submenu: [
    //                 {
    //                     name: 'Основные отходы',
    //                     image: '/pictures/menu/cart.png',
    //                     link: "/opt22",
    //                 },
    //                 {
    //                     name: 'Ключевые отходы',
    //                     image: '/pictures/menu/cart.png',
    //                     link: "/opt23",
    //                 },
    //                 {
    //                     name: 'Основные отходы',
    //                     image: '/pictures/menu/cart.png',
    //                     link: "/opt24",
    //                 }
    //             ]
    //         },
    //         {
    //             name:'Возврат отходов',
    //             image: '/pictures/menu/cart.png',
    //             link: "/send3",
    //         },
    //         {
    //             name:'Изменение отходов',
    //             image: '/pictures/menu/cart.png',
    //             link: "/update4",
    //         }
    //     ]
    // },
    {
        name:"Учет",
        level: 2,
        submenu: [
            {
                name:'Начать учет',
                image: '/pictures/menu/accounting.png',
                link: "/accounting",
            }
        ]
    },
    {
        name:"Бухгалтерия",
        level: 2,
        submenu: [
            {
                name:'Список',
                image: '/pictures/menu/accounting.png',
                link: "/report",
            }
        ]
    },
    {
        name:"Складские дела",
        level: 2,
        submenu: [
            {
                name:'Остаток товара',
                image: '/pictures/menu/stocks.png',
                link: "/remainder",
            }
        ]
    },
    {
        name:"Основые информаций",
        level: 1,
        submenu: [
            {
                name:'Города',
                image: '/pictures/menu/city.png',
                link: "/city",
            },
            {
                name:'Создать склад',
                image: '/pictures/menu/warehouse.png',
                link: "/warehouse",
            },
            {
                name:'Создать категорию',
                image: '/pictures/menu/category.png',
                link: "/category",
            },
            {
                name:'Основные данные товара',
                image: '/pictures/menu/product.png',
                link: "/product",
            },
            {
                name:'Cоздать партнера',
                image: '/pictures/menu/user.png',
                link: "/partners",
            }
        ]
    },
    {
        name:"Cтатистика",
        level: 1,
        submenu: [
            {
                name:'Cтатистика оприходование',
                image: '/pictures/menu/crown.png',
                link: "/statistics/income",
            },
            {
                name:'Cтатистика заказов',
                image: '/pictures/menu/crown.png',
                link: "/statistics/order",
            },
            {
                name:'Cтатистика заказов партнера',
                image: '/pictures/menu/crown.png',
                link: "/statistics/order-user",
            },
            {
                name:'Акт сверки',
                image: '/pictures/menu/crown.png',
                link: "/statistics/act",
            },
        ]
    },
    {
        name:"Система управления",
        level: 1,
        submenu: [
            {
                name:'Управления пользователями',
                image: '/pictures/menu/crown.png',
                link: "/users",
            },
            {
                name:'Изменить пароль',
                image: '/pictures/menu/user.png',
                link: "/password",
            }
        ]
    },
    {
        name:"Доступы",
        level: 1,
        submenu: [
            {
                name:'Менеджеры',
                image: '/pictures/crown.png',
                link: "/managers",
            },
            {
                name:'Роли',
                image: '/pictures/crown.png',
                link: "/roles",
            }
        ]
    }
]

export { menu }