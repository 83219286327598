import { useState } from 'react'

/* CSS styles */
import './design/app.css'
import './design/login.css'
import './design/layout.css'
import './design/pagination.css'
import './design/menu.css'
import './design/navbar.css'
import './design/header.css'
import './design/order.css'
import './design/dashboard.css'


/* Layouts */
import Authorization from "./pages/Authorization"
import Layout from "./pages/Layout"

/* JWT */
import { isExpired } from "react-jwt"
/*
    Entry Point
*/
const App = () => {

    const data = localStorage.getItem("token")
    const [token, setToken] = useState(data)

    if (!token || isExpired(token)) {
        return (
            <div className="app">
                <Authorization setToken={setToken} />
            </div>
        )
    }

    return (
        <div className='app'>
            <Layout setToken={setToken} />
        </div>
    )

}

export default App