import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Helpers */
import { withRouter, utils } from '../../helpers'

/* REST API */
import { view, changePaymentStatus, changeDeliveryType, changePaymentType, update, } from '../../api/Order'
import { list as list_of_products } from "../../api/Product"
import { list as list_of_partners, user_debt } from "../../api/User"

/* SweetAlert */
import Swal from "sweetalert2"

/* Select */
import { SelectMenu, SelectItem } from "../../widgets"

/*
    Page Order
*/
class Order extends Component {

    constructor(props) {
        super(props)

        const { params } = this.props

        this.state = {
            id: params.id,
            data: null,
            loading: true,
            store: [],

            partners: [],
            partner: null,

            selected_products: [],
            products_all: [],

            paymentMethod: 0,
            paymentStatus: null,

            isItemsUpdated: false,

            comment: "",

            refund: 0,

            sale: 0,

            partner_debt: 0,
            partner_total: 0
        }
    }

    componentDidMount = () => {
        this.load()
        this.products()
        this.partners()
    }


    products = (search = null) => {
        list_of_products({ ...search }).then(response => {
            if (response.status === 200) {
                const array = response.data.data.slice(0, 10)
                this.setState({ products_all: array })
            }
        })
    }

    partners = (search = null) => {
        list_of_partners({ search }).then(response => {
            if (response.status === 200) {
                this.setState({ partners: response.data.data })
            }
        })
    }

    /* Load Order Data */
    load = () => {
        const { id } = this.state

        view({ orderID: id }).then(response => {
            if (response.status === 200) {
                let items = []
                // console.log(response.data)
                if (response.data.order && response.data.order.items && response.data.order.items.length > 0) {
                    response.data.order.items.forEach(item => {
                        const product = {
                            "productID": item.productID,
                            "name": item.itemProduct.name,
                            "price": item.price,
                            "salePrice": item.itemProduct.salePrice,
                            "quantity": item.quantity,
                            "code": item.itemProduct.code,
                            "article": item.itemProduct.article
                        }
                        items.push(product)
                    })
                    console.log(items)
                }

                const order = response.data.order

                this.setState({
                    loading: false,
                    data: order,
                    partner: { id: order.userID, ...order.user },
                    sale: order.sale_percent,
                    paymentMethod: order.paymentMethod,
                    refund: order.paymentStatus === "reserve",
                    selected_products: items,
                    paymentStatus: order.paymentStatus
                })

                if (response.data?.order) {
                    this.setState({ comment: response.data.order.comment })
                }

                if (order.userID) {
                    user_debt({ userID: order.userID }).then(response => {
                        if (response.status === 200) {
                            this.setState({ partner_debt: response.data.totalDebt, partner_total: response.data.totalAmount })
                        }
                    })
                }
            }
        })
    }

    /* update products list */
    save = () => {
        const { id, selected_products, comment, sale } = this.state
        let total = 0
        let products = []
        selected_products.forEach(item => {
            const product = {
                "id": item.productID,
                "name": item.product,
                "price": item.price,
                "quantity": item.quantity,
                "salePrice": item.salePrice
            }
            total += item.quantity * item.price
            products.push(product)
        })

        const data = {
            orderID: id,
            products,
            total,
            comment,
            sale_percent: sale
        }

        update(data).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Успешна изменен',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })

    }

    setRefund = (data) => {
        this.setState({ refund: data.target.checked })
    }


    /* Draw Top */
    _top = () => {

        const { data } = this.state

        return (
            <div className="content-top">
                <div className="content-name">
                    <img src="/pictures/orders.png" alt="Order" />
                    <p>Заказ {data ? `#${utils.check(data.number)}` : ''}</p>
                </div>
            </div>
        )
    }

    /* Draw Content */
    _content = () => {

        const { data, loading, products_all, selected_products, isItemsUpdated } = this.state

        /* DRAW LOADER */
        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        /* DRAW EMPTY */
        if (data === null) {
            return (
                <div className="content-element">
                    Заказ не найден
                </div>
            )
        }

        let total = 0
        selected_products.forEach(item => {
            total += parseInt(item.price) * parseInt(item.quantity)
        })


        return (
            <div className="order-view">
                <div className="order-view-info">
                    <div className="order-row">
                        <div className="order-text">Клиент: </div>
                        <div className="order-value">{utils.check(data.user.name)}</div>
                    </div>

                    <div className="order-row">
                        <div className="order-text">Статус заказа: </div>
                        <div className="order-value">{utils.order_status(data.deliveryStatus)}</div>
                    </div>
                </div>
                <div className="order-view-products">

                    <p>Продукты</p>

                    <SelectMenu
                        isEdit
                        list={products_all}
                        items={selected_products}
                        search={(value) => this.products(value)}
                        update={(data) => this.setState({ selected_products: data, isItemsUpdated: true })}
                    />

                    <div className="order-product-total">
                        <div>Итого: <b>{utils.convertor(total)}</b></div>
                        {isItemsUpdated ? <div onClick={() => this.update_list()} className="content-form-button simple">Сохранить</div> : null}
                    </div>
                </div>
            </div>
        )
    }


    /* Change Delivery Type */
    changeDeliveryType = status => {

        const { id, data } = this.state

        changeDeliveryType({ orderID: id, status }).then(response => {
            if (response.status === 200) {

                Swal.fire({
                    icon: 'success',
                    title: 'Статус доставки успешно изменен',
                    showConfirmButton: false,
                    timer: 1500
                })

                if (status === 'pickup' && data.deliveryStatus === 'ontheway') {
                    this.changeDeliveryStatus('pending')
                }

                data.deliveryMethod = status
                this.setState({ data })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }




    /* Change Payment Type */
    changePaymentType = status => {

        const { id } = this.state

        changePaymentType({ orderID: id, status }).then(response => {
            if (response.status === 200) {

                Swal.fire({
                    icon: 'success',
                    title: 'Вид оплаты успешно изменен',
                    showConfirmButton: false,
                    timer: 1500
                })

                this.setState({ paymentMethod: status })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }




    /* Change Payment Type */
    changePaymentStatus = status => {

        const { id } = this.state

        changePaymentStatus({ orderID: id, status }).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Статус оплаты успешно изменен',
                    showConfirmButton: false,
                    timer: 1500
                })

                this.setState({ paymentStatus: status })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }

    /* Draw Status Changers */
    _change = () => {

        const { data } = this.state

        if (data) {

            return (
                <div className="order-status-box">

                    <div className="order-status-change">
                        <h2>Изменить вид оплаты</h2>
                        <p>Нажмите на статус который хотите изменить</p>
                        <div className="order-status">
                            {utils.PAYMENT_TYPE.map((item, index) =>
                                <div onClick={() => this.changePaymentType(item.key)} key={`${index}`} className={data.paymentMethod === item.key ? `order-status-item order-status-item-active-${item.color}` : `order-status-item`} >
                                    {item.text}
                                </div>
                            )}
                        </div>

                        <h2>Изменить статус оплаты</h2>
                        <p>Нажмите на статус который хотите изменить</p>
                        <div className="order-status">
                            {utils.PAYMENT_STATUS.map((item, index) =>
                                <div onClick={() => this.changePaymentStatus(item.key)} key={`${index}`} className={data.paymentStatus === item.key ? `order-status-item order-status-item-active-${item.color}` : `order-status-item`} >
                                    {item.text}
                                </div>
                            )}
                        </div>

                    </div>

                </div>
            )
        }
    }

    isNumber(value) {
        return typeof value === 'number' && isFinite(value);
    }

    render = () => {

        const { partners, partner, selected_products, partner_debt, paymentMethod, paymentStatus, partner_total, comment, sale } = this.state

        let total = 0

        selected_products.forEach(item => {
            total += item.price * item.quantity
        })

        if (this.isNumber(Number(sale))) {
            total = total - (total * Number(sale))
        }



        return (
            <div className="content">
                {this._top()}

                <div className="content-box">

                    <div className="content-form-box">

                        <div className="content-form-half">

                            <p>Партнер</p>
                            <SelectItem
                                type={true}
                                list={partners}
                                partner={partner}
                                search={(value) => this.partners(value)}
                                setPartner={(data) => this.setPartner(data)}
                            />

                            <p>Метод оплаты</p>
                            <select value={paymentMethod} onChange={event => this.changePaymentType(event.target.value)}>
                                <option value='debt'>Долг</option>
                                <option value='cash'>Наличными</option>
                                {/* <option value='qr'>QR</option> */}
                            </select>

                            <p>Статус оплаты</p>
                            <select value={paymentStatus} onChange={event => this.changePaymentStatus(event.target.value)}>
                                <option seleted="paid">Оплачен</option>
                                <option value='notpaid'>Не оплачен</option>
                                <option value='reserve'>Резерв</option>
                            </select>

                            <p>Описание</p>
                            <textarea value={comment} onChange={event => this.setState({ comment: event.target.value })} placeholder="Введите комментарий"></textarea>

                            {/* <p>Резерв</p>
                            <input className="order-checkbox" checked={refund} onChange={data => this.setRefund(data)} type="checkbox"></input> */}

                        </div>
                        <div className="content-form-half">

                            <p>Долг партнера</p>
                            <input type="text" value={utils.convertor(partner_debt)} disabled />

                            <p>Общая сумма закупок</p>
                            <input type="text" value={utils.convertor(partner_total)} disabled />

                            <p>Скидка</p>
                            <input type="text" value={sale} onChange={event => this.setState({ sale: event.target.value })} placeholder="Пример: 0.6"></input>

                            <p>Общая сумма</p>
                            <input type="text" value={total} disabled placeholder="Общая сумма"></input>
                        </div>
                    </div>
                    <div className="content-form-box">
                        <div className="content-form-half full">
                            {
                                partner ?
                                    <>
                                        <p>Продукты</p>
                                        <SelectMenu
                                            orderMenu={true}
                                            items={selected_products}
                                            partner={partner}
                                            search={(value) => this.products(value)}
                                            update={(data) => this.setState({ selected_products: data })}
                                        />
                                    </>
                                    : null
                            }
                        </div>
                    </div>

                    <div className="content-form-bottom">
                        <div onClick={() => this.save()} className="content-form-button">Сохранить</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(Order)