import axios from "axios"
import { env } from '../constants'



/* Login */
export const login = data => {
    const uri = `${env.startpoint}user/login`
    return axios.post(uri, data).then(response => response).catch(error => error.response)
}

/* Register */
export const register = data => {
    const uri = `${env.startpoint}user/register`
    return axios.post(uri, data).then(response => response).catch(error => error.response)
}

/* Register */
export const password_update = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}user/password`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}