import React, { Component } from "react"

/* REST API */
import { roles, set_role } from "../../api/Permissions"

import { admins } from "../../api/User"

/* Sweet alert */
import Swal from "sweetalert2"

/* Helpers */
import { withRouter } from '../../helpers'

/* Widgets */
import { SelectItem } from "../../widgets"


/*
    Page Set Role
*/
class SetRole extends Component {


    constructor() {
        super()

        this.state = {
            manager: null,
            managers: [],

            role: null,
            roles: [],
        }
    }

    componentDidMount = () => {
        this.load()
        this.roles()
        this.managers()
    }

    /* Load Categories */
    load = () => {
    }

    managers = (search = null) => {
        admins({ search }).then(response => {
            if (response.status === 200) {
                this.setState({ managers: response.data.data })
            }
        })
    }

    roles = (search = null) => {
        roles({ search }).then(response => {
            if (response.status === 200) {
                this.setState({ roles: response.data.roles })
            }
        })
    }

    /* Create */
    create = () => {

        const { manager, role } = this.state
        const { navigate } = this.props
        if (!manager) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите менеджера', showConfirmButton: false, timer: 1500 })
            return
        }

        if (!role) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите роль', showConfirmButton: false, timer: 1500 })
            return
        }

        const data = {
            adminID: manager.id,
            roleID: role,
        }

        set_role(data).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Успешна добавлена',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/managers')
                    }
                })

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })

    }


    /* Draw Top */
    _top = () => {

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/category.png" alt="Category" />
                    <p>Добавить роль для менеджера</p>
                </div>

            </div>
        )
    }

    render = () => {

        const { managers, manager, roles } = this.state

        return (
            <div className="content">
                {this._top()}

                <div className="content-box">

                    <div className="content-form-box">

                        <div className="content-form-half">

                            <p>Менеджер</p>
                            <SelectItem
                                list={managers}
                                partner={manager}
                                search={(value) => this.managers(value)}
                                setPartner={(data) => this.setState({ manager: data })}
                            />
                        </div>

                        <div className="content-form-half">
                            <p>Роль</p>
                            <select onChange={event => this.setState({ role: event.target.value })}>
                                <option seleted="true" value={0}>Не выбран</option>
                                {
                                    roles.map(item => {
                                        return (
                                            <option value={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                    </div>

                    <div className="content-form-bottom">
                        <div onClick={() => this.create()} className="content-form-button">Добавить</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(SetRole)