import axios from "axios"
import { env } from '../constants'


/* Remainder */
export const list = data => {
    const token = localStorage.getItem("token")
    const searchTerm = (data.search === null || data.search === undefined || data.search === "") ? '' : `&searchTerm=${data.search}`
    const uri = `${env.startpoint}stock/list?page=${data.page}${searchTerm}`
    const headers = { headers: { Authorization: `Bearer ${token}`, params: data } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* REMOVE */
export const remove = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}stock/delete`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* PARENT */
export const parent = () => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}category/parent`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* CREATE */
export const create = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}stock/create`
    const headers = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* EDIT */
export const edit = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}stock/edit`
    const headers = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* CHANGE STATUS */
export const status = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}stock/status`
    const headers = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* View */
export const view = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}stock/view`
    const headers = { headers: { Authorization: `Bearer ${token}` }, params: data }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

export const file = () => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}stock/export`
    
 // or Content-type: "application/vnd.ms-excel"
    const headers = { headers: { Authorization: `Bearer ${token}`, 'Content-Disposition': "attachment;filename=report.xls", 'Content-Type': "application/octet-stream" } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

export const import_file = (data) => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}stock/import`
    const headers = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}
