import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Helpers */
import { withRouter, utils } from '../../helpers'

/* REST API */
import { view, update } from '../../api/Order'
import { list as list_of_products } from "../../api/Product"

/* SweetAlert */
import Swal from "sweetalert2"

/* Select */
import { SelectMenu } from "../../widgets"

/*
    Page Order Products
*/
class OrderProducts extends Component {


    constructor(props) {
        super(props)

        const { params } = this.props

        this.state = {
            id: params.id,
            data: null,
            loading: true,
            store: [],

            selected_products: [],
            products_all: [],

            isItemsUpdated: false,
        }
    }

    componentDidMount = () => {
        this.load()
        this.products()
    }


    products = (search = null) => {
        list_of_products({ ...search }).then(response => {
            if (response.status === 200) {
                const array = response.data.data.slice(0, 10)
                this.setState({ products_all: array })
            }
        })
    }

    /* Load Order Data */
    load = () => {
        const { id } = this.state

        view({ orderID: id }).then(response => {
            if (response.status === 200) {
                let items = []
                if (response.data.order && response.data.order.items && response.data.order.items.length > 0) {
                    response.data.order.items.forEach(item => {
                        const product = {
                            "productID": item.productID,
                            "name": item.itemProduct.name,
                            "price": item.price,
                            "salePrice": item.itemProduct.salePrice,
                            "quantity": item.quantity,
                            "code": item.itemProduct.code,
                            "article": item.itemProduct.article
                        }
                        items.push(product)
                    })
                }

                this.setState({ loading: false, data: response.data.order, selected_products: items })
            }
        })
    }

    /* update products list */
    update_list = () => {
        const { id ,selected_products } = this.state
        let total = 0
        let products = []
        selected_products.forEach(item => {
            const product = {
                "id": item.productID,
                "name": item.product,
                "price": item.price,
                "quantity": item.quantity,
                "salePrice": item.salePrice
            }
            total += item.quantity * item.price
            products.push(product)

            const data = {
                orderID: id,
                products,
                total
            }

            update(data).then(response => {
                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Успешна изменен',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        })
    }


    /* Draw Top */
    _top = () => {

        const { data } = this.state

        return (
            <div className="content-top">
                <div className="content-name">
                    <img src="/pictures/orders.png" alt="Order" />
                    <p>Заказ {data ? `#${utils.check(data.number)}` : ''}</p>
                </div>
            </div>
        )
    }

    /* Draw Content */
    _content = () => {

        const { data, loading, products_all, selected_products, isItemsUpdated } = this.state

        /* DRAW LOADER */
        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        /* DRAW EMPTY */
        if (data === null) {
            return (
                <div className="content-element">
                    Заказ не найден
                </div>
            )
        }

        let total = 0
        selected_products.forEach(item => {
            total += parseInt(item.price) * parseInt(item.quantity)
        })


        return (
            <div className="order-view">
                <div className="order-view-info">
                    <h2>Информация о заказе</h2>
                    <div className="order-row">
                        <div className="order-text">Номер заказа: </div>
                        <div className="order-value">#{utils.check(data.number)}</div>
                    </div>
                    <div className="order-row">
                        <div className="order-text">Клиент: </div>
                        <div className="order-value">{utils.check(data.user.name)}</div>
                    </div>
                    <div className="order-row">
                        <div className="order-text">Email: </div>
                        <div className="order-value">{utils.check(data.email)}</div>
                    </div>
                    <div className="order-row">
                        <div className="order-text">Телефон: </div>
                        <div className="order-value">{utils.check(data.phone)}</div>
                    </div>

                    <div className="order-row">
                        <div className="order-text">Статус заказа: </div>
                        <div className="order-value">{utils.order_status(data.deliveryStatus)}</div>
                    </div>

                    <div className="order-row">
                        <div className="order-text">Оплата: </div>
                        <div className="order-value">{utils.paymentType(data.paymentMethod)}</div>
                    </div>

                    <div className="order-row">
                        <div className="order-text">Статус оплаты: </div>
                        <div className="order-value">{utils.paymentStatus(data.paymentStatus)}</div>
                    </div>
                    <div className="order-row">
                        <div className="order-text">Комментарий: </div>
                        <div className="order-value">{data.comment}</div>
                    </div>

                </div>
                <div className="order-view-products">

                    <p>Продукты</p>

                    <SelectMenu
                        list={products_all}
                        disabled
                        items={selected_products}
                        search={(value) => this.products(value)}
                        update={(data) => this.setState({ selected_products: data, isItemsUpdated: true })}
                    />

                    <div className="order-product-total">
                        <div>Итого: <b>{utils.convertor(total)}</b></div>
                        {isItemsUpdated ? <div onClick={() => this.update_list()} className="content-form-button simple">Сохранить</div> : null}
                    </div>
                </div>
            </div>
        )
    }

    render = () => {
        return (
            <div className="content">

                {this._top()}

                <div className="content-box">
                    {this._content()}
                </div>

            </div>
        )
    }

}

export default withRouter(OrderProducts)