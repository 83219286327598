import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { list, remove, file, exportFile } from '../../api/Order'

/* Pagination */
import { Pagination } from '../../components'

/* SweetAlert */
import Swal from "sweetalert2"

/* React Router */
import { Link } from "react-router-dom"

/* Helpers */
import { utils } from '../../helpers'

/* Moment */
import moment from "moment"

/* Permission */
import { Permission } from "../Auth"

/* File download */
const FileDownload = require('js-file-download');

/* Page Products */
class Sells extends Component {

    constructor() {
        super()

        this.state = {
            loading: true,
            data: [],
            page: 1,
            search: "",
            count: 0,

            permission: false,
        }
    }


    componentDidMount = () => {
        this.load()
    }

    /* Load */
    load = (page = 1, text = '') => {

        this.setState({ page, loading: true })

        const startDate = new Date()
        let endDate = new Date()

        const start_at = moment(startDate).format('YYYY-MM-DD')
        const end_at = moment(endDate).add(1, 'd').format('YYYY-MM-DD')

        list({ page, search: text, start_at, end_at }).then(response => {
            if (response.status === 200) {
                console.log(response.data)
                this.setState({ data: response.data.orders, count: response.data.pagination.totalItems, loading: false })
            } else if (response.status === 403) {
                this.setState({ permission: true })
            }
        })
    }


    addZero = (number) => {
        if (parseInt(number) < 10) {
            return '0' + number
        }
        return number
    }

    invoice = (id, number) => {
        file({ orderID: id }).then(response => {
            if (response.status === 200) {
                FileDownload(response.data, number + '.pdf');
            }
        })
    }

    todaysPdf = () => {
        exportFile().then(response => {
            if (response.status === 200) {
                FileDownload(response.data, 'order.pdf');
            }
        })
    }

    /* Search action */
    search = text => {
        this.setState({ search: text })

        if (text !== "") {
            this.setState({ loading: true })

            if (this._timeout) {
                clearTimeout(this._timeout)
            }

            this._timeout = setTimeout(() => {
                this.load(1, text.trim())
            }, 1000)
        }
    }


    /* Draw Top */
    _top = () => {

        const { count, search } = this.state

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/product.png" alt="Product" />
                    <p>За сегодня</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

                <div className="content-create">
                    <Link className="content-create-button" to="/sells/create/0">Добавить</Link>
                </div>

                <div className="content-search">
                    <img src="/pictures/search.png" alt="Search" />
                    <input value={search} onChange={event => this.search(event.target.value)} placeholder="Поиск" />
                </div>

                <div className="content-create">
                    <div className="content-create-button blue" onClick={() => this.todaysPdf()}>Отчет</div>
                </div>

            </div>
        )
    }



    /* Remove */
    remove = (item) => {

        Swal.fire({
            title: 'Вы действительно хотите удалить?',
            text: "Невозможно восстановить после удаления",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f44336',
            confirmButtonText: 'Да, удалить!',
            cancelButtonText: 'Нет'
        }).then((result) => {
            if (result.isConfirmed) {
                remove({ orderID: item.id }).then(response => {
                    if (response.status === 200) {

                        const { data } = this.state

                        const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))

                        if (index > -1) {
                            data.splice(index, 1)
                            this.setState({ data })
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Успешно удалено',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Что-то пошло не так! Попробуйте позднее',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }).catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Что-то пошло не так! Попробуйте позднее',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }
        })
    }


    /* Draw Content */
    _content = () => {

        const { data, loading, page } = this.state

        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        return (
            <div className="content-data-box">
                {data.map((item, index) => {
                    const is_admin_order = item.employee ? 'admin' : ''
                    return (
                        <div className={`content-box-body ${is_admin_order}`} key={`${index}`}>
                            <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                            <div className="content-item">{utils.check(item.number)}</div>
                            <div className="content-item">{utils.convertor(item.total)}</div>
                            <div className="content-item">{item.user ? item.user.name : ''}</div>
                            <div className="content-item">{item.deliveryStatus}</div>
                            <div className="content-item">{item.paymentStatus === 'paid' ? 'Оплачен' : item.paymentStatus === 'notpaid' ? 'Не оплачен' : 'Резерв'}</div>
                            <div className="content-item">{item.sale_percent}</div>
                            <div className="content-item">{moment(item.boughtAt).format("DD.MM.YY")}</div>
                            <div className="content-item">{item.employee ? item.employee.name : ''}</div>
                            <div className="content-item-button blue" onClick={() => this.invoice(item.id, item.number)}>Скачать</div>
                            <div className="content-item content-buttons name">
                                <Link className="content-item-button" to={`/sells/edit/${item.id}`}>Edit</Link>
                                <div className="content-item-button delete" onClick={() => this.remove(item)}>Delete</div>
                            </div>
                        </div>
                    )
                }
                )}
            </div>
        )
    }


    render = () => {

        const { count, page, search, permission } = this.state

        if (permission) {
            return <Permission />
        }

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">

                    <div className="content-box-head">
                        <div className="content-head number">#</div>
                        <div className="content-head">Номер заказа</div>
                        <div className="content-head">Цена</div>
                        <div className="content-head">Партнер</div>
                        <div className="content-head">Статус</div>
                        <div className="content-head">Статус оплаты</div>
                        <div className="content-head">Скидка</div>
                        <div className="content-head">Дата</div>
                        <div className="content-head">Сотрудник</div>
                        <div className="content-head">Накладная</div>
                        <div className="content-head name order"></div>
                    </div>

                    {this._content()}

                    <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page, search)} />

                </div>

            </div>
        )
    }

}

export default Sells