import React, { Component } from 'react'

/* Router */
import { NavLink } from "react-router-dom"

class SideSubMenu extends Component {
    constructor() {
        super()
        this.state = {
            isHidden: true,
        }
    }

    componentDidMount = () => {
    }

    render = () => {
        const { data, index } = this.props
        const { isHidden } = this.state

        return (
            <div>
                <div className="submenu-item" onClick={() => this.setState({ isHidden: !isHidden })}>
                    <div className="submenu-dot" key={`submenu_${index}`} />
                    <img src={data.image ? data.image : '/pictures/menu/empty.png'} alt="Category" />
                    <div className="menu-name">{data.name}</div>
                    <img src='/pictures/menu/arrow.png' className={`triangle mini ${!isHidden ? "triangle-top" : "triangle-bottom"}`} />
                </div>
                <div className={`sub_sidemenu ${!isHidden ? 'active' : ''}`}>
                    {
                        data.submenu.map((item, index) => {
                            return (
                                <NavLink to={item.link} className="submenu-item mini" key={`nav_${index}`}>
                                    <div className="submenu-dot" key={`submenu_${index}`} />
                                    <img src={item.image ? item.image : '/pictures/menu/empty.png'} alt="Category" />
                                    <div className="submenu-name">{item.name}</div>
                                </NavLink>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default SideSubMenu