import React, { Component } from "react"

/* REST API */
import { list as list_of_warehouses } from "../../api/Warehouse"
import { list as list_of_products } from "../../api/Product"
import { list as list_of_partners } from "../../api/User"
import { view, update } from "../../api/Incomings"

/* Sweet alert */
import Swal from "sweetalert2"

/* Helpers */
import { utils, withRouter } from '../../helpers'

/* Widgets */
import { SelectMenu, SelectItem } from "../../widgets"


/*
    Page Edit Incoming
*/
class EditIncoming extends Component {

    constructor(props) {
        super(props)
        const { params } = this.props

        this.state = {
            id: params.id,
            warehouses: [],//
            warehouseID: 0,//

            amount: "",//
            amountTotal: "",//
            items: [],//

            paymentMethod: "",//
            recipient: "",//

            partner: null,
            partnerID: 0,

            comment: "",

            documentDate: "",

            amountPayment: "",
            amountOther: "",

            warehouseName: "",
            employeeName: "",
            partners: [],

            products_all: [],
            selected_products: [],
            show: false,
        }
    }

    componentDidMount() {
        this.load()
        this.incoming()
        this.products()
        this.partners()
        this.partner()
    }

    /* Load Categories */
    load = () => {
        list_of_warehouses().then(response => {
            if (response.status === 200) {
                this.setState({ warehouses: response.data.warehouses })
            }
        })
    }

    incoming = () => {
        const { id } = this.state
        view({ incomeID: id }).then(response => {
            if (response.status === 200) {

                const data = response.data.incomes

                let products = []

                data.items.forEach((item, index) => {
                    const product = {
                        productID: item.productID,
                        name: item.itemProduct.name,
                        price: item.price,
                        quantity: item.quantity,
                        documentNumber: item.documentNumber,
                        code: item.itemProduct.code,
                        article: item.itemProduct.article
                    }
                    products.push(product)
                })

                this.setState({
                    warehouseID: data.warehouseID,
                    amount: data.amount,
                    amountTotal: data.amountTotal,
                    comment: data.comment,
                    items: products,
                    selected_products: products,
                    paymentMethod: data.paymentMethod,
                    recipient: data.recipient,
                    partner: data.partner,
                    partnerID: data.partner ? data.partner.id : null,
                    documentDate: data.documentDate,
                    amountPayment: data.amountPayment,
                    amountOther: data.amountOther,
                    warehouseName: data.warehouseName,
                    employeeName: data.employeeName
                })
            }
        })

    }

    partners = (search = null) => {
        list_of_partners({ search }).then(response => {
            if (response.status === 200) {
                this.setState({ partners: response.data.data })
            }
        })
    }

    partner = (id) => {

    }

    products = (search = null) => {
        list_of_products({ ...search }).then(response => {
            if (response.status === 200) {
                const array = response.data.data.slice(0, 10)
                this.setState({ products_all: array })
            }
        })
    }


    /* Draw Top */
    _top = () => {
        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/product.png" alt="Product" />
                    <p>Изменить оприходование</p>
                </div>

            </div>
        )
    }



    /* UPDATE INCOME */
    update = () => {

        const { id, warehouseID, amount, amountTotal, selected_products, paymentMethod, partnerID, amountPayment, amountOther, comment} = this.state

        const { navigate } = this.props

        if (amount) {
            if (!utils.isNumeric(amount)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильная Сумма', showConfirmButton: false, timer: 1500 })
                return
            }
        }

        if (amountTotal) {
            if (!utils.isNumeric(amountTotal)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильная оптовая Сумма', showConfirmButton: false, timer: 1500 })
                return
            }
        }

        if (amountPayment) {
            if (!utils.isNumeric(amountPayment)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильная cумма оплаты', showConfirmButton: false, timer: 1500 })
                return
            }
        }

        if (amountPayment) {
            if (!utils.isNumeric(amountPayment)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильная другая сумма', showConfirmButton: false, timer: 1500 })
                return
            }
        }


        const data = {
            incomeID: id,
            warehouseID,
            amount,
            amountTotal,
            comment,
            items: selected_products,
            paymentMethod,
            partnerID,
            amountPayment,
            amountOther,
        }


        update(data).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Успешно изменен',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/incomings')
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }

    setPaymentMethod = (event) => {

        const { amountTotal } = this.state

        this.setState({ paymentMethod: event.target.value })

        if (event.target.value === 'cash' || event.target.value === 'qr') {
            this.setState({ amountPayment: amountTotal })
        }

    }

    setAmountOther = (event) => {

        const { amount, paymentMethod } = this.state

        const value = event.target.value === '' ? 0 : parseFloat(event.target.value)

        this.setState({ amountOther: value, amountTotal: parseFloat(amount) + parseFloat(value) })

        if (paymentMethod === 'cash' || paymentMethod === 'qr') {
            this.setState({ amountPayment: parseFloat(amount) + parseFloat(value) })
        }

    }

    setAmount = (event) => {

        const { amountOther } = this.state

        const value = event.target.value === '' ? 0 : parseFloat(event.target.value)

        this.setState({ amount: value, amountTotal: parseFloat(value) + parseFloat(amountOther) })
    }

    setAmountTotal = (event) => {

        const { paymentMethod } = this.state

        const value = event.target.value === '' ? 0 : parseFloat(event.target.value)

        this.setState({ amountTotal: value })

        if (paymentMethod === 'cash' || paymentMethod === 'qr') {
            this.setState({ amountPayment: parseFloat(value) })
        }

    }

    setAmountPayment = (event) => {

        const value = event.target.value === '' ? 0 : parseFloat(event.target.value)

        this.setState({ amountPayment: value })
    }

    setProducts = (data) => {

        const { amountOther, paymentMethod } = this.state

        let total = 0

        data.forEach(item => {
            total += item.quantity * item.price
        })

        if (paymentMethod === 'cash' || paymentMethod === 'qr') {
            this.setState({ amountPayment: parseFloat(amountOther) + parseFloat(total) })
        }

        this.setState({ selected_products: data, amount: total, amountTotal: parseFloat(amountOther) + parseFloat(total) })
    }

    render = () => {

        const { warehouseID, warehouses, partners, partner, amount, amountTotal, comment, amountPayment, amountOther, products_all, selected_products } = this.state

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">
                    <div className="content-form-box">

                        <div className="content-form-half">

                            <p>Склад</p>
                            <select onChange={event => this.setState({ warehouseID: event.target.value })} value={warehouseID}>
                                <option seleted="true" value={0}>Не выбран</option>
                                {warehouses.map((item, index) =>
                                    <option key={`${index}`} value={item.id}>{item.name}</option>
                                )}
                            </select>

                            <p>Партнер</p>
                            <SelectItem
                                type={true}
                                list={partners}
                                partner={partner}
                                search={(value) => this.partners(value)}
                                setPartner={(data) => this.setState({ partner: data, partnerID: data.id })}
                            />

                            <p>Сумма</p>
                            <input type="text" value={amount} onChange={event => this.setAmount(event)} placeholder="Введите сумму (₸)" />

                            <p>Общая сумма</p>
                            <input type="text" value={amountTotal} onChange={event => this.setAmountTotal(event)} placeholder="Введите общую (₸)" />
                        </div>

                        <div className="content-form-half">

                            <p>Метод оплаты</p>
                            <select onChange={event => this.setPaymentMethod(event)}>
                                <option seleted="true" value={0}>Не выбран</option>
                                <option value='debt'>Долг</option>
                                <option value='cash'>Наличными</option>
                                <option value='qr'>QR</option>
                            </select>

                            <p>Сумма оплаты</p>
                            <input type="text" value={amountPayment} onChange={event => this.setAmountPayment(event)} placeholder="Сумма оплаты" />

                            <p>Другие суммы</p>
                            <input type="text" value={amountOther} onChange={event => this.setAmountOther(event)} placeholder="Другие суммы" />

                            <p>Описание</p>
                            <textarea value={comment} onChange={event => this.setState({ comment: event.target.value })} placeholder="Введите описание"></textarea>
                        </div>

                    </div>

                    <div className="content-form-box">
                        <div className="content-form-half full">
                            <p>Продукты</p>
                            <SelectMenu
                                list={products_all}
                                items={selected_products}
                                search={(data) => this.products(data)}
                                update={(data) => this.setProducts(data)}
                            />
                        </div>
                    </div>

                    <div className="content-form-bottom">
                        <div onClick={() => this.update()} className="content-form-button">Добавить</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(EditIncoming)