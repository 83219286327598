import React, { Component } from "react"

/* Router */
import { Link } from "react-router-dom"

/*
    Permission Page
*/
class Permission extends Component {

    render = () => {
        return (
            <div className="not-found">
                <div className="not-found-title">У вас нет доступа</div>
                <div className="not-found-big">403</div>
                <Link to='/'>Вернуться на главную</Link>
            </div>
        )
    }

}

export default Permission