import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { list, update, create, remove, status } from '../../../api/City'

/* Pagination */
import { Pagination } from '../../../components'

/* SweetAlert */
import Swal from "sweetalert2"

/* Switch */
import Switch from "react-switch"

/* Permission */
import { Permission } from "../../Auth"

/* Page Cities */
class Cities extends Component {

    constructor() {
        super()

        this.state = {
            loading: true,
            data: [],
            page: 1,
            search: "",
            count: 0,

            permission: false,
        }
    }


    componentDidMount = () => {
        this.load()
    }

    /* Load */
    load = (page = 1) => {

        this.setState({ page, loading: true })

        list({ page }).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data.cities, loading: false })
            } else if (response.status === 403) {
                this.setState({permission: true})
            }
        })
    }

    /* Draw Top */
    _top = () => {

        const { count } = this.state

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/pin.png" alt="Pin" />
                    <p>Города</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

                <div className="content-create">
                    <div className="content-create-button" onClick={() => this.create()}>Добавить</div>
                </div>

            </div>
        )
    }



    /* Edit */
    edit = async (item) => {

        const { data } = this.state

        const name = item.name ? item.name : ""

        const { value } = await Swal.fire({
            title: 'Редактировать город',
            html:
                `<input id="edit-name" value="${name}" class="swal2-input" placeholder="Название">`,
            focusConfirm: false,
            confirmButtonColor: '#000',
            confirmButtonText: 'Сохранить',
            preConfirm: () => {
                return {
                    id: item.id,
                    name: document.getElementById('edit-name').value
                }
            }
        })

        update(value).then(response => {
            if (response.status === 200) {

                const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))

                if (index > -1) {
                    data[index].name = value.name
                    data[index].slug = value.slug
                    data[index].lat = value.lat
                    data[index].lng = value.lng

                    this.setState({ data })
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Данные успешно сохранены',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }



    /* Create */
    create = async () => {

        const { data } = this.state

        const { value, isConfirmed } = await Swal.fire({
            title: 'Добавить город',
            html:
                `<input id="create-name" class="swal2-input" placeholder="Название">`,
            focusConfirm: false,
            confirmButtonColor: '#000',
            confirmButtonText: 'Добавить',
            preConfirm: () => {
                return {
                    name: document.getElementById('create-name').value
                }
            }
        })

        if (isConfirmed) {

            if (value.name === "") {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите название', showConfirmButton: false, timer: 1500 })
                return
            }

            create(value).then(response => {
                if (response.status === 200) {

                    data.push(response.data)
                    this.setState({ data })

                    Swal.fire({
                        icon: 'success',
                        title: 'Данные успешно добавлены',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        }

    }


    /* Remove */
    remove = async (item) => {

        Swal.fire({
            title: 'Вы действительно хотите удалить?',
            text: "Невозможно восстановить после удаления",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f44336',
            confirmButtonText: 'Да, удалить!',
            cancelButtonText: 'Нет'
        }).then((result) => {
            if (result.isConfirmed) {
                remove({ cityID: item.id }).then(response => {
                    if (response.status === 200) {

                        const { data } = this.state

                        const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))

                        if (index > -1) {
                            let newData = data
                            newData.splice(index, 1)
                            this.setState({ data: newData })
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Успешно удалено',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Что-то пошло не так! Попробуйте позднее',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }).catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Что-то пошло не так! Попробуйте позднее',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }
        })
    }




    /* Change visible */
    change = (id, value) => {

        const { data } = this.state

        status({ cityID:id, visible: value ? 1 : 0 }).then(response => {
            if (response.status === 200) {

                const index = data.findIndex(e => parseInt(e.id) === parseInt(id))
                if (index > -1) {
                    data[index].visible = value ? 1 : 0
                    this.setState({ data })
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Статус успешно изменен',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }




    /* Draw Content */
    _content = () => {

        const { data, loading, page } = this.state

        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        return (
            <div className="content-data-box">
                {data.map((item, index) =>
                    <div className="content-box-body" key={`${index}`}>
                        <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                        <div className="content-item">{item.name}</div>
                        <div className="content-item content-buttons name">

                            <Switch
                                onChange={value => this.change(item.id, value)}
                                checked={parseInt(item.visible) === 1 ? true : false}
                                onColor="#b1e17a"
                                onHandleColor="#8bc34a"
                                offHandleColor="#fff"
                                offColor="#d7d7d7"
                                handleDiameter={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                height={20}
                                width={40}
                            />

                            <div className="content-item-button" onClick={() => this.edit(item)}>Редактировать</div>
                            <div className="content-item-button delete" onClick={() => this.remove(item)}>Удалить</div>
                        </div>
                    </div>
                )}
            </div>
        )
    }


    render = () => {

        const { count, page, permission } = this.state

        if (permission) {
            return <Permission/>
        }

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">

                    <div className="content-box-head">
                        <div className="content-head number">#</div>
                        <div className="content-head">Название</div>
                        <div className="content-head name"></div>
                    </div>

                    {this._content()}

                    <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page)} />

                </div>

            </div>
        )
    }

}

export default Cities