import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { list, remove } from '../../api/Accounting'

/* Pagination */
import { Pagination } from '../../components'

/* SweetAlert */
import Swal from "sweetalert2"

/* React Router */
import { Link } from "react-router-dom"

/* Helpers */
import { utils } from '../../helpers'

/* Moment */
import moment from "moment"

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

/* Permission */
import { Permission } from "../Auth"


/* Page Products */
class Accounting extends Component {

    constructor() {
        super()

        this.state = {
            loading: true,
            data: [],
            page: 1,
            search: "",
            count: 0,

            startDate: new Date(),
            endDate: new Date(),

            start_at: null,
            end_at: null,

            permission: false
        }
    }


    componentDidMount = () => {
        this.load()
    }

    /* Load */
    load = (page = 1, text = '') => {

        this.setState({ page, loading: true })

        const { start_at, end_at } = this.state

        const search = text.length > 0 ? text : null

        list({ page, search, start_at, end_at }).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data.accountings, count: response.data.pagination.totalItems, loading: false })
            } else if (response.status === 403) {
                this.setState({permission: true})
            }
        })
    }

    /* Search action */
    search = text => {
        this.setState({ search: text })

        if (text !== "") {
            this.setState({ loading: true })

            if (this._timeout) {
                clearTimeout(this._timeout)
            }

            this._timeout = setTimeout(() => {
                this.load(1, text.trim())
            }, 1000)
        }
    }

    filter = () => {
        const { startDate, endDate } = this.state

        // const s_year = startDate.getFullYear()
        // const s_month = this.addZero(startDate.getMonth() + 1)
        // const s_day = this.addZero(startDate.getDay() + 1)

        // const e_year = endDate.getFullYear()
        // const e_month = this.addZero(endDate.getMonth() + 1)
        // const e_day = this.addZero(endDate.getDay() + 2)

        // const start_at = s_year + '-' + s_month + '-' + s_day
        // const end_at = e_year + '-' + e_month + '-' + e_day

        
        const start_at = moment(startDate).format('YYYY-MM-DD')
        const end_at = moment(endDate).add(1, 'd').format('YYYY-MM-DD')

        this.setState({start_at, end_at})

        setTimeout(() => {
            this.load()
        })
    }

    clearFilter = () => {
        this.setState({ start_at: null, end_at: null })
        setTimeout(() => {
            this.load()
        }, 250)
    }

    addZero = (number) => {
        if (parseInt(number) < 10) {
            return '0' + number
        }
        return number
    }


    /* Draw Top */
    _top = () => {

        const { count, search, startDate, endDate, start_at, end_at } = this.state

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/product.png" alt="Product" />
                    <p>Начать учет</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

                <div className="content-create">
                    <Link className="content-create-button" to="/accounting/create">Добавить</Link>
                </div>

                <div className="content-search">
                    <img src="/pictures/search.png" alt="Search" />
                    <input value={search} onChange={event => this.search(event.target.value)} placeholder="Поиск" />
                </div>
                
                <div className="space" />
                <div className="statTime">
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => this.setState({ startDate: date })}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        showConfirmButton
                        shouldCloseOnSelect
                    />
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => this.setState({ endDate: date })}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        showConfirmButton
                    />

                    {start_at && end_at && <div className="clear-button" onClick={() => this.clearFilter()}>Очистить фильтр</div>}

                    <div className="content-create">
                        <div className="content-create-button blue" onClick={() => this.filter()}>Фильтр</div>
                    </div>
                </div>

            </div>
        )
    }



    /* Remove */
    remove = (item) => {

        Swal.fire({
            title: 'Вы действительно хотите удалить?',
            text: "Невозможно восстановить после удаления",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f44336',
            confirmButtonText: 'Да, удалить!',
            cancelButtonText: 'Нет'
        }).then((result) => {
            if (result.isConfirmed) {
                remove({ accountingID: item.id }).then(response => {
                    if (response.status === 200) {

                        const { data } = this.state

                        const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))

                        if (index > -1) {
                            data.splice(index, 1)
                            this.setState({ data })
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Успешно удалено',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Что-то пошло не так! Попробуйте позднее',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }).catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Что-то пошло не так! Попробуйте позднее',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }
        })
    }


    /* Draw Content */
    _content = () => {

        const { data, loading, page } = this.state

        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        return (
            <div className="content-data-box">
                {data.map((item, index) =>
                    <div className="content-box-body" key={`${index}`}>
                        <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                        <div className="content-item">{utils.convertor(item.totalDifference)}</div>
                        <div className="content-item">{moment(item.createdAt).format("DD.MM.YYYY")}</div>
                        {/* <div className="content-item">{item.items ? item.items.length : 0}</div> */}
                        <div className="content-item">{utils.check(item.employee.name)}</div>
                        <div className="content-item content-buttons name">
                            <Link className="content-item-button" to={`/accounting/edit/${item.id}`}>Редактировать</Link>
                            <div className="content-item-button delete" onClick={() => this.remove(item)}>Удалить</div>
                        </div>
                    </div>
                )}
            </div>
        )
    }


    render = () => {

        const { count, page, search, permission } = this.state

        if (permission) {
            return <Permission/>
        }

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">

                    <div className="content-box-head">
                        <div className="content-head number">#</div>
                        <div className="content-head">Разница</div>
                        <div className="content-head">Дата</div>
                        {/* <div className="content-head">Кол-во товаров</div> */}
                        <div className="content-head">Сотрудник</div>
                        <div className="content-head name"></div>
                    </div>

                    {this._content()}

                    <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page, search)} />

                </div>

            </div>
        )
    }

}

export default Accounting