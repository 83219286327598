import React, { Component } from "react"

/* REST API */
import { create_role, permissions } from "../../api/Permissions"

/* Sweet alert */
import Swal from "sweetalert2"

/* Helpers */
import { withRouter } from '../../helpers'

import SelectRoleMenu from "./widgets/SelectRoleMenu"


/*
    Page Create Role
*/
class CreateRole extends Component {


    constructor() {
        super()

        this.state = {
            role: '',

            permissions: null,
            selected_permissions: [],
        }
    }

    componentDidMount = () => {
        this.load()
        this.permissions()
    }

    /* Load Categories */
    load = () => {
    }

    permissions = (search = null) => {
        permissions({ search }).then(response => {
            if (response.status === 200) {
                this.setState({ permissions: response.data.permissions })
            }
        })
    }
    /* Create */
    create = () => {

        const { role, selected_permissions } = this.state
        const { navigate } = this.props
        if (selected_permissions.length === 0) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите разрешении', showConfirmButton: false, timer: 1500 })
            return
        }

        if (role === '') {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите название роли', showConfirmButton: false, timer: 1500 })
            return
        }

        let permissionIDs = []

        selected_permissions.map(item => {
            permissionIDs.push(item.id)
        })

        const data = {
            name: role,
            permissionIDs
        }

        create_role(data).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Успешна добавлена',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/roles')
                    }
                })

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })

    }


    /* Draw Top */
    _top = () => {

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/category.png" alt="Category" />
                    <p>Добавить роль для менеджера</p>
                </div>

            </div>
        )
    }

    render = () => {

        const { permissions, selected_permissions, role } = this.state

        return (
            <div className="content">
                {this._top()}

                <div className="content-box">

                    <div className="content-form-box">

                        <div className="content-form-half">

                            <p>Роль</p>
                            <input type="text" value={role} onChange={event => this.setState({ role: event.target.value })} placeholder="Название" />

                        </div>

                        <div className="content-form-half">
                            <p>Разрешении</p>
                            <SelectRoleMenu
                                list={permissions}
                                items={selected_permissions}
                                update={(data) => this.setState({ selected_permissions: data })}
                            />
                        </div>

                    </div>

                    <div className="content-form-bottom">
                        <div onClick={() => this.create()} className="content-form-button">Добавить</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(CreateRole)