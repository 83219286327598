import React, { Component } from "react"

/* Helpers */
import { withRouter } from '../helpers'

/* Menu data */
import { menu } from "../constants/menu"

import { SideMenu } from "../widgets"

/* Nav Link */
import { NavLink } from "react-router-dom"

/*
    Widget Menu
*/
class Menu extends Component {

    render = () => {
        return (
            <div className="menu">

                <div className="menu-logo">
                    <img src="/pictures/icon.png" alt="Icon" />
                    <h2>T&N GROUP</h2>
                </div>

                <NavLink to="/" className="menu-item">
                    <div className="menu-dot" />
                    <img src="/pictures/dashboard.png" alt="Dashboard" />
                    <div className="menu-name">Dashboard</div>
                </NavLink>

                {
                    menu.map((item, index) => {
                        return (
                            <SideMenu data={item} key={`menu_${index}`} index={index}/>
                        )
                    })
                }

            </div>
        )
    }
}

export default withRouter(Menu)