import React, { Component } from "react"

/* REST API */
import { parent } from '../../../api/Category'
import { view, update, removeImage, uploadGallery, removeGallery } from '../../../api/Product'

/* Sweet alert */
import Swal from "sweetalert2"

/* Helpers */
import { utils, withRouter } from '../../../helpers'

/* Constants */
import { env } from "../../../constants"

/*
    Page Edit Product
*/
class EditProduct extends Component {

    constructor(props) {
        super(props)

        const { params } = this.props

        this.state = {
            id: params.id,
            parents: [],
            name: "",
            article: "",
            slug: "",
            categoryID: 0,

            price: "",
            salePrice: "",
            description: "",

            image: null,
            gallery: [],

            volume: "",
            weight: "",

            place: "",

            code: '',
            viewProduct: '',
            unit: '',

            pricePercent: '',
            salePricePercent: '',
        }
    }

    componentDidMount = () => {
        this.product()
        this.load()
    }

    /* Load Categories */
    load = () => {
        parent().then(response => {
            if (response.status === 200) {
                this.setState({ parents: response.data.categories })
            }
        })
    }

    /* Load Products */
    product = () => {
        const { id } = this.state

        view({ productID: id }).then(response => {
            if (response.status === 200) {

                const data = response.data.product
                
                console.log(data)

                this.setState({
                    name: data.name,
                    article: data.article,
                    slug: data.slug,
                    categoryID: data.categoryID,
                    price: data.price,
                    salePrice: data.salePrice,
                    description: data.description ? data.description : "",
                    picture: data.image ? 'https://back.tn-market.kz/products/' + data.image : null,
                    gallery: data.galleries ? data.galleries : [],
                    volume: data.volume,
                    weight: data.weight,
                    place: data.place ? data.place : "",
                    code: data.code,
                    viewProduct: data.view,
                    unit: data.unit,
                    pricePercent: data.pricePercent,
                    salePricePercent: data.salePricePercent,
                })
            }
        })
    }

    /* Draw Top */
    _top = () => {

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/product.png" alt="Product" />
                    <p>Редактировать продукт</p>
                </div>

            </div>
        )
    }



    /* CREATE PRODUCT */
    create = () => {

        const {
            id, name, article, slug, categoryID, price, salePrice, description,
            volume, weight, place, code, viewProduct, unit, pricePercent, salePricePercent, image, picture
        } = this.state

        const { navigate } = this.props

        if (name === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите название', showConfirmButton: false, timer: 1500 })
            return
        }

        if (article === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите артикул', showConfirmButton: false, timer: 1500 })
            return
        }

        if (slug === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите Key (URL)', showConfirmButton: false, timer: 1500 })
            return
        }

        if (price) {
            if (!utils.isNumeric(price)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильная цена', showConfirmButton: false, timer: 1500 })
                return
            }
        }

        if (code === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите код', showConfirmButton: false, timer: 1500 })
            return
        }

        if (viewProduct === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите вид', showConfirmButton: false, timer: 1500 })
            return
        }
        if (unit === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите единицу измерения', showConfirmButton: false, timer: 1500 })
            return
        }

        if (pricePercent === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите комиссию (роз)', showConfirmButton: false, timer: 1500 })
            return
        }
        if (salePricePercent === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите комиссию (опт)', showConfirmButton: false, timer: 1500 })
            return
        }

        if (salePrice) {
            if (!utils.isNumeric(salePrice)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильная оптовая цена', showConfirmButton: false, timer: 1500 })
                return
            }
        }

        // const data = {
        //     productID: id, name, article, slug, categoryID, price, comment: description, volume, weight, code, view: viewProduct, unit, pricePercent, salePricePercent
        // }

        const obj = new FormData

        obj.append('productID', id)
        obj.append('name', name)
        obj.append('article', article)
        obj.append('slug', slug)
        obj.append('categoryID', categoryID)
        obj.append('price', price)
        obj.append('comment', description)
        obj.append('volume', volume)
        obj.append('weight', weight)
        obj.append('place', place)
        obj.append('code', code)
        obj.append('view', viewProduct)
        obj.append('unit', unit)
        obj.append('pricePercent', pricePercent)
        obj.append('salePricePercent', salePricePercent)

        if (image) {
            obj.append('image', image)
        } else if (picture === null) {
            obj.append('image', null)
        }


        update(obj).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Успешно сохранен',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/product')
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })

    }



    /* Set Name */
    setName = event => {
        this.setState({ name: event.target.value })
        const slug = utils.translit(event.target.value)
        this.setState({ slug })
    }


    /* Upload Image */
    upload = event => {
        let file = event.target.files[0]

        if (file) {
            this.setState({ image: file, picture: URL.createObjectURL(file) })
        }
    }

    /* Remove Image */
    removeImage = () => {
        const { id } = this.state

        removeImage({ productID: id }).then(response => {
            if (response.status === 200) {
                this.setState({ image: null })
            }
        })
    }

    /* Upload to Gallery */
    uploadGallery = event => {

        const { id, gallery } = this.state

        for (let file of event.target.files) {

            const data = new FormData()
            data.append('productID', id)
            data.append('image', file)

            uploadGallery(data).then(response => {
                if (response.status === 200) {
                    gallery.push({ id: response.data.id, image: response.data.image })
                    this.setState({ gallery })
                }
            })
        }
    }


    /* Remove from gallery */
    removeGallery = (item, index) => {
        removeGallery({ imageID: item.id }).then(response => {
            if (response.status === 200) {
                const { gallery } = this.state
                gallery.splice(index, 1)
                this.setState({ gallery })
            }
        })
    }


    render = () => {

        const { name, article, slug, parents, categoryID, price, salePrice, description, viewProduct, picture, volume, weight, place, code, unit, pricePercent, salePricePercent, gallery } = this.state

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">
                    <div className="content-form-box">

                        <div className="content-form-half">
                            <p>Название продукта</p>
                            <input type="text" value={name} onChange={event => this.setName(event)} placeholder="Введите название" />

                            <p>Key (URL)</p>
                            <input type="text" value={slug} onChange={event => this.setState({ slug: event.target.value })} placeholder="Введите Key" />

                            <p>Артикул</p>
                            <input type="text" value={article} onChange={event => this.setState({ article: event.target.value })} placeholder="Введите артикул" />

                            <p>Код</p>
                            <input type="text" value={code} onChange={event => this.setState({ code: event.target.value })} placeholder="Введите код" />

                            <p>Категория</p>
                            <select onChange={event => this.setState({ categoryID: event.target.value })} value={categoryID}>
                                <option seleted="true" value={0}>Не выбран</option>
                                {parents.map((item, index) =>
                                    <option key={`${index}`} value={item.id}>{item.name}</option>
                                )}
                            </select>

                            <p>Цена</p>
                            <input type="text" value={price} onChange={event => this.setState({ price: event.target.value })} placeholder="Введите цену (₸)" />

                            <p>Оптовая цена</p>
                            <input type="text" value={salePrice} onChange={event => this.setState({ salePrice: event.target.value })} placeholder="Введите скидочную цену (₸)" />
                            <p>Основная картинка</p>

                            <div className="content-gallery-box">
                                {/* `${env.mediapoint}products/${image}` */}
                                {picture
                                    ?
                                    <div className="content-gallery-item">
                                        <img src={picture} alt="Gallery" />
                                        <div onClick={() => this.setState({ picture: null, image: null })} className="content-gallery-remove">
                                            <img alt="Minus" src="/pictures/minus.png" />
                                        </div>
                                    </div>
                                    :
                                    <label className="content-gallery-file">
                                        <input multiple={true} onChange={event => this.upload(event)} type="file" name="file" accept="image/png, image/jpeg" />
                                        <div className="content-gallery-button">
                                            <img src="/pictures/image-upload.png" alt="Add" />
                                        </div>
                                    </label>
                                }

                            </div>

                            <p>Галерея</p>

                            <div className="content-gallery-box">

                                <label className="content-gallery-file">
                                    <input multiple={true} onChange={event => this.uploadGallery(event)} type="file" name="file" accept="image/png, image/jpeg" />
                                    <div className="content-gallery-button">
                                        <img src="/pictures/image.png" alt="Add" />
                                    </div>
                                </label>

                                {gallery.map((item, index) =>
                                    <div key={`${index}`} className="content-gallery-item">
                                        <img src={'https://back.tn-market.kz/products/' + item.image} alt="Gallery" />
                                        <div onClick={() => this.removeGallery(item, index)} className="content-gallery-remove">
                                            <img alt="Minus" src="/pictures/minus.png" />
                                        </div>
                                    </div>
                                )}

                            </div>

                        </div>

                        <div className="content-form-half">
                            <p>Вид</p>
                            <input type="text" value={viewProduct} onChange={event => this.setState({ viewProduct: event.target.value })} placeholder="Введите вид" />

                            <p>Единица измерения</p>
                            <input type="text" value={unit} onChange={event => this.setState({ unit: event.target.value })} placeholder="Введите ед.из" />


                            <p>Объем</p>
                            <input type="text" value={volume} onChange={event => this.setState({ volume: event.target.value })} placeholder="Введите объем" />

                            <p>Вес</p>
                            <input type="text" value={weight} onChange={event => this.setState({ weight: event.target.value })} placeholder="Введите вес" />

                            <p>Место</p>
                            <input type="text" value={place} onChange={event => this.setState({ place: event.target.value })} placeholder="Введите место" />

                            <p>Комиссия (роз)</p>
                            <input type="text" value={pricePercent} onChange={event => this.setState({ pricePercent: event.target.value })} placeholder="Введите комиссию (роз)" />

                            <p>Комиссия (опт)</p>
                            <input type="text" value={salePricePercent} onChange={event => this.setState({ salePricePercent: event.target.value })} placeholder="Введите комиссию (опт)" />

                            <p>Описание</p>
                            <textarea value={description} onChange={event => this.setState({ description: event.target.value })} placeholder="Введите описание"></textarea>
                        </div>
                    </div>

                    <div className="content-form-bottom">
                        <div onClick={() => this.create()} className="content-form-button">Сохранить</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(EditProduct)