import React, { Component } from "react"

/* REST API */
import { create } from "../../api/Report"

import { list } from "../../api/User"

/* Sweet alert */
import Swal from "sweetalert2"

/* Helpers */
import { withRouter } from '../../helpers'

/* Constanyt */
import { utils } from "../../helpers"

/* Widgets */
import { SelectItem } from "../../widgets"


/*
    Page Create Report
*/
class CreateReport extends Component {


    constructor() {
        super()

        this.state = {
            partner: null,
            partners: [],

            cashOrder: 0,
            paymentTotal: 0,
            comment: ''
        }
    }

    componentDidMount = () => {
        this.load()
        this.partners()
    }

    /* Load Categories */
    load = () => {
    }

    partners = (search = null) => {
        list({ search }).then(response => {
            if (response.status === 200) {
                this.setState({ partners: response.data.data })
            }
        })
    }

    /* Create */
    create = () => {

        const { partner, cashOrder, paymentTotal, comment } = this.state
        const { navigate } = this.props
        if (!partner) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите партнера', showConfirmButton: false, timer: 1500 })
            return
        }

        if (cashOrder) {
            if (!utils.isNumeric(cashOrder)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильный долг', showConfirmButton: false, timer: 1500 })
                return
            }
        }

        if (paymentTotal) {
            if (!utils.isNumeric(paymentTotal)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильная сумма оплаты', showConfirmButton: false, timer: 1500 })
                return
            }
        }

        const data = {
            partnerID: partner.id,
            comment,
            paymentTotal,
            cashOrder,
        }


        create(data).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Успешна добавлена',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/report')
                    }
                })

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })

    }

    setCash = (event) => {

        const value = event.target.value === '' ? 0 : parseFloat(event.target.value)

        this.setState({ cashOrder: value })
    }

    setTotal = (event) => {

        const value = event.target.value === '' ? 0 : parseFloat(event.target.value)

        this.setState({ paymentTotal: value })
    }



    /* Draw Top */
    _top = () => {

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/category.png" alt="Category" />
                    <p>Добавить бухгалтерию</p>
                </div>

            </div>
        )
    }

    render = () => {

        const { partners, partner, comment, cashOrder, paymentTotal } = this.state

        return (
            <div className="content">
                {this._top()}

                <div className="content-box">

                    <div className="content-form-box">

                        <div className="content-form-half">

                            <p>Партнер</p>
                            <SelectItem
                                type={true}
                                list={partners}
                                partner={partner}
                                search={(value) => this.partners(value)}
                                setPartner={(data) => this.setState({ partner: data })}
                            />
                            <p>Описание</p>
                            <textarea value={comment} onChange={event => this.setState({ comment: event.target.value })} placeholder="Введите комментарий"></textarea>
                        </div>

                        <div className="content-form-half">
                            <p>Кассовый ордер</p>
                            <input type="text" value={cashOrder} onChange={event => this.setCash(event)} placeholder="Введите кассовый ордер (₸)" />

                            <p>Долг</p>
                            <input type="text" value={paymentTotal} onChange={event => this.setTotal(event)} placeholder="Введите долг (₸)" />

                        </div>

                    </div>

                    <div className="content-form-bottom">
                        <div onClick={() => this.create()} className="content-form-button">Добавить</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(CreateReport)