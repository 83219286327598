import React, { Component } from "react"

/* REST API */
import { update, view } from "../../api/Report"

import { list } from "../../api/User"

/* Sweet alert */
import Swal from "sweetalert2"

/* Helpers */
import { withRouter } from '../../helpers'

/* Constanyt */
import { utils } from "../../helpers"

/* Widgets */
import { SelectItem } from "../../widgets"


/*
    Page Create Report
*/
class CreateReport extends Component {


    constructor(props) {
        super(props)

        const { params } = this.props

        this.state = {
            id: params.id,
            partner: null,
            partners: [],

            cashOrder: '',
            paymentTotal: '',
            comment: ''
        }
    }

    componentDidMount = () => {
        this.load()
        this.partners()
        this.report()
    }

    /* Load Categories */
    load = () => {

    }

    report = () => {
        const { id } = this.state
        view({ reportID: id }).then(response => {
            if (response.status === 200) {

                const data = response.data.report

                this.setState({
                    partner: data.partner,
                    comment: data.comment,
                    cashOrder: data.cashOrder,
                    paymentTotal: data.paymentTotal
                })
            }
        })
    }

    partners = (search = null) => {
        list({ search }).then(response => {
            if (response.status === 200) {
                this.setState({ partners: response.data.data })
            }
        })
    }

    /* Create */
    create = () => {

        const { id, partner, cashOrder, paymentTotal, comment } = this.state
        const { navigate } = this.props
        if (!partner) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите партнера', showConfirmButton: false, timer: 1500 })
            return
        }

        if (cashOrder) {
            if (!utils.isNumeric(cashOrder)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильный долг', showConfirmButton: false, timer: 1500 })
                return
            }
        }

        if (paymentTotal) {
            if (!utils.isNumeric(paymentTotal)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильная сумма оплаты', showConfirmButton: false, timer: 1500 })
                return
            }
        }

        const data = {
            reportID: id,
            partnerID: partner.id,
            comment,
            paymentTotal,
            cashOrder,
        }


        update(data).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Успешна обновлен',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/report')
                    }
                })

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })

    }


    /* Draw Top */
    _top = () => {

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/category.png" alt="Category" />
                    <p>Изменить бухгалтерию</p>
                </div>

            </div>
        )
    }

    render = () => {

        const { partners, partner, comment, cashOrder, paymentTotal } = this.state

        return (
            <div className="content">
                {this._top()}

                <div className="content-box">

                    <div className="content-form-box">

                        <div className="content-form-half">

                            <p>Партнер</p>
                            <SelectItem
                                list={partners}
                                partner={partner}
                                search={(value) => this.partners(value)}
                                setPartner={(data) => this.setState({ partner: data })}
                            />
                            <p>Описание</p>
                            <textarea value={comment} onChange={event => this.setState({ comment: event.target.value })} placeholder="Введите комментарий"></textarea>
                        </div>

                        <div className="content-form-half">
                            <p>Долг</p>
                            <input type="text" value={cashOrder} onChange={event => this.setState({ cashOrder: event.target.value })} placeholder="Введите долг (₸)" />

                            <p>Оплачено</p>
                            <input type="text" value={paymentTotal} onChange={event => this.setState({ paymentTotal: event.target.value })} placeholder="Введите сумму оплаты (₸)" />

                        </div>

                    </div>

                    <div className="content-form-bottom">
                        <div onClick={() => this.create()} className="content-form-button">Сохранить</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(CreateReport)