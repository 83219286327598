import axios from "axios"
import { env } from '../constants'


/* Product */
export const list = data => {
    console.log(data)
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/list`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Update */
export const update = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/edit`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Create */
export const create = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/create`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}


/* Remove */
export const remove = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/delete`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* View */
export const view = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/view`
    const headers = { headers: { Authorization: `Bearer ${token}` }, params: data }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

export const file = (data) => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/pdf`
    const headers = { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' }
    
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

export const exportFile = (data) => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/export`
    const headers = { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' }
    
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

export const export_all = (data) => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/list/export`
    const headers = { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' }
    
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* UPDATE */
export const changeDeliveryType = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/deliveryMethod`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* UPDATE */
export const changePaymentType = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/paymentMethod`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* UPDATE */
export const changePaymentStatus = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}order/paymentStatus`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}
