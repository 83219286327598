import axios from "axios"
import { env } from '../constants'


/* Reports */
export const list = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}report/list`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Act */
export const act = data => {
    console.log(data)
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}report/act`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Update */
export const update = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}report/edit`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Create */
export const create = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}report/create`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}


/* Remove */
export const remove = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}report/delete`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* View */
export const view = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}report/view`
    const headers = { headers: { Authorization: `Bearer ${token}` }, params: data }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* Export file */
export const export_file = () => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}report/export`
    const headers = { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' }
    return axios.post(uri, {}, headers).then(response => response).catch(error => error.response)
}
