import React, { Component } from "react"

/* REST API */
import { parent, create } from '../../../api/Category'

/* Sweet alert */
import Swal from "sweetalert2"

/* Helpers */
import { utils, withRouter } from '../../../helpers'




/*
    Page CreateCategory
*/
class CreateCategory extends Component {


    constructor() {
        super()

        this.state = {
            parents: [],
            name: "",
            slug: "",
            parentID: 0,
            image: null,
        }
    }

    componentDidMount = () => {
        this.load()
    }

    /* Load Categories */
    load = () => {
        parent().then(response => {
            if (response.status === 200) {
                this.setState({ parents: response.data.categories })
            }
        })
    }


    /* Upload Image */
    upload = event => {
        let file = event.target.files[0]

        if (file) {
            this.setState({ image: file })
        }
    }


    /* Create */
    create = () => {

        const { name, slug, parentID, image } = this.state
        const { navigate } = this.props

        if (name === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите название', showConfirmButton: false, timer: 1500 })
            return
        }

        if (slug === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите Key (URL)', showConfirmButton: false, timer: 1500 })
            return
        }


        const data = new FormData()
        data.append('image', image)
        data.append('name', name)
        data.append('slug', slug)
        data.append('parentID', parentID)

        create(data).then(response => {
            if (response.status === 200) {

                Swal.fire({
                    icon: 'success',
                    title: 'Успешна добавлена',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/category')
                    }
                })

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })

    }


    /* Draw Top */
    _top = () => {

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/category.png" alt="Category" />
                    <p>Добавить категорию</p>
                </div>

            </div>
        )
    }


    /* Set Name */
    setName = event => {
        this.setState({ name: event.target.value })
        const slug = utils.translit(event.target.value)
        this.setState({ slug })
    }


    render = () => {

        const { name, slug, image, parents } = this.state

        return (
            <div className="content">
                {this._top()}

                <div className="content-box">

                    <div className="content-form-box">

                        <div className="content-form-half">

                            <p>Название категории</p>
                            <input type="text" value={name} onChange={event => this.setName(event)} placeholder="Введите название" />

                            <p>Key (URL)</p>
                            <input type="text" value={slug} onChange={event => this.setState({ slug: event.target.value })} placeholder="Введите Key" />


                            <p>Родительская категория</p>
                            <select onChange={event => this.setState({ parentID: event.target.value })}>
                                <option seleted="true" value={0}>Не выбран</option>
                                {parents.map((item, index) =>
                                    <option key={`${index}`} value={item.id}>{item.name}</option>
                                )}
                            </select>

                            <div className="content-space" />

                            <p>Картинка</p>
                            <label className="content-input-file">
                                <input onChange={event => this.upload(event)} type="file" name="file" accept="image/png, image/jpeg" />
                                <span className="content-input-file-button">Выберите файл</span>
                                <span className="content-input-file-text">
                                    {(image && image.name !== null) ? image.name : "Максимум 10MB"}
                                </span>
                            </label>

                        </div>

                        <div className="content-form-half">
                        </div>

                    </div>

                    <div className="content-form-bottom">
                        <div onClick={() => this.create()} className="content-form-button">Добавить</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(CreateCategory)