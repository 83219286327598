import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Sweet Alert */
import Swal from 'sweetalert2'

/* REST API */
import { register } from '../../api/Manager'

/* Router */
import { Link } from "react-router-dom"





/*
    Page Register
*/
class Register extends Component {

    constructor() {
        super()

        this.state = {
            name: "",
            email: "",
            password: "",
            repeat: "",
            loading: "",
            secure: false
        }
    }


    /* Register action */
    register = () => {

        const { name, email, password, repeat } = this.state

        if (name === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите свое имя', showConfirmButton: false, timer: 1500 })
            return
        }

        if (email === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите Email', showConfirmButton: false, timer: 1500 })
            return
        }

        if (!/^\S+@\S+\.\S+$/.test(email)) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильный Email', showConfirmButton: false, timer: 1500 })
            return
        }

        if (password === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите пароль', showConfirmButton: false, timer: 1500 })
            return
        }

        if (password.length < 8) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите пароль больше 8 символов', showConfirmButton: false, timer: 1500 })
            return
        }

        if (password !== repeat) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Пароли не совпадают', showConfirmButton: false, timer: 1500 })
            return
        }

        this.setState({ loading: true })


        register({ name, email, password }).then(response => {

            if (response.status === 200) {
                
                this.setState({
                    name: "",
                    email: "",
                    password: "",
                    repeat: "",
                    loading: "",
                    secure: false
                })

                Swal.fire({
                    icon: 'success',
                    title: 'Вы успешно зарегистрировались',
                    text: 'Ожидайте ответ от модератора',
                    showConfirmButton: false,
                })

            }
            else if (response.status === 401) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Менеджер с таким Email уже существует', showConfirmButton: false, timer: 1500 })
            }
            else {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
            }
        }).catch(() => {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }


    render = () => {

        const { name, email, password, repeat, loading, secure } = this.state

        return (
            <div className="login-page">

                <div className="login-box">

                    <div className="login-logo">
                        <img src="/pictures/icon.png" alt="Icon" />
                        <h2>T&N GROUP</h2>
                    </div>

                    <h2 className="login-title">Регистрация</h2>
                    <p className="login-text">Чтобы создать аккаунт, введите свои данные</p>


                    <div className="form-box">
                        <img src="/pictures/account.png" alt="Account" />
                        <input placeholder="Ваше Имя" value={name} onChange={event => this.setState({ name: event.target.value })} />
                    </div>

                    <div className="form-box">
                        <img src="/pictures/mail.png" alt="Mail" />
                        <input placeholder="Email" value={email} onChange={event => this.setState({ email: event.target.value })} />
                    </div>

                    <div className="form-box">
                        <img src="/pictures/lock.png" alt="Lock" />
                        <input placeholder="Создайте пароль" type={secure ? "text" : "password"} value={password} onChange={event => this.setState({ password: event.target.value })} />
                        <div className="login-secure" onClick={() => this.setState({ secure: !secure })}>
                            {secure ? <img className="hide" src="/pictures/hide.png" alt="Eye" /> : <img src="/pictures/show.png" alt="Eye" />}
                        </div>
                    </div>

                    <div className="form-box">
                        <img src="/pictures/lock.png" alt="Lock" />
                        <input placeholder="Повторите пароль" type={secure ? "text" : "password"} value={repeat} onChange={event => this.setState({ repeat: event.target.value })} />
                        <div className="login-secure" onClick={() => this.setState({ secure: !secure })}>
                            {secure ? <img className="hide" src="/pictures/hide.png" alt="Eye" /> : <img src="/pictures/show.png" alt="Eye" />}
                        </div>
                    </div>

                    <button onClick={() => this.register()} disabled={loading} className="login-button">
                        {loading ? <LoadingOutlined /> : "Войти"}
                    </button>

                    <p className="login-link">У вас уже есть аккаунт? <Link to="/">Войти</Link></p>

                </div>

            </div>
        )
    }

}

export default Register