import axios from "axios"
import { env } from '../constants'

/* Income */
export const income_list = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}statistics/income`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Order */
export const order_list = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}statistics/order`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Order User */
export const order_user_list = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}statistics/order/user`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Act */
export const act_user = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}statistics/act`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}