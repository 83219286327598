import React, { Component } from "react";
import MenuItems from "./MenuItems";

class Dropdown extends Component {

  render = () => {

    const { submenus, dropdown, depthLevel } = this.props

    const depth = depthLevel + 1;
    const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";

    return (
      <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
        {submenus.map((submenu, index) => (
          <MenuItems items={submenu} last={submenus.length - 1 === index} key={index} depthLevel={depth} />
        ))}
      </ul>
    );
  }
};

export default Dropdown;