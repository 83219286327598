import React, { Component } from "react"

/* REST API */
import { edit, view } from '../../../api/Remainder'
import { list as list_of_warehouses } from "../../../api/Warehouse"
import { list as list_of_products } from "../../../api/Product"

/* Sweet alert */
import Swal from "sweetalert2"

/* Helpers */
import { utils, withRouter } from '../../../helpers'

/* Widgets */
import SelectMenuRemainder from "./widgets/SelectMenuRemainder"


/*
    Page EditRemainder
*/
class EditRemainder extends Component {


    constructor(props) {
        super(props)

        const { params } = this.props

        this.state = {
            id: params.id,
            warehouses: [],
            warehouseID: 0,
            productID: 0,
            count: 0,
            products_all: [],
            selected_products: [],

            product: 0,

            maxCount: 0,
            minCount: 0,
        }
    }

    componentDidMount = () => {
        this.load()
        this.products()
        this.inform()
    }

    /* Load Categories */
    load = () => {
        list_of_warehouses().then(response => {
            if (response.status === 200) {
                this.setState({ warehouses: response.data.warehouses })
            }
        })
    }

    inform = () => {
        const { id } = this.state
        view({ stockID: id }).then(response => {

            if (response.status === 200) {

                const data = response.data.data

                const product = {
                    productID: data.productID,
                    name: data.product.name,
                    article: data.product.article,
                    code: data.product.code
                }
                const warehouse = {
                    id: data.warehouseID,
                    name: data.warehouse.name
                }
                this.setState({
                    selected_products: [product],
                    product: product,
                    productID: data.productID,
                    count: data.count,
                    maxCount: data.maxCount,
                    minCount: data.minCount,
                    warehouse: warehouse,
                    warehouseID: data.warehouseID,
                })
            }
        })
    }

    products = (search = null) => {
        list_of_products({ ...search }).then(response => {
            if (response.status === 200) {
                const array = response.data.data.slice(0, 10)
                this.setState({ products_all: array })
            }
        })
    }

    /* Create */
    create = () => {

        const { id, selected_products, warehouseID, count, minCount, maxCount } = this.state
        const { navigate } = this.props
        if (!warehouseID) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите склад', showConfirmButton: false, timer: 1500 })
            return
        }

        if (selected_products.length < 1) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите продукт', showConfirmButton: false, timer: 1500 })
            return
        }

        if (String(count).length > 0) {
            if (!utils.isNumeric(count)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильная кол-во', showConfirmButton: false, timer: 1500 })
                return
            }
        }


        const data = {
            stockID: id,
            warehouseID,
            productID: selected_products[0].productID,
            count,
            minCount,
            maxCount
        }

        edit(data).then(response => {
            if (response.status === 200) {

                Swal.fire({
                    icon: 'success',
                    title: 'Успешна добавлена',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/remainder')
                    }
                })

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })

    }


    /* Draw Top */
    _top = () => {

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/category.png" alt="Category" />
                    <p>Изменить остаток</p>
                </div>

            </div>
        )
    }

    render = () => {

        const { products_all, selected_products, warehouses, count, maxCount, minCount, warehouseID } = this.state

        return (
            <div className="content">
                {this._top()}

                <div className="content-box">

                    <div className="content-form-box">

                        <div className="content-form-half">

                            <p>Склад</p>
                            <select value={warehouseID} onChange={event => this.setState({ warehouseID: event.target.value })}>
                                <option seleted="true" value={0}>Не выбран</option>
                                {warehouses.map((item, index) =>
                                    <option key={`${index}`} value={item.id}>{item.name}</option>
                                )}
                            </select>

                            <p>Продукт</p>
                            <SelectMenuRemainder
                                list={products_all}
                                items={selected_products}
                                search={(data) => this.products(data)}
                                update={(data) => this.setState({ selected_products: data })}
                            />

                            <p>Кол-во</p>
                            <input type="text" value={count} onChange={event => this.setState({ count: event.target.value })} placeholder="Введите кол-во" />
                                    
                            <p>MIN кол-во</p>
                            <input type="text" value={minCount} onChange={event => this.setState({ minCount: event.target.value })} placeholder="Введите MIN кол-во" />
 
                            <p>MAX кол-во</p>
                            <input type="text" value={maxCount} onChange={event => this.setState({ maxCount: event.target.value })} placeholder="Введите MAX кол-во" />
                        
                        </div>

                        <div className="content-form-half">
                        </div>

                    </div>

                    <div className="content-form-bottom">
                        <div onClick={() => this.create()} className="content-form-button">Добавить</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(EditRemainder)