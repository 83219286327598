import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { admins, remove } from '../../api/User'

/* Pagination */
import { Pagination } from '../../components'

/* Helpers */
import { utils } from '../../helpers'

/* SweetAlert */
import Swal from "sweetalert2"
import { env } from "../../constants"

/* Permission */
import { Permission } from "../Auth"

/* Moment */
import moment from "moment"
import 'moment/locale/ru'
moment.locale('ru')


/* Page Users */
class Users extends Component {

    constructor() {
        super()

        this.state = {
            loading: true,
            data: [],
            page: 1,
            search: "",
            count: 0,

            permission: false
        }
    }


    componentDidMount = () => {
        this.load()
    }

    /* Load */
    load = (page = 1, text = '') => {

        this.setState({ page, loading: true })

        admins({ page, search: text }).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data.data, count: response.data.pagination.totalItems, loading: false })
            } else if (response.status === 403) {
                this.setState({ permission: true })
            }
        })
    }

    /* Search action */
    search = text => {
        this.setState({ search: text })

        if (text !== "") {
            this.setState({ loading: true })

            if (this._timeout) {
                clearTimeout(this._timeout)
            }

            this._timeout = setTimeout(() => {
                this.load(1, text.trim())
            }, 1000)
        }
    }


    /* Draw Top */
    _top = () => {

        const { count, search } = this.state

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/user.png" alt="User" />
                    <p>Администраторы</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

                <div className="content-search">
                    <img src="/pictures/search.png" alt="Search" />
                    <input value={search} onChange={event => this.search(event.target.value)} placeholder="Поиск" />
                </div>

            </div>
        )
    }



    /* Edit */
    edit = async (item) => {

        Swal.fire({
            title: 'Посмотреть',
            html: `
                <div class="content-layout-content">
                    <p><b>Имя: </b> ${utils.check(item.name)}</p>
                    <p><b>Email: </b> ${utils.check(item.email)}</p>
                    <p><b>Телефон: </b> ${utils.formatter(item.phone, "+# (###) ###-##-##")}</p>
                    <p><b>Изображение: </b> ${utils.check(item.image)}</p>
                    <p><b>День рождение: </b> ${item.birthday ? moment(item.birthday).format("Do MMMM YYYY") : "-"}</p>
                    <p><b>Пол: </b> ${utils.gender(item.gender)}</p>
                    <p><b>Дата регистрации: </b> ${item.createdAt ? moment(item.createdAt).format("Do MMMM YYYY, hh:mm") : "-"}</p>
                </div>
            `,
            focusConfirm: false,
            confirmButtonColor: '#000',
            confirmButtonText: 'Закрыть',
        })
    }


    /* Remove */
    remove = async (item) => {

        Swal.fire({
            title: 'Вы действительно хотите удалить?',
            text: "Невозможно восстановить после удаления",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f44336',
            confirmButtonText: 'Да, удалить!',
            cancelButtonText: 'Нет'
        }).then((result) => {
            if (result.isConfirmed) {
                remove({ userId: item.id }).then(response => {
                    if (response.status === 200) {

                        const { data } = this.state

                        const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))

                        if (index > -1) {
                            let newData = data
                            newData.splice(index, 1)
                            this.setState({ data: newData })
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Успешно удалено',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Что-то пошло не так! Попробуйте позднее',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }).catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Что-то пошло не так! Попробуйте позднее',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }
        })
    }



    /* Show Image */
    image = img => {
        if (img) {
            Swal.fire({
                imageUrl: `${env.mediapoint}users/${img}`,
                imageHeight: 300,
                imageAlt: 'Category',
                confirmButtonColor: '#000',
                confirmButtonText: 'Закрыть'
            })
        }
    }



    /* Draw Content */
    _content = () => {

        const { data, loading, page } = this.state

        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        return (
            <div className="content-data-box">
                {data.map((item, index) =>
                    <div className="content-box-body" key={`${index}`}>
                        <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                        <div className="content-item">
                            <b>{utils.check(item.name)}</b>
                            {item.image && <img onClick={() => this.image(item.image)} className="ava" src={`${env.mediapoint}users/${item.image}`} alt="User" />}
                        </div>
                        <div className="content-item">{utils.check(item.email)}</div>
                        <div className="content-item">{utils.formatter(item.phone, "+# (###) ###-##-##")}</div>
                        <div className="content-item content-buttons name">
                            <div className="content-item-button" onClick={() => this.edit(item)}>Посмотреть</div>
                            <div className="content-item-button delete" onClick={() => this.remove(item)}>Удалить</div>
                        </div>
                    </div>
                )}
            </div>
        )
    }


    render = () => {

        const { count, page, search, permission } = this.state

        if (permission) {
            return <Permission />
        }

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">

                    <div className="content-box-head">
                        <div className="content-head number">#</div>
                        <div className="content-head">Имя</div>
                        <div className="content-head">Email</div>
                        <div className="content-head">Телефон</div>
                        <div className="content-head name"></div>
                    </div>

                    {this._content()}

                    <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page, search)} />

                </div>

            </div>
        )
    }

}

export default Users