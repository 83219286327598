import React, { Component } from 'react'

/* Router */
import { NavLink } from "react-router-dom"

/* SideSubmenu */

import SideSubMenu from './SideSubMenu'

class SideMenu extends Component {
    constructor() {
        super()
        this.state = {
            isHidden: true,
        }
    }

    componentDidMount = () => {

    }

    render = () => {

        const { data, index } = this.props
        const { isHidden } = this.state

        return (
            <div>
                <div className={`menu-space ${!index ? 'mt10' : ''}`} onClick={() => this.setState({ isHidden: !isHidden })}>
                    <p className='name'>{data.name}</p>
                    <img src='/pictures/menu/arrow.png' className={`triangle ${!isHidden ? "triangle-top" : "triangle-bottom"}`} />
                </div>
                <div className={`sidemenu ${!isHidden ? 'active' : ''}`}>
                    {
                        data.submenu.map((item, index) => {
                            if (!item.submenu) {
                                return (
                                    <NavLink to={item.link} className="menu-item" key={`nav_${index}`}>
                                        <div className="menu-dot" key={`submenu_${index}`} />
                                        <img src={item.image ? item.image : '/pictures/menu/empty.png'} alt="Category" />
                                        <div className="menu-name">{item.name}</div>
                                    </NavLink>
                                )
                            } else {
                                return (
                                    <SideSubMenu data={item} key={`side_sub_menu_${index}`} index={index} />
                                )
                            }
                        })
                    }
                </div>
            </div>
        )
    }
}

export default SideMenu