import React, { Component } from "react"

/* REST API */
import { parent } from '../../../api/Category'
import { create, uploadGallery, removeGallery } from '../../../api/Product'

/* Sweet alert */
import Swal from "sweetalert2"

/* Helpers */
import { utils, withRouter } from '../../../helpers'


/*
    Page Create Product
*/
class CreateProduct extends Component {

    constructor() {
        super()

        this.state = {
            parents: [],
            name: "",
            article: "",
            slug: "",
            categoryID: 0,

            price: "",
            salePrice: "",
            description: "",

            image: null,
            picture: null,

            gallery: [],
            files: [],

            volume: "",
            weight: "",

            code: '',
            view: '',
            unit: '',

            pricePercent: '',
            salePricePercent: '',

            place: '',
        }
    }

    componentDidMount = () => {
        this.load()
    }


    /* Load Categories */
    load = () => {
        parent().then(response => {
            if (response.status === 200) {
                this.setState({ parents: response.data.categories })
            }
        })
    }

    /* Draw Top */
    _top = () => {
        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/product.png" alt="Product" />
                    <p>Добавить продукт</p>
                </div>

            </div>
        )
    }



    /* CREATE PRODUCT */
    create = () => {

        const { name, article, slug, categoryID, price, salePrice, volume, weight, code, view, unit, description, image, pricePercent, salePricePercent, place, gallery } = this.state

        const { navigate } = this.props

        if (name === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите название', showConfirmButton: false, timer: 1500 })
            return
        }

        if (article === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите артикул', showConfirmButton: false, timer: 1500 })
            return
        }

        if (slug === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите Key (URL)', showConfirmButton: false, timer: 1500 })
            return
        }

        if (price) {
            if (!utils.isNumeric(price)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильная цена', showConfirmButton: false, timer: 1500 })
                return
            }
        }

        if (salePrice) {
            if (!utils.isNumeric(salePrice)) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильная оптовая цена', showConfirmButton: false, timer: 1500 })
                return
            }
        }

        if (code === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите код', showConfirmButton: false, timer: 1500 })
            return
        }

        if (view === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите вид', showConfirmButton: false, timer: 1500 })
            return
        }
        if (unit === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите юнит', showConfirmButton: false, timer: 1500 })
            return
        }

        if (pricePercent === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите комиссию (роз)', showConfirmButton: false, timer: 1500 })
            return
        }
        if (salePricePercent === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите комиссию (опт)', showConfirmButton: false, timer: 1500 })
            return
        }

        const data = new FormData()
        data.append('name', name)

        data.append('article', article)

        data.append('slug', slug)
        data.append('categoryID', categoryID)

        data.append('image', image)

        data.append('price', price)
        data.append('salePrice', salePrice)

        data.append('volume', volume)
        data.append('weight', weight)
        data.append('weight', weight)
        
        data.append('code', code)
        data.append('view', view)
        data.append('unit', unit)

        data.append('pricePercent', pricePercent)
        data.append('salePricePercent', salePricePercent)

        if (gallery.length > 0) {
            gallery.forEach((picture) => {
                data.append(`gallery`, picture)
            })
        }

        data.append('comment', description)

        data.append('place', place)

        create(data).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Успешно создан',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/product')
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })

    }



    /* Set Name */
    setName = event => {
        this.setState({ name: event.target.value })
        const slug = utils.translit(event.target.value)
        this.setState({ slug })
    }

    /* Upload Image */
    upload = event => {
        let file = event.target.files[0]

        if (file) {
            this.setState({ image: file, picture: URL.createObjectURL(file) })
        }
    }

    /* Upload to Gallery */
    uploadGallery = event => {

        const { gallery, files } = this.state

        for (let file of event.target.files) {
            gallery.push(file)
            files.push(URL.createObjectURL(file))
        }

        this.setState({ files, gallery })
    }


    /* Remove from gallery */
    removeGallery = index => {
        const { gallery, files } = this.state

        gallery.splice(index, 1)
        files.splice(index, 1)

        this.setState({ files, gallery })
    }

    render = () => {

        const { name, article, slug, code, view, unit, parents, price, salePrice, description, volume, weight, picture, pricePercent, salePricePercent, place, files } = this.state

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">
                    <div className="content-form-box">

                        <div className="content-form-half">
                            <p>Название продукта</p>
                            <input type="text" value={name} onChange={event => this.setName(event)} placeholder="Введите название" />

                            <p>Key (URL)</p>
                            <input type="text" value={slug} onChange={event => this.setState({ slug: event.target.value })} placeholder="Введите Key" />

                            <p>Артикул</p>
                            <input type="text" value={article} onChange={event => this.setState({ article: event.target.value })} placeholder="Введите артикул" />

                            <p>Код</p>
                            <input type="text" value={code} onChange={event => this.setState({ code: event.target.value })} placeholder="Введите код" />

                            <p>Категория</p>
                            <select onChange={event => this.setState({ categoryID: event.target.value })}>
                                <option seleted="true" value={0}>Не выбран</option>
                                {parents.map((item, index) =>
                                    <option key={`${index}`} value={item.id}>{item.name}</option>
                                )}
                            </select>

                            <p>Цена</p>
                            <input type="text" value={price} onChange={event => this.setState({ price: event.target.value })} placeholder="Введите цену (₸)" />

                            <p>Оптовая цена</p>
                            <input type="text" value={salePrice} onChange={event => this.setState({ salePrice: event.target.value })} placeholder="Введите скидочную цену (₸)" />
                            <p>Основная картинка</p>

                            <div className="content-gallery-box">
                                {picture
                                    ?
                                    <div className="content-gallery-item">
                                        <img src={picture} alt="Gallery" />
                                        <div onClick={() => this.setState({ picture: null, image: null })} className="content-gallery-remove">
                                            <img alt="Minus" src="/pictures/minus.png" />
                                        </div>
                                    </div>
                                    :
                                    <label className="content-gallery-file">
                                        <input multiple={true} onChange={event => this.upload(event)} type="file" name="file" accept="image/png, image/jpeg" />
                                        <div className="content-gallery-button">
                                            <img src="/pictures/image-upload.png" alt="Add" />
                                        </div>
                                    </label>
                                }
                            </div>
                            <p>Галерея</p>

                            <div className="content-gallery-box">

                                <label className="content-gallery-file">
                                    <input multiple={true} onChange={event => this.uploadGallery(event)} type="file" name="file" accept="image/png, image/jpeg" />
                                    <div className="content-gallery-button">
                                        <img src="/pictures/image.png" alt="Add" />
                                    </div>
                                </label>

                                {files.map((item, index) =>
                                    <div key={`${index}`} className="content-gallery-item">
                                        <img src={item} alt="Gallery" />
                                        <div onClick={() => this.removeGallery(index)} className="content-gallery-remove">
                                            <img alt="Minus" src="/pictures/minus.png" />
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>

                        <div className="content-form-half">
                            <p>Вид</p>
                            <input type="text" value={view} onChange={event => this.setState({ view: event.target.value })} placeholder="Введите вид" />

                            <p>Единица измерения</p>
                            <input type="text" value={unit} onChange={event => this.setState({ unit: event.target.value })} placeholder="Введите ед.из" />


                            <p>Объем</p>
                            <input type="text" value={volume} onChange={event => this.setState({ volume: event.target.value })} placeholder="Введите объем" />

                            <p>Вес</p>
                            <input type="text" value={weight} onChange={event => this.setState({ weight: event.target.value })} placeholder="Введите вес" />

                            <p>Комиссия (роз)</p>
                            <input type="text" value={pricePercent} onChange={event => this.setState({ pricePercent: event.target.value })} placeholder="Введите комиссию (роз)" />

                            <p>Комиссия (опт)</p>
                            <input type="text" value={salePricePercent} onChange={event => this.setState({ salePricePercent: event.target.value })} placeholder="Введите комиссию (опт)" />

                            <p>Место</p>
                            <input type="text" value={place} onChange={event => this.setState({ place: event.target.value })} placeholder="Введите место" />

                            <p>Описание</p>
                            <textarea value={description} onChange={event => this.setState({ description: event.target.value })} placeholder="Введите описание"></textarea>
                        </div>

                    </div>

                    <div className="content-form-bottom">
                        <div onClick={() => this.create()} className="content-form-button">Добавить</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(CreateProduct)