import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { act_user } from '../../api/Statistics'

import { list as list_of_products } from "../../api/Product"

import { list as list_of_partners } from "../../api/User"

/* Pagination */
import { Pagination } from '../../components'

// /* SweetAlert */
// import Swal from "sweetalert2"

/* Moment */
import moment from "moment"

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

/* Helpers */
import { utils } from '../../helpers'

import { SelectItem } from "../../widgets"

/* Permission */
import { Permission } from "../Auth"

/* Page Act */

class Act extends Component {

    constructor() {
        super()

        this.state = {
            loading: false,
            data: [],
            page: 1,
            count: 0,

            search: "",
            partners: [],
            partner: null,
            partnerID: null,

            product: 0,

            searchMethod: null,

            permission: false,

            startDate: new Date(),
            endDate: new Date(),

            start_at: null,
            end_at: null,
        }
    }


    componentDidMount = () => {
        // this.load()
        this.products()
        this.partners()
    }

    /* Load */
    load = (page = 1) => {

        const { searchMethod, partnerID } = this.state

        let data = {}
        const { start_at, end_at } = this.state

        if (searchMethod === 'date') {
            data = { page, startDate: start_at, endDate: end_at, userID: partnerID }
        } else {
            data = { page, userID: partnerID }
        }

        this.setState({ page, loading: true })

        act_user(data).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data.data, loading: false })
            } else if (response.status === 403) {
                this.setState({ permission: true })
            }
        })
    }

    partners = (search = null) => {
        list_of_partners({ search }).then(response => {
            if (response.status === 200) {
                this.setState({ partners: response.data.data })
            }
        })
    }

    products = (search = null) => {
        list_of_products({ ...search }).then(response => {
            if (response.status === 200) {
                const array = response.data.data.slice(0, 10)
                this.setState({ products_all: array })
            }
        })
    }

    setProduct = (data) => {
        this.setState({ selected_products: data })
        setTimeout(() => {
            if (data.length > 0) {
                this.load()
            }
        }, 250)
    }

    setSearchMethod = (event) => {
        this.setState({ searchMethod: event.target.value })
    }

    /* Search action */
    search = text => {
        this.setState({ search: text })

        if (text !== "") {
            this.setState({ loading: true })

            if (this._timeout) {
                clearTimeout(this._timeout)
            }

            this._timeout = setTimeout(() => {
                this.load(1, text.trim())
            }, 1000)
        }
    }


    filter = () => {
        const { startDate, endDate } = this.state
        const start_at = moment(startDate).format('YYYY-MM-DD')
        const end_at = moment(endDate).add(1, 'd').format('YYYY-MM-DD')


        this.setState({ start_at, end_at })

        setTimeout(() => {
            this.load()
        })
    }

    clearFilter = () => {
        this.setState({ start_at: null, end_at: null })
        setTimeout(() => {
            this.load()
        }, 250)
    }

    addZero = (number) => {
        if (parseInt(number) < 10) {
            return '0' + number
        }
        return number
    }


    /* Draw Top */
    _top = () => {

        const { count, partner, partners, searchMethod, startDate, endDate, start_at, end_at } = this.state

        return (
            <div className="content-top stat">

                <div className="content-name stat">
                    <img src="/pictures/product.png" alt="Product" />
                    <p>Cтатистика партнера</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

                <div className="content-form-box partner-stat">
                    <SelectItem
                        type={true}
                        list={partners}
                        partner={partner}
                        search={(value) => this.partners(value)}
                        setPartner={(data) => this.setState({ partner: data, partnerID: data.id, selected_products: [] })}
                    />
                </div>

                {
                    partner ?
                        <select onChange={event => this.setSearchMethod(event)} defaultValue={0}>
                            <option value='all_time'>За все время</option>
                            <option value='date'>По дате</option>
                        </select>
                        : null
                }



                {
                    searchMethod === "date" && partner ?
                        <div className="statTime">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => this.setState({ startDate: date })}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd/MM/yyyy"
                                showConfirmButton
                                shouldCloseOnSelect
                            />
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => this.setState({ endDate: date })}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd/MM/yyyy"
                                showConfirmButton
                            />

                            {start_at && end_at && <div className="clear-button stat" onClick={() => this.clearFilter()}>Очистить фильтр</div>}
                        </div>
                        : null
                }

                {
                    partner ?
                        <div className="content-create">
                            <div className="content-create-button blue" onClick={() => this.filter()}>Фильтр</div>
                        </div>
                        : null
                }

            </div>
        )
    }



    /* Draw Content */
    _content = () => {

        const { data, loading, page } = this.state
        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        let totalCashOrder = 0
        let totalPayment = 0
        let totalIncome = 0

        return (
            <div className="content-data-box">
                {data.map((item, index) => {
                    const is_admin_order = item.employee ? 'admin' : ''
                    totalCashOrder += item.cashOrder
                    totalPayment += item.paymentTotal
                    return (
                        <div className={`content-box-body ${is_admin_order}`} key={`${index}`}>
                            <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                            <div className="content-item">{utils.check(item.number)}</div>
                            <div className="content-item">{utils.check(item.comment)}</div>
                            <div className="content-item">{utils.convertor(item.cashOrder)}</div>
                            <div className="content-item">{utils.convertor(item.paymentTotal)}</div>
                            <div className="content-item">{utils.convertor(0)}</div>
                            <div className="content-item">{moment(item.boughtAt).format("DD.MM.YY")}</div>
                        </div>
                    )
                }
                )}
                <div className={`content-box-body yellow`} key={`last`}>
                    <div className="content-item number">Общая сумма</div>
                    <div className="content-item"></div>
                    <div className="content-item"></div>
                    <div className="content-item">{utils.convertor(totalCashOrder)}</div>
                    <div className="content-item">{utils.convertor(totalPayment)}</div>
                    <div className="content-item">{utils.convertor(totalIncome)}</div>
                    <div className="content-item"></div>
                </div>
            </div>
        )
    }


    render = () => {

        const { count, page, search, permission } = this.state

        if (permission) {
            return <Permission />
        }

        return (
            <div className="content">

                {this._top()}
                <div className="content-box">
                    <div className="content-box-head">
                        <div className="content-head number">#</div>
                        <div className="content-head">Номер накладная</div>
                        <div className="content-head">Комментарий</div>
                        <div className="content-head">Кассовый заказ</div>
                        <div className="content-head">Сумма продажи</div>
                        <div className="content-head">Сумма прихода</div>
                        <div className="content-head">Дата</div>
                    </div>

                    {this._content()}

                    <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page, search)} />

                </div>

            </div>
        )
    }

}

export default Act