import axios from "axios"
import { env } from '../constants'


/* Product */
export const list = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}income/list`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Update */
export const update = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}income/edit`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Create */
export const create = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}income/create`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}


/* Remove */
export const remove = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}income/delete`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* View */
export const view = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}income/view`
    const headers = { headers: { Authorization: `Bearer ${token}` }, params: data }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

export const file = () => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}income/export`
    
    const headers = { headers: { Authorization: `Bearer ${token}`, 'Content-Disposition': "attachment;filename=report.xls", 'Content-Type': "application/octet-stream" } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}
