import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { list, remove, file, export_all } from '../../api/Order'
import { changePaymentStatus } from "../../api/Order"

/* SweetAlert */
import Swal from "sweetalert2"

/* Helpers */
import { utils } from '../../helpers'

/* Moment */
import moment from "moment"

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

/* Permission */
import { Permission } from "../Auth"

/* File download */
const FileDownload = require('js-file-download');

let filterTypes = {
    "number": "simple",
    "total": "simple",
    "username": "simple",
    "deliveryStatus": "simple",
    "sale_percent": "simple",
    "paymentStatus": "simple",
    "boughtAt": "simple",
    "employee": "simple",
    "usermaxdebt": "simple"
}

/* Page Products */
class SellsAll extends Component {

    constructor() {
        super()

        this.state = {
            loading: true,
            data: [],
            page: 1,
            search: "",
            count: 0,

            startDate: new Date(),
            endDate: new Date(),

            start_at: null,
            end_at: null,

            permission: false,

            needToPaginate: false,

            filterData: { key: null, type: null }
        }
    }


    componentDidMount = () => {
        this.load()
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const { page, needToPaginate } = this.state
        const target = document.getElementById("bottomId")

        if (this._timeout) {
            clearTimeout(this._timeout)
        }

        this._timeout = setTimeout(() => {
            if (this.isBottom(target) && needToPaginate) {
                this.load(page + 1)
            }
        }, 500)

    }

    /* Load */
    load = (page = 1, text = '') => {

        this.setState({ page })

        const { start_at, end_at, search, data, filterData } = this.state

        const searchText = text.length > 0 ? text : search

        list({ page, search: searchText, start_at, end_at, filterData }).then(response => {
            if (response.status === 200) {
                console.log(response.data.orders)
                let tempOrders = page === 1 ? [] : data
                let needToPaginate = response.data.pagination.itemsPerPage > response.data.orders.length ? false : true
                this.setState({ data: tempOrders.concat(response.data.orders), count: response.data.pagination.totalItems, loading: false, needToPaginate })
            } else if (response.status === 403) {
                this.setState({ permission: true })
            }
        })
    }

    invoice = (id, number) => {
        file({ orderID: id }).then(response => {
            if (response.status === 200) {
                FileDownload(response.data, number + '.pdf');
            }
        })
    }

    removeFromRezerv = (item, index) => {
        const { data } = this.state
        changePaymentStatus({ orderID: item.id, status: "notpaid" }).then(response => {
            if (response.status === 200) {

                Swal.fire({
                    icon: 'success',
                    title: 'Cтатус оплаты успешно изменен',
                    showConfirmButton: false,
                    timer: 1500
                })
                let tempItem = item
                let tempData = data
                tempItem.paymentStatus = 'notpaid'
                tempData[index] = tempItem

                this.setState({ data: tempData })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }

    /* Search action */
    search = text => {
        this.setState({ search: text })

        if (text !== "") {
            this.setState({ loading: true })

            if (this._timeout) {
                clearTimeout(this._timeout)
            }

            this._timeout = setTimeout(() => {
                this.load(1, text.trim())
            }, 1000)
        }
    }

    filterColumn = (type) => {
        let tempFilterTypes = {
            "number": "simple",
            "total": "simple",
            "username": "simple",
            "deliveryStatus": "simple",
            "sale_percent": "simple",
            "paymentStatus": "simple",
            "boughtAt": "simple",
            "employee": "simple",
            "usermaxdebt": "simple"
        }
        if (filterTypes[type] === 'simple') {
            tempFilterTypes[type] = 'asc'
            filterTypes = tempFilterTypes
            this.setState({ filterData: { key: type, type: "asc" } })
        } else if (filterTypes[type] === 'asc') {
            tempFilterTypes[type] = 'desc'
            filterTypes = tempFilterTypes
            this.setState({ filterData: { key: type, type: "desc" } })
        }
        else {
            filterTypes[type] = 'simple'
            this.setState({ filterData: { key: null, type: null } })
        }
        setTimeout(() => {
            this.load(1)
        }, 1000)
    }

    getFilterColor = (type) => {
        let color = ''
        if (filterTypes[type] === 'asc') {
            color = 'green'
        } else if (filterTypes[type] === 'desc') {
            color = 'red'
        }
        return color
    }

    filter = () => {
        const { startDate, endDate } = this.state
        const start_at = moment(startDate).format('YYYY-MM-DD')
        const end_at = moment(endDate).add(1, 'd').format('YYYY-MM-DD')

        this.setState({ start_at, end_at })

        setTimeout(() => {
            this.load()
        })
    }

    clearFilter = () => {
        this.setState({ start_at: null, end_at: null })
        setTimeout(() => {
            this.load()
        }, 250)
    }


    addZero = (number) => {
        if (parseInt(number) < 10) {
            return '0' + number
        }
        return number
    }

    exportAll = () => {
        const { startDate, endDate } = this.state
        const start_at = moment(startDate).format('YYYY-MM-DD')
        const end_at = moment(endDate).add(1, 'd').format('YYYY-MM-DD')

        this.setState({ start_at, end_at })

        export_all({ start_at, end_at }).then(response => {
            if (response.status === 200) {
                FileDownload(response.data, 'orders.pdf');
            }
        })
    }



    /* Draw Top */
    _top = () => {

        const { count, search, startDate, start_at, endDate, end_at } = this.state

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/product.png" alt="Product" />
                    <p>Продавать</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

                <div className="content-search">
                    <img src="/pictures/search.png" alt="Search" />
                    <input value={search} onChange={event => this.search(event.target.value)} placeholder="Поиск" />
                </div>

                <div className="space" />
                <div className="statTime">
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => this.setState({ startDate: date })}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        showConfirmButton
                        shouldCloseOnSelect
                    />
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => this.setState({ endDate: date })}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        showConfirmButton
                    />

                    {start_at && end_at && <div className="clear-button" onClick={() => this.clearFilter()}>Очистить фильтр</div>}

                    <div className="content-create">
                        <div className="content-create-button blue" onClick={() => this.filter()}>Искать</div>
                    </div>
                    <div className="content-create">
                        <div className="content-create-button blue" onClick={() => this.exportAll()}>EXCELL</div>
                    </div>
                </div>

            </div>
        )
    }



    /* Remove */
    remove = (item) => {

        Swal.fire({
            title: 'Вы действительно хотите удалить?',
            text: "Невозможно восстановить после удаления",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f44336',
            confirmButtonText: 'Да, удалить!',
            cancelButtonText: 'Нет'
        }).then((result) => {
            if (result.isConfirmed) {
                remove({ orderID: item.id }).then(response => {
                    if (response.status === 200) {

                        const { data } = this.state

                        const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))

                        if (index > -1) {
                            data.splice(index, 1)
                            this.setState({ data })
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Успешно удалено',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Что-то пошло не так! Попробуйте позднее',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }).catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Что-то пошло не так! Попробуйте позднее',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }
        })
    }

    isBottom = (el) => {
        if (el && el.getBoundingClientRect()) {
            return el.getBoundingClientRect().bottom <= window.innerHeight;
        }
        return 0
    }


    /* Draw Content */
    _content = () => {

        const { data, loading } = this.state

        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        return (
            <div className="content-data-box orders-with-scroll">
                {data.map((item, index) => {
                    const is_admin_order = item.employee ? 'admin' : ''
                    return (
                        <div className={`content-box-body ${is_admin_order}`} key={`${index}`}>
                            <div className="content-item number">{index + 1}</div>
                            <div className="content-item">{utils.check(item.number)}</div>
                            <div className="content-item">{utils.convertor(item.total)}</div>
                            <div className="content-item">{item.user ? item.user.name : ''}</div>
                            <div className="content-item">{item.user && item.user.maxDebt ? item.user.maxDebt : 'Нет данных'}</div>
                            <div className="content-item">{item.deliveryStatus}</div>
                            <div className="content-item">{item.sale_percent}</div>
                            <div className="content-item">{item.paymentStatus === 'paid' ? 'Оплачен' : item.paymentStatus === 'notpaid' ? 'Не оплачен' : <strong className="veryStrong">Резерв</strong>}</div>
                            <div className="content-item">{moment(item.boughtAt).format("DD.MM.YY")}</div>
                            <div className="content-item">{item.employee ? item.employee.name : ''}</div>
                            <div className="content-item-button blue" onClick={() => this.invoice(item.id, item.number)}>Скачать</div>
                            <div className="content-item content-buttons name">
                                {/* <Link className="content-item-button" to={`/sells/edit/${item.id}`}>Edit</Link> */}
                                {item.paymentStatus === 'reserve' ? <div className="content-item-button remove" onClick={() => this.removeFromRezerv(item, index)}>Вытащить с резерва</div> : null}

                            </div>
                        </div>
                    )
                }
                )}
                <div id="bottomId" />
            </div>
        )
    }


    render = () => {

        const { permission } = this.state

        if (permission) {
            return <Permission />
        }

        return (
            <div className="content">

                {this._top()}

                <div className="content-box">

                    <div className="content-box-head">
                        <div className="content-head number">#</div>
                        <div className={`content-head clickable ${this.getFilterColor("number")}`} onClick={() => this.filterColumn('number')}>Номер заказа</div>
                        <div className={`content-head clickable ${this.getFilterColor("total")}`} onClick={() => this.filterColumn('total')}>Цена</div>
                        <div className={`content-head clickable ${this.getFilterColor("username")}`} onClick={() => this.filterColumn('username')}>Партнер</div>
                        <div className={`content-head clickable ${this.getFilterColor("usermaxdebt")}`} onClick={() => this.filterColumn('usermaxdebt')}>Лимит партнера</div>
                        <div className={`content-head clickable ${this.getFilterColor("deliveryStatus")}`} onClick={() => this.filterColumn('deliveryStatus')}>Статус</div>
                        <div className={`content-head clickable ${this.getFilterColor("sale_percent")}`} onClick={() => this.filterColumn('sale_percent')}>Скидка</div>
                        <div className={`content-head clickable ${this.getFilterColor("paymentStatus")}`} onClick={() => this.filterColumn('paymentStatus')}>Статус оплаты</div>
                        <div className={`content-head clickable ${this.getFilterColor("boughtAt")}`} onClick={() => this.filterColumn('boughtAt')}>Дата</div>
                        <div className={`content-head clickable ${this.getFilterColor("employee")}`} onClick={() => this.filterColumn('employee')}>Сотрудник</div>
                        <div className="content-head">Накладная</div>
                        <div className="content-head name order"></div>
                    </div>

                    {this._content()}

                    {/* <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page, search)} /> */}

                </div>

            </div>
        )
    }

}

export default SellsAll