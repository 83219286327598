import React, { Component } from 'react'
import Modal from 'react-modal';

const customStyles = {
    content: {
        transform: 'translate(calc((100vw - 1220px) / 2), 10%)',
        inset: 'auto',
        padding: '10px'
    },
};

class SelectMenuRemainder extends Component {
    constructor() {
        super()
        this.state = {
            show: false,
            searchName: '',
            searchArticle: '',
            searchCode: '',

            activeIndex: -1
        }
        this.divRef = React.createRef();
        this.divRefClick = React.createRef();
        this.divRefInnerClick = React.createRef();

        this._timeout = 0
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener("keydown", this.callback)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    callback = (event) => {
        const { list } = this.props
        const { activeIndex, show } = this.state

        if (list.length > 0) {
            if (event.code === 'Enter') {
                if (show) {
                    if (activeIndex !== -1) {
                        this.setToList(list[activeIndex])
                    } else {
                        this.setState({ activeIndex: 0 })
                    }
                } else {
                    this.setState({ show: true })
                }

            }
            if (event.code === 'ArrowUp' && show) {
                if (activeIndex - 1 >= 0) {
                    this.setState({ activeIndex: activeIndex - 1 })
                }
            } else if (event.code === 'ArrowDown' && show) {
                if (list[activeIndex + 1]) {
                    this.setState({ activeIndex: activeIndex + 1 })
                }
            }
        }

    }


    handleClickOutside = (event) => {
        if (this.divRef.current && !this.divRef.current.contains(event.target)) {
            this.setState({
                show: false,
            });
        } else {
            if (this.divRefClick.current.contains(event.target)) {
                this.setState({
                    show: true,
                });
            }
        }
    };

    checkList = (id) => {
        const { items } = this.props
        if (items.filter((product) => product.productID === id).length > 0) {
            return true
        } else {
            return false
        }
    }

    setToList = (item) => {
        const { items, update } = this.props

        let newData = items

        if (item.productID) {
            newData = items.filter((product) => product.productID !== item.productID)
        } else {
            if (items.filter((product) => product.productID === item.id).length > 0) {
                newData = items.filter((product) => product.productID !== item.id)
            } else {
                const product = {
                    "productID": item.id,
                    "name": item.name,
                    "article": item.article,
                    "code": item.code,
                    "stocks": item.stocks
                }
                newData = [product]

                this.setState({ show: false })
            }
        }

        update(newData)
    }

    search = (text, key) => {
        let data = {}
        if (key === 'name') {
            data = {
                searchName: text
            }
            this.setState({ searchName: text })
        } else if (key === 'article') {
            data = {
                searchArticle: text
            }
            this.setState({ searchArticle: text })
        } else {
            data = {
                searchCode: text
            }
            this.setState({ searchCode: text })
        }

        const { search } = this.props
        if (this._timeout) {
            clearTimeout(this._timeout)
        }

        this._timeout = setTimeout(() => {
            this.setState({activeIndex: -1})
            search(data)
        })
    }

    render = () => {

        const { show, searchName, searchArticle, searchCode, activeIndex } = this.state
        const { list, items } = this.props

        return (
            <div className='select-menu'>
                <div className='items-list' ref={this.divRefClick}>
                    <div className='items-list-inner' onClick={() => this.setState({ show: true })}>
                        {
                            items.length > 0 ?
                                <div className='product'>
                                    <div>#</div>
                                    <div>Название</div>

                                    <div className='input-values header'>
                                        <p>Код</p>
                                        <p>Артикул</p>
                                        {/* <div /> */}
                                    </div>
                                </div>
                                : null
                        }

                        {
                            items.map((item, index) => {
                                return (
                                    <div key={`sp_${index}`} className='product'>
                                        <div>{index + 1}</div>
                                        {item.name}
                                        <div className='input-values'>

                                            <input type='text' disabled value={item.code} className='quantity' />
                                            <input type='text' disabled value={item.article} className='price' />
                                            <img src='/pictures/close.png' className='close' alt='a' onClick={() => this.setToList(item)} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='img-block'>
                        <img src='/pictures/menu/arrow.png' alt='a' />
                    </div>

                </div>
                <Modal
                    isOpen={show}
                    onRequestClose={() => this.setState({ show: false })}
                    style={customStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                >
                    <div className='select-dropdown multi' ref={this.divRef}>
                        <div className='inputs'>
                            <input
                                className='search-input'
                                type='text'
                                value={searchName} onChange={event => this.search(event.target.value, 'name')} placeholder="Название"
                            />
                            <input
                                className='search-input'
                                type='text'
                                value={searchArticle} onChange={event => this.search(event.target.value, 'article')} placeholder="Артикул"
                            />
                            <input
                                className='search-input'
                                type='text'
                                value={searchCode} onChange={event => this.search(event.target.value, 'code')} placeholder="Код"
                            />
                        </div>
                        <div className='select-item head'>
                            <p>Название</p>
                            <p>Артикул</p>
                            <p>Код</p>
                            <p>Остаток</p>
                            <p>Место</p>
                            
                            <p>Роз.цена</p>
                            <p>Опт.цена</p>
                            <p>Закуп.цена</p>
                            <p>В списке</p>
                        </div>
                        {
                            list.map((item, index) => {
                                const style = item.stocks[0] && item.stocks[0].count ? '' : "disabled"
                                const active = activeIndex === index ? "active" : ''
                                return (
                                    <div key={`sp_${index}`} className={`select-item ${style} ${active}`} onClick={() => this.setToList(item)}>
                                        <p>{item.name}</p>
                                        <p>{item.article}</p>
                                        <p>{item.code}</p>
                                        <p>{(item.stocks && item.stocks[0]) ? item.stocks[0].count : ''}</p>
                                        <p>{item.place ? item.place : 'fdfd'}</p>
                                       
                                        <p>{item.price}</p>
                                        <p>{item.salePrice}</p>
                                        <p>{item.mainPrice}</p>
                                        <p>{this.checkList(item.id) ? <img src='/pictures/check.png' alt='a' /> : <img className='hidden' src='/pictures/check.png' alt='a' />}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal>
            </div>
        )
    }

}

export default SelectMenuRemainder