import React, { Component } from "react";
import MenuItems from './MenuItems';
import { menu } from "../../constants/menu";

class Navbar extends Component {
    constructor() {
        super()
        this.state = {
        }
    }

    render = () => {
        return (
            <nav>
                <ul className="menus">
                    {menu.map((item, index) => {
                        const depthLevel = item.level;
                        return <MenuItems items={item} last={true} key={index} depthLevel={depthLevel} />;
                    })}
                </ul>
            </nav>
        )
    }
}

export default Navbar