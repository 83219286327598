import React, { Component } from 'react'

import Modal from 'react-modal';

const customStyles = {
    content: {
        transform: 'translate(calc((100vh - 650px)), 5%)',
        inset: 'auto',
        padding: '10px'
    },
};

class SelectRoleMenu extends Component {
    constructor() {
        super()
        this.state = {
            show: false,
        }
        this.divRef = React.createRef();
        this.divRefClick = React.createRef();
        this.divRefInnerClick = React.createRef();

        this._timeout = 0
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.divRef.current && !this.divRef.current.contains(event.target)) {
            this.setState({
                show: false,
            });
        } else {
            if (this.divRefClick.current.contains(event.target)) {
                this.setState({
                    show: true,
                });
            }
        }
    };

    checkList = (id) => {
        const { items } = this.props
        if (items.filter((item) => item.id === id).length > 0) {
            return true
        } else {
            return false
        }
    }

    setToList = (data) => {
        const { items, update } = this.props

        let newData = items

        if (items.filter((product) => product.id === data.id).length > 0) {
            newData = items.filter((product) => product.id !== data.id)
        } else {
            const obj = {
                "id": data.id,
                "name": data.name,
            }
            newData.push(obj)
        }

        update(newData)
    }

    render = () => {

        const { show } = this.state
        const { list, items } = this.props

        return (
            <div className='select-menu'>
                <div className='items-list' ref={this.divRefClick}>
                    <div className='items-list-inner multiple' onClick={() => this.setState({ show: true })}>
                        {
                            items.map((item, index) => {
                                return (
                                    <div key={`sp_${index}`} className='item multiple'>
                                        {item.name}
                                        <div className='input-values'>
                                            <img src='/pictures/close.png' className='close' alt='a' onClick={() => this.setToList(item)} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='img-block'>
                        <img src='/pictures/menu/arrow.png' alt='a'/>
                    </div>

                </div>

                <Modal
                    isOpen={show}
                    onRequestClose={() => this.setState({ show: false })}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className='select-dropdown roles' ref={this.divRef}>
                        {
                            list && list.length > 0 && list.map((item, index) => {
                                const added = this.checkList(item.id) ? 'added' : ''
                                return (
                                    <div key={`sp_${index}`} className={`select-item ${added}`} onClick={() => this.setToList(item)}>
                                        <p>{item.name}</p>
                                        {this.checkList(item.id) && <img src='/pictures/check.png' alt='a' />}
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal>
            </div>
        )
    }

}

export default SelectRoleMenu