import axios from "axios"
import { env } from '../constants'


/* User */
export const list = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}managment/list`
    const headers = { headers: { Authorization: `Bearer ${token}`, params: data } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* User */
export const roles = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}managment/roles`
    const headers = { headers: { Authorization: `Bearer ${token}`, params: data } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* Permissions */
export const permissions = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}managment/permissions`
    const headers = { headers: { Authorization: `Bearer ${token}`, params: data } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* Remove */
export const set_role = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}managment/create`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Remove */
export const create_role = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}managment/role/create`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}


/* Remove */
export const remove = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}managment/delete`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Remove */
export const remove_role = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}managment/role/delete`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}