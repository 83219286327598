import React, { Component } from 'react'

class SelectItem extends Component {
    constructor() {
        super()
        this.state = {
            show: false,
            searchText: '',
        }

        this.partnerRef = React.createRef();
        this.partnerRefClick = React.createRef();
        this.partnerRefInnerClick = React.createRef();

        this._timeout = 0
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.partnerRef.current && !this.partnerRef.current.contains(event.target)) {
            this.setState({
                show: false,
            });
        } else {
            if (this.partnerRefClick.current.contains(event.target)) {
                this.setState({
                    show: true,
                });
            }
        }
    };

    search = (text) => {
        const { search } = this.props
        this.setState({ searchText: text })
        if (this._timeout) {
            clearTimeout(this._timeout)
        }

        this._timeout = setTimeout(() => {
            search(text)
        })
    }

    setPartner = (data) => {
        const { setPartner } = this.props
        setPartner(data)
        this.setState({ show: false })
    }

    render = () => {

        const { show, searchText } = this.state
        const { list, partner, type } = this.props

        return (
            <div className='select-menu'>
                <div className='items-list' ref={this.partnerRefClick}>
                    <div className='partner'>{partner ? partner.name : "Не выбран"}</div>
                    <div className='img-block'>
                        <img src='/pictures/menu/arrow.png' />
                    </div>
                </div>
                {
                    show ?
                        <div className='select-dropdown mono' ref={this.partnerRef}>
                            <input
                                className='search-input'
                                type='text'
                                value={searchText} onChange={event => this.search(event.target.value)} placeholder="Поиск партнера"
                            />
                            {
                                list.map((item, index) => {
                                    return (
                                        <div key={`sp_${index}`} className='select-item row' onClick={() => this.setPartner(item)}>
                                            <p>{item.name}</p>
                                            {type ? <p>{item.type ? "Оптовик" : 'Роз.клиент'}</p> : null}
                                            
                                            {partner && partner.id === item.id && <img src='/pictures/check.png' />}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        : null
                }
            </div>
        )
    }

}

export default SelectItem