import React, { Component } from "react"

/* JWT */
import { decodeToken } from "react-jwt"



/*
    Page Dashboard
*/
class Dashboard extends Component {

    logout= () => {
        localStorage.removeItem('token')
        setTimeout(() => {
            window.location.href = '/'
        }, 250)
    }


    /* Draw Top */
    _top = () => {

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/dashboard.png" alt="Dashboard" />
                    <p>Dashboard</p>
                </div>

            </div>
        )
    }


    render = () => {

        const token = localStorage.getItem("token")
        const data = decodeToken(token)

        return (
            <div className="content">
                {this._top()}
                <div className="dashboard-welcome">
                    <div className="dashboard-user">
                        <h2>Здравствуйте, <b>{data.name}</b></h2>
                        <button onClick={() => this.logout()}>Logout</button>
                    </div>
                    <p>Наша миссия – обеспечить вас надежными компонентами, которые подарят вашим грузовикам новую жизнь на дороге.</p>
                </div>
            </div>
        )
    }

}

export default Dashboard