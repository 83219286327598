import React, { Component } from "react"

/* Router */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

/* Pages */
import { Incomings, CreateIncoming, EditIncoming } from "./Incoming"

import { Warehouses } from "./Main/Warehouse"
import { City } from "./Main/City"
import { Categories, CreateCategory, EditCategory } from "./Main/Category"
import { Products, CreateProduct, EditProduct } from "./Main/Product"
import { Partners, CreatePartner } from "./Main/Partner"
import { Remainder, CreateRemainder, EditRemainder } from "./WarehousePart"

import { Accounting, CreateAccounting, EditAccounting } from "./Accounting"
import { Report, CreateReport, EditReport } from "./Report"

import { Sells, Rezerv, Refund, SellsAll, CreateSell, EditSells } from "./Orders"

import { Users, Password } from "./SystemManager"

import { Dashboard } from '../pages/Dashboard'
import { Managers, Roles, SetRole, CreateRole } from '../pages/Role'
import { NotFound } from '../pages/Auth'

import { IncomeStatistics, OrderStatistics, OrderUserStatistics, Act, OrderProducts } from "./Statistics"


/* Widgets */
import Menu from './Menu'
import { Navbar } from "../widgets"

/* JWT */
import { decodeToken } from "react-jwt"



/*
    Layout Dashboard
*/
class Layout extends Component {

    render = () => {
        const token = localStorage.getItem("token")
        const data = decodeToken(token)

        return (
            <Router>
                <div className="layout">
                    <Navbar />
                    <div className="layout-inner">
                        <Menu />

                        <div className="container">

                            <div className="header">
                                <div className="header-user">

                                    <img src="/pictures/profile.png" alt="Profile" />

                                    <div className="header-name">
                                        <p>{data.name}</p>
                                        <span>{data.email}</span>
                                    </div>

                                </div>

                            </div>

                            <Routes>
                                <Route path="*" element={<NotFound />} />
                                <Route path="/" element={<Dashboard />} />

                                <Route path="/managers" element={<Managers />} />
                                <Route path="/roles" element={<Roles />} />
                                <Route path="/roles/set" element={<SetRole />} />
                                <Route path="/roles/create" element={<CreateRole />} />

                                <Route path="/incomings" element={<Incomings />} />
                                <Route path="/incomings/create" element={<CreateIncoming />} />
                                <Route path="/incomings/edit/:id" element={<EditIncoming />} />

                                <Route path="/accounting" element={<Accounting />} />
                                <Route path="/accounting/create" element={<CreateAccounting />} />
                                <Route path="/accounting/edit/:id" element={<EditAccounting />} />

                                <Route path="/report" element={<Report />} />
                                <Route path="/report/create" element={<CreateReport />} />
                                <Route path="/report/edit/:id" element={<EditReport />} />

                                <Route path="/city" element={<City />} />
                                <Route path="/warehouse" element={<Warehouses />} />

                                <Route path="/category" element={<Categories />} />
                                <Route path="/category/create" element={<CreateCategory />} />
                                <Route path="/category/edit/:id" element={<EditCategory />} />

                                <Route path="/product" element={<Products />} />
                                <Route path="/product/create" element={<CreateProduct />} />
                                <Route path="/product/edit/:id" element={<EditProduct />} />

                                <Route path="/sells" element={<Sells />} />
                                <Route path="/rezerv" element={<Rezerv />} />
                                <Route path="/refund" element={<Refund />} />
                                <Route path="/sells-all" element={<SellsAll />} />
                                <Route path="/sells/create/:rezerv" element={<CreateSell />} />
                                <Route path="/sells/edit/:id" element={<EditSells />} />
                                {/* <Route path="/product-all/create" element={<CreateProductAll />} />
                                <Route path="/product-all/edit/:id" element={<EditProductAll />} /> */}

                                <Route path="/remainder" element={<Remainder />} />
                                <Route path="/remainder/create" element={<CreateRemainder />} />
                                <Route path="/remainder/edit/:id" element={<EditRemainder />} />

                                <Route path="/partners" element={<Partners />} />
                                <Route path="/partner-create" element={<CreatePartner />} />

                                <Route path="users" element={<Users />} />
                                <Route path="password" element={<Password />} />

                                <Route path="statistics/income" element={<IncomeStatistics />} />
                                <Route path="statistics/order" element={<OrderStatistics />} />
                                <Route path="statistics/order-user" element={<OrderUserStatistics />} />
                                <Route path="statistics/act" element={<Act />} />
                                <Route path="order/products/:id" element={<OrderProducts />} />

                            </Routes>

                        </div>
                    </div>
                </div>


            </Router>
        )
    }
}

export default Layout