import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { income_list } from '../../api/Statistics'

import { list as list_of_products } from "../../api/Product"

/* Pagination */
import { Pagination } from '../../components'

// /* SweetAlert */
// import Swal from "sweetalert2"

/* React Router */
import { Link } from "react-router-dom"

/* Moment */
import moment from "moment"

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

/* Helpers */
import { utils } from '../../helpers'

import { SelectMenuProduct } from "./widgets"

/* Permission */
import { Permission } from "../Auth"




/* Page Income Statistics */
class IncomeStatistics extends Component {

    constructor() {
        super()

        this.state = {
            loading: false,
            data: [],
            page: 1,
            count: 0,

            search: "",
            productID: null,
            products_all: [],
            selected_products: [],

            product: 0,

            searchMethod: null,

            permission: false,

            startDate: new Date(),
            endDate: new Date(),

            start_at: null,
            end_at: null,
        }
    }


    componentDidMount = () => {
        // this.load()
        this.products()
    }

    /* Load */
    load = (page = 1) => {

        const { selected_products, searchMethod } = this.state

        let data = {}

        if (selected_products[0] && searchMethod === 'product') {
            const productID = selected_products[0].productID
            data = { page, productID }
        } else {
            const { start_at, end_at } = this.state
            data = { page, startDate:start_at, endDate: end_at }
        }

        this.setState({ page, loading: true })

        income_list(data).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data.data, loading: false })
            } else if (response.status === 403) {
                this.setState({ permission: true })
            }
        })
    }

    products = (search = null) => {
        list_of_products({ ...search }).then(response => {
            if (response.status === 200) {
                const array = response.data.data.slice(0, 10)
                this.setState({ products_all: array })
            }
        })
    }

    setProduct = (data) => {
        this.setState({ selected_products: data })
        setTimeout(() => {
            if (data.length > 0) {
                this.load()
            }
        }, 250)
    }

    setSearchMethod = (event) => {
        this.setState({ searchMethod: event.target.value })
    }

    /* Search action */
    search = text => {
        this.setState({ search: text })

        if (text !== "") {
            this.setState({ loading: true })

            if (this._timeout) {
                clearTimeout(this._timeout)
            }

            this._timeout = setTimeout(() => {
                this.load(1, text.trim())
            }, 1000)
        }
    }
    

    filter = () => {
        const { startDate, endDate } = this.state

        // const s_year = startDate.getFullYear()
        // const s_month = this.addZero(startDate.getMonth() + 1)
        // const s_day = this.addZero(startDate.getDay() + 1)

        // const e_year = endDate.getFullYear()
        // const e_month = this.addZero(endDate.getMonth() + 1)
        // const e_day = this.addZero(endDate.getDay() + 2)

        // const start_at = s_year + '-' + s_month + '-' + s_day
        // const end_at = e_year + '-' + e_month + '-' + e_day

        const start_at = moment(startDate).format('YYYY-MM-DD')
        const end_at = moment(endDate).add(1, 'd').format('YYYY-MM-DD')

        this.setState({start_at, end_at})

        setTimeout(() => {
            this.load()
        })
    }

    clearFilter = () => {
        this.setState({ start_at: null, end_at: null })
        setTimeout(() => {
            this.load()
        }, 250)
    }

    addZero = (number) => {
        if (parseInt(number) < 10) {
            return '0' + number
        }
        return number
    }


    /* Draw Top */
    _top = () => {

        const { count, products_all, selected_products, searchMethod, startDate, endDate, start_at, end_at } = this.state

        return (
            <div className="content-top stat">

                <div className="content-name stat">
                    <img src="/pictures/product.png" alt="Product" />
                    <p>Cтатистика оприходование</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

                <p>Поиск</p>
                <select onChange={event => this.setSearchMethod(event)} defaultValue={0}>
                    <option value='date'>По дате</option>
                    <option value='product'>По товару</option>
                </select>

                {
                    searchMethod === "product" ?
                        <div className="content-form-box">
                            <SelectMenuProduct
                                list={products_all}
                                items={selected_products}
                                search={(data) => this.products(data)}
                                update={(data) => this.setProduct(data)}
                            />
                        </div>
                        :
                        <div className="statTime">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => this.setState({ startDate: date })}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd/MM/yyyy"
                                showConfirmButton
                                shouldCloseOnSelect
                            />
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => this.setState({ endDate: date })}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd/MM/yyyy"
                                showConfirmButton
                            />

                            {start_at && end_at && <div className="clear-button stat" onClick={() => this.clearFilter()}>Очистить фильтр</div>}

                            <div className="content-create">
                                <div className="content-create-button blue" onClick={() => this.filter()}>Фильтр</div>
                            </div>
                        </div>
                }
            </div>
        )
    }



    /* Draw Content */
    _content = () => {

        const { data, loading, page } = this.state
        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        return (
            <div className="content-data-box">
                {data.map((item, index) => {
                    let total = 0
                    item.items.map(product => {
                        total += product.price * product.quantity
                    })
                    return (
                        <div className="content-box-body" key={`${index}`}>
                            <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                            <div className="content-item">{utils.check(item.documentNumber)}</div>
                            <div className="content-item">{utils.check(item.partner.name)}</div>
                            <div className="content-item">{item.paymentMethod}</div>
                            <div className="content-item">{utils.convertor(total)}</div>
                            <div className="content-item">{moment(item.createdAt).format("DD.MM.YYYY")}</div>
                        </div>
                    )
                }
                )}
            </div>
        )
    }


    render = () => {

        const { count, page, search, permission } = this.state

        if (permission) {
            return <Permission />
        }

        return (
            <div className="content">

                {this._top()}
                <div className="content-box">
                    <div className="content-box-head">
                        <div className="content-head number">#</div>
                        <div className="content-head">Номер</div>
                        <div className="content-head">Партнер</div>
                        <div className="content-head">Метод оплаты</div>
                        <div className="content-head">Общая сумма</div>
                        <div className="content-head">Дата</div>
                    </div>

                    {this._content()}

                    <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page, search)} />

                </div>

            </div>
        )
    }

}

export default IncomeStatistics