import React, { Component } from "react";
import Dropdown from "./Dropdown";

class MenuItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdown: false,
        };

        this.ref = React.createRef();
    }

    componentWillMount() {
        document.addEventListener("mousedown", this.handleOutsideClick);
        document.addEventListener("touchstart", this.handleOutsideClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleOutsideClick);
        document.removeEventListener("touchstart", this.handleOutsideClick);
    }

    handleOutsideClick = (event) => {
        const { dropdown } = this.state;
        if (dropdown && this.ref.current && !this.ref.current.contains(event.target)) {
            this.setState({ dropdown: false });
        }
    };

    onMouseEnter = () => {
        if (window.innerWidth > 960) {
            this.setState({ dropdown: true });
        }
    };

    onMouseLeave = () => {
        if (window.innerWidth > 960) {
            this.setState({ dropdown: false });
        }
    };

    render() {
        const { items, last, depthLevel } = this.props;
        const { dropdown } = this.state;

        return (
            <li
                className="menu-items"
                ref={this.ref}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                {items.link && items.submenu ? (
                    <></>
                ) : !items.link && items.submenu ? (
                    <>
                        <button
                            type="button"
                            aria-haspopup="menu"
                            aria-expanded={dropdown ? "true" : "false"}
                            onClick={() => this.setState({ dropdown: !dropdown })}
                            className={`${!last ? 'border-b1' : '' }`}
                        >
                            {
                                items.image ?
                                    <img className="nav_image" src={items.image} />
                                    : null
                            }
                            {items.name}{" "}
                            {items.level > 0 ? <span className="arrow" /> : <span className="arrow-right" />}
                        </button>
                        <Dropdown depthLevel={depthLevel} submenus={items.submenu} dropdown={dropdown} />
                    </>
                ) : (
                    <a href={items.link} className={`${!last ? 'border-b1' : '' }`}>
                        {
                            items.image ?
                                <img className="nav_image" src={items.image} />
                                : null
                        }
                        {items.name}{" "}
                        <div/>
                    </a>

                )}
            </li>
        );
    }
}

export default MenuItems;
