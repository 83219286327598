import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { list, file, remove, status, top, import_file } from '../../../api/Product'

/* Pagination */
import { Pagination } from '../../../components'

/* SweetAlert */
import Swal from "sweetalert2"

/* React Router */
import { Link } from "react-router-dom"

/* Helpers */
import { utils } from '../../../helpers'

/* Switch */
import Switch from "react-switch"

/* File download */
import FileDownload from "js-file-download"

/* Permission */
import { Permission } from "../../Auth"

/* Page Products */
class Products extends Component {

    constructor() {
        super()

        this.state = {
            loading: true,
            data: [],
            page: 1,
            search: "",
            count: 0,

            permission: false
        }
    }


    componentDidMount = () => {
        this.load()
    }

    /* Load */
    load = (page = 1, text = '') => {

        this.setState({ page, loading: true })

        list({ page, search: text }).then(response => {
            if (response.status === 200) {
                this.setState({ data: response.data.data, count: response.data.pagination.totalItems, loading: false })
            } else if (response.status === 403) {
                this.setState({ permission: true })
            }
        })

    }

    export = () => {
        file().then(response => {
            if (response.status === 200) {
                FileDownload(response.data, 'report.xlsx');
            }
        })
    }

    import = async () => {
        const { value: file } = await Swal.fire({
            title: 'Добавить',
            input: 'file',
            showCancelButton: true,
            inputAttributes: {
                'accept': '.xls, .xlsx',
                'aria-label': 'Upload your Excel file'
            },
            confirmButtonColor: 'blue',
            confirmButtonText: 'Добавить',
            cancelButtonColor: 'red',
            cancelButtonText: 'Назад'
        })
        if (file) {
            let data = new FormData()
            data.append('file', file)
            import_file(data).then(response => {
                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Успешно добавлен',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Что-то пошло не так! Попробуйте позднее',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        }
    }

    /* Search action */
    search = text => {
        this.setState({ search: text })

        if (text !== "") {
            this.setState({ loading: true })

            if (this._timeout) {
                clearTimeout(this._timeout)
            }

            this._timeout = setTimeout(() => {
                this.load(1, text.trim())
            }, 1000)
        }
    }


    /* Draw Top */
    _top = () => {

        const { count, search } = this.state

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/product.png" alt="Product" />
                    <p>Продукты</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

                <div className="content-create">
                    <Link className="content-create-button" to="/product/create">Добавить</Link>
                </div>

                <div className="content-search">
                    <img src="/pictures/search.png" alt="Search" />
                    <input value={search} onChange={event => this.search(event.target.value)} placeholder="Поиск" />
                </div>

                <div className="content-create">
                    <div className="content-create-button blue" onClick={() => this.export()}>EXPORT</div>
                </div>

                <div className="content-create">
                    <div className="content-create-button blue" onClick={() => this.import()}>IMPORT</div>
                </div>

            </div>
        )
    }



    /* Remove */
    remove = (item) => {

        Swal.fire({
            title: 'Вы действительно хотите удалить?',
            text: "Невозможно восстановить после удаления",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f44336',
            confirmButtonText: 'Да, удалить!',
            cancelButtonText: 'Нет'
        }).then((result) => {
            if (result.isConfirmed) {
                remove({ productID: item.id }).then(response => {
                    if (response.status === 200) {

                        const { data } = this.state

                        const index = data.findIndex(e => parseInt(e.id) === parseInt(item.id))

                        if (index > -1) {
                            data.splice(index, 1)
                            this.setState({ data })
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Успешно удалено',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Что-то пошло не так! Попробуйте позднее',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }).catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Что-то пошло не так! Попробуйте позднее',
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
            }
        })
    }



    /* Change visible */
    change = (id, value) => {

        const { data } = this.state

        status({ productID: id, visible: value ? 1 : 0 }).then(response => {
            if (response.status === 200) {

                const index = data.findIndex(e => parseInt(e.id) === parseInt(id))

                if (index > -1) {
                    data[index].visible = value ? 1 : 0
                    this.setState({ data })
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Статус успешно изменен',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }

    /* Change visible */
    changeTop = (id, value) => {

        const { data } = this.state

        top({ productID: id, isTop: value ? 1 : 0 }).then(response => {
            if (response.status === 200) {

                const index = data.findIndex(e => parseInt(e.id) === parseInt(id))

                if (index > -1) {
                    data[index].isTop = value ? 1 : 0
                    this.setState({ data })
                }

                Swal.fire({
                    icon: 'success',
                    title: 'Статус успешно изменен',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }



    /* Draw Content */
    _content = () => {

        const { data, loading, page } = this.state

        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        return (
            <div className="content-data-box">
                {data.map((item, index) => {
                    return (
                        <div className="content-box-body products" key={`${index}`}>
                            <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                            <div className="content-item">{utils.check(item.name)}</div>
                            <div className="content-item">{utils.check(item.slug)}</div>
                            <div className="content-item">{utils.check(item.article)}</div>
                            <div className="content-item">{utils.check(item.code)}</div>
                            <div className="content-item">{(item.stocks && item.stocks[0]) ? item.stocks[0].count : ''}</div>
                            <div className="content-item">{utils.convertor(item.mainPrice)}</div>
                            <div className="content-item">{utils.convertor(item.price)}</div>
                            <div className="content-item">{utils.convertor(item.salePrice)}</div>
                            <div className="content-item">{utils.check(item.pricePercent)}</div>
                            <div className="content-item">{utils.check(item.salePricePercent)}</div>
                            <div className="content-item">{utils.check(item.unit)}</div>
                            <div className="content-item">{utils.check(item.place)}</div>
                            <div className="content-item center">
                                <Switch
                                    onChange={value => this.changeTop(item.id, value)}
                                    checked={parseInt(item.isTop) === 1}
                                    onColor="#e47979"
                                    onHandleColor="#f44336"
                                    offHandleColor="#fff"
                                    offColor="#d7d7d7"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    height={20}
                                    width={40}
                                />
                            </div>
                            <div className="content-item center">
                                <Switch
                                    onChange={value => this.change(item.id, value)}
                                    checked={parseInt(item.visible) === 1}
                                    onColor="#b1e17a"
                                    onHandleColor="#8bc34a"
                                    offHandleColor="#fff"
                                    offColor="#d7d7d7"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    height={20}
                                    width={40}
                                />
                            </div>
                            <div className="content-item content-buttons name">



                                <Link className="content-item-button" to={`/product/edit/${item.id}`}>Edit</Link>
                                <div className="content-item-button delete" onClick={() => this.remove(item)}>Delete</div>
                            </div>
                        </div>
                    )
                }
                )}
            </div>
        )
    }


    render = () => {

        const { count, page, search, permission } = this.state

        if (permission) {
            return <Permission />
        }

        return (
            <div className="content">

                {this._top()}

                <div className="content-box products">

                    <div className="content-box-head products">
                        <div className="content-head number">#</div>
                        <div className="content-head filter-button">Название</div>
                        <div className="content-head">Key</div>
                        <div className="content-head">Артикул</div>
                        <div className="content-head">Код</div>
                        <div className="content-head">Остаток</div>
                        <div className="content-head">Цена</div>
                        <div className="content-head">Роз.цена</div>
                        <div className="content-head">Опт.цена</div>
                        <div className="content-head">Коэф.роз</div>
                        <div className="content-head">Коэф.опт</div>
                        <div className="content-head">Юнит</div>
                        <div className="content-head">Место</div>
                        <div className="content-head">Top</div>
                        <div className="content-head">Активный</div>
                        <div className="content-head name products"></div>
                    </div>

                    {this._content()}

                    <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page, search)} />

                </div>

            </div>
        )
    }

}

export default Products