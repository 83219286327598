import React, { Component } from 'react'

import Modal from 'react-modal';

const customStyles = {
    content: {
        transform: 'translate(calc((100vw - 1220px) / 2), 10%)',
        inset: 'auto',
        padding: '10px'
    },
};

class SelectMenuAccounting extends Component {
    constructor() {
        super()
        this.state = {
            show: false,
            search: '',
            searchName: '',
            searchArticle: '',
            searchCode: '',
            activeIndex: -1
        }
        this.divRef = React.createRef();
        this.divRefClick = React.createRef();
        this.divRefInnerClick = React.createRef();

        this._timeout = 0
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener("keydown", this.callback)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    callback = (event) => {
        const { list } = this.props
        const { activeIndex, show } = this.state

        if (list.length > 0) {
            if (event.code === 'Enter') {
                if (show) {
                    if (activeIndex !== -1) {
                        this.setToList(list[activeIndex])
                    } else {
                        this.setState({ activeIndex: 0 })
                    }
                } else {
                    this.setState({ show: true })
                }

            }
            if (event.code === 'ArrowUp' && show) {
                if (activeIndex - 1 >= 0) {
                    this.setState({ activeIndex: activeIndex - 1 })
                }
            } else if (event.code === 'ArrowDown' && show) {
                if (list[activeIndex + 1]) {
                    this.setState({ activeIndex: activeIndex + 1 })
                }
            }
        }

    }

    handleClickOutside = (event) => {
        if (this.divRef.current && !this.divRef.current.contains(event.target)) {
            this.setState({
                show: false,
            });
        } else {
            if (this.divRefClick.current.contains(event.target)) {
                this.setState({
                    show: true,
                });
            }
        }
    };

    checkList = (id) => {
        const { items } = this.props
        if (items.filter((product) => product.productID === id).length > 0) {
            return true
        } else {
            return false
        }
    }

    setToList = (item) => {
        const { items, update } = this.props

        if ((item && item.stocks && item.stocks[0] && item.stocks[0].count) || item.productID) {
            let newData = items

            if (item.productID) {
                newData = items.filter((product) => product.productID !== item.productID)
            } else {
                if (items.filter((product) => product.productID === item.id).length > 0) {
                    newData = items.filter((product) => product.productID !== item.id)
                } else {
                    const product = {
                        "productID": item.id,
                        "product": item.name,
                        "remains": item.stocks[0] ? item.stocks[0].count : 0,
                        "facts": 0,
                        "difference": 0,
                        "mainPrice": item.price,
                        "sumDifference": 0,
                        "place": item.place ? item.place : "",
                        "article": item.article,
                        "code": item.code,
                        "stocks": item.stocks
                    }
                    newData.push(product)

                    this.setState({ show: false })

                    setTimeout(() => {
                        if (document.getElementById(`product_${item.id}`)) {
                            document.getElementById(`product_${item.id}`).focus()
                        }
                    }, 450)
                }
            }

            update(newData)
        }
    }

    updateValues = (value, id, type) => {
        let value_to_set = (type === 'place') ? "" : "0"
        if (value.length !== 0) {
            value_to_set = value
        }
        const { items, update } = this.props
        const index = items.findIndex((prod) => prod.productID === id)
        let demo = items
        if (type === 'facts') {
            demo[index].facts = parseFloat(value_to_set.match(/(\d+)/)[0])
        } else if (type === 'mainPrice') {
            demo[index].mainPrice = parseFloat(value_to_set.match(/(\d+)/)[0])
        } else if (type === 'place') {
            demo[index].place = value_to_set
        } else {

        }

        demo[index].difference = demo[index].facts - demo[index].remains
        demo[index].sumDifference = (demo[index].facts * demo[index].mainPrice) - (demo[index].remains * demo[index].mainPrice)

        update(demo)
    }

    search = (text, key) => {
        let data = {}
        if (key === 'name') {
            data = {
                searchName: text
            }
            this.setState({ searchName: text })
        } else if (key === 'article') {
            data = {
                searchArticle: text
            }
            this.setState({ searchArticle: text })
        } else if (key === 'search') {
            data = {
                search: text
            }
            this.setState({ search: text })
        }
        else {
            data = {
                searchCode: text
            }
            this.setState({ searchCode: text })
        }

        const { search } = this.props
        if (this._timeout) {
            clearTimeout(this._timeout)
        }

        this._timeout = setTimeout(() => {
            this.setState({ activeIndex: -1 })
            search(data)
        })
    }

    render = () => {

        const { show, search, searchName, searchArticle, searchCode, activeIndex } = this.state
        const { list, items } = this.props

        return (
            <div className='select-menu'>
                <div className='items-list' ref={this.divRefClick}>
                    <div className='items-list-inner' onClick={() => this.setState({ show: true })}>
                        {
                            items.length > 0 ?
                                <div className='product'>
                                    <div className='input-values full header'>
                                        <p>Название</p>{/*  */}
                                        <p>Артикул</p>
                                        <p>Код</p>
                                        <p>Ост.</p>{/*  */}
                                        <p>Факт. ост</p>
                                        <p>Разница кол-во</p>
                                        <p>Разница в сумме</p>
                                        <p>Цена</p>
                                        <p>Место</p>
                                        {/* <div /> */}
                                    </div>
                                </div>
                                : null
                        }
                        {
                            items.map((item, index) => {
                                return (
                                    <div key={`sp_${index}`} className='product'>
                                        <div className='input-values full similar-height'>
                                            <p className='count special' >{item.product}</p>
                                            <p className='count special'>{item.article ? item.article : ''}</p>
                                            <p className='count special' >{item.code ? item.code : ''}</p>
                                            <input type='text' value={item.remains} disabled className='count' />
                                            <input type='text' id={`product_${item.productID}`} value={item.facts} className='count' onChange={event => this.updateValues(event.target.value, item.productID, 'facts')} />
                                            <input type='text' value={item.difference} className='count' disabled />
                                            <input type='text' value={item.sumDifference} className='count' disabled />
                                            <input type='text' value={item.mainPrice} className='mainPrice' onChange={event => this.updateValues(event.target.value, item.productID, 'mainPrice')} />
                                            <input type='text' value={item.place} className='count' onChange={event => this.updateValues(event.target.value, item.productID, 'place')} />
                                            <img src='/pictures/close.png' className='close' alt='test' onClick={() => this.setToList(item)} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='img-block'>
                        <img src='/pictures/menu/arrow.png' alt='test' />
                    </div>

                </div>


                <Modal
                    isOpen={show}
                    onRequestClose={() => this.setState({ show: false })}
                    style={customStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                >
                    <div className='select-dropdown multi' ref={this.divRef}>
                        <div className='inputs'>
                            <input
                                className='search-input'
                                type='text'
                                value={searchName} onChange={event => this.search(event.target.value, 'name')} placeholder="Название"
                            />
                            <input
                                className='search-input'
                                type='text'
                                value={searchArticle} onChange={event => this.search(event.target.value, 'article')} placeholder="Артикул"
                            />
                            <input
                                className='search-input'
                                type='text'
                                value={searchCode} onChange={event => this.search(event.target.value, 'code')} placeholder="Код"
                            />
                            <input
                                className='search-input'
                                type='text'
                                value={search} onChange={event => this.search(event.target.value, 'search')} placeholder="Еще"
                            />
                        </div>
                        <div className='select-item head'>
                            <p>Название</p>
                            <p>Артикул</p>
                            <p>Код</p>
                            <p>Остаток</p>
                            <p>Место</p>

                            <p>Роз.цена</p>
                            <p>Опт.цена</p>
                            <p>Закуп.цена</p>
                            <p>В списке</p>
                        </div>
                        {
                            list.map((item, index) => {
                                const active = activeIndex === index ? "active" : ''
                                const style = item.stocks[0] && item.stocks[0].count ? '' : "disabled"
                                return (
                                    <div key={`sp_${index}`} className={`select-item ${style} ${active}`} onClick={() => this.setToList(item)}>
                                        <p className='withScroll'>{item.name}</p>
                                        <p className='withScroll'>{item.article}</p>
                                        <p className='withScroll'>{item.code}</p>
                                        <p>{(item.stocks && item.stocks[0]) ? item.stocks[0].count : ''}</p>
                                        <p>{item.place ? item.place : 'fdfd'}</p>

                                        <p>{item.price}</p>
                                        <p>{item.salePrice}</p>
                                        <p>{item.mainPrice}</p>
                                        <p>{this.checkList(item.id) ? <img src='/pictures/check.png' alt='test' /> : <img className='hidden' src='/pictures/check.png' alt='test' />}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal>

            </div>
        )
    }

}

export default SelectMenuAccounting