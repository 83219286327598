import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Sweet Alert */
import Swal from 'sweetalert2'

/* REST API */
import { password_update } from '../../api/Manager'

/* Router */
import { Link } from "react-router-dom"


/*
    Page Register
*/
class Password extends Component {

    constructor() {
        super()

        this.state = {
            password: "",
            newPassword: "",
            repeat: "",
            loading: "",
            secure: false,
            secureNew: false,
            secureRepeat: false
        }
    }


    /* Register action */
    register = () => {

        const { password, newPassword, repeat } = this.state

        if (password === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите пароль', showConfirmButton: false, timer: 1500 })
            return
        }

        if (password.length < 8) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите пароль больше 8 символов', showConfirmButton: false, timer: 1500 })
            return
        }

        if (newPassword === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите новый пароль', showConfirmButton: false, timer: 1500 })
            return
        }

        if (newPassword.length < 8) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите новый пароль больше 8 символов', showConfirmButton: false, timer: 1500 })
            return
        }

        if (newPassword !== repeat) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Пароли не совпадают', showConfirmButton: false, timer: 1500 })
            return
        }

        this.setState({ loading: true })


        password_update({ password, newPassword }).then(response => {
            if (response.status === 200) {
                
                this.setState({
                    password: "",
                    repeat: "",
                    loading: "",
                    secure: false
                })

                Swal.fire({
                    icon: 'success',
                    title: 'Вы успешно изменили пароль',
                    text: '',
                    showConfirmButton: false,
                })

            }
            else {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
            }
        }).catch(() => {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }


    render = () => {

        const { password, newPassword, repeat, loading, secure, secureNew, secureRepeat } = this.state

        return (
            <div className="login-page">

                <div className="login-box">
                    <div className="form-box">
                        <img src="/pictures/lock.png" alt="Lock" />
                        <input placeholder="Старый пароль" type={secure ? "text" : "password"} value={password} onChange={event => this.setState({ password: event.target.value })} />
                        <div className="login-secure" onClick={() => this.setState({ secure: !secure })}>
                            {!secure ? <img className="hide" src="/pictures/hide.png" alt="Eye" /> : <img src="/pictures/show.png" alt="Eye" />}
                        </div>
                    </div>

                    <div className="form-box">
                        <img src="/pictures/lock.png" alt="Lock" />
                        <input placeholder="Новый пароль" type={secureNew ? "text" : "password"} value={newPassword} onChange={event => this.setState({ newPassword: event.target.value })} />
                        <div className="login-secure" onClick={() => this.setState({ secureNew: !secureNew })}>
                            {!secureNew ? <img className="hide" src="/pictures/hide.png" alt="Eye" /> : <img src="/pictures/show.png" alt="Eye" />}
                        </div>
                    </div>

                    <div className="form-box">
                        <img src="/pictures/lock.png" alt="Lock" />
                        <input placeholder="Повторите пароль" type={secureRepeat ? "text" : "password"} value={repeat} onChange={event => this.setState({ repeat: event.target.value })} />
                        <div className="login-secure" onClick={() => this.setState({ secureRepeat: !secureRepeat })}>
                            {!secureRepeat ? <img className="hide" src="/pictures/hide.png" alt="Eye" /> : <img src="/pictures/show.png" alt="Eye" />}
                        </div>
                    </div>

                    <button onClick={() => this.register()} disabled={loading} className="login-button">
                        {loading ? <LoadingOutlined /> : "Изменить"}
                    </button>

                </div>

            </div>
        )
    }

}

export default Password