import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* Sweet Alert */
import Swal from 'sweetalert2'

/* REST API */
import { login } from '../../api/Manager'

/* Router */
import { Link } from "react-router-dom"





/*
    Page Login
*/
class Login extends Component {

    constructor() {
        super()

        this.state = {
            email: "",
            password: "",
            loading: "",
            secure: false
        }
    }


    /* Login action */
    login = () => {

        const { email, password } = this.state
        const { setToken } = this.props

        if (email === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите Email', showConfirmButton: false, timer: 1500 })
            return
        }

        if (!/^\S+@\S+\.\S+$/.test(email)) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неправильный Email', showConfirmButton: false, timer: 1500 })
            return
        }

        if (password === "") {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите пароль', showConfirmButton: false, timer: 1500 })
            return
        }

        if (password.length < 8) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Введите пароль больше 8 символов', showConfirmButton: false, timer: 1500 })
            return
        }

        this.setState({ loading: true })


        login({ email, password }).then(response => {
            if (response.status === 200) {
                setToken(response.data.token)
                localStorage.setItem('token', response.data.token)
            }
            else if (response.status === 404) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неверный Email и(или) пароль', showConfirmButton: false, timer: 1500 })
            }
            else if (response.status === 401) {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Неверный Email и(или) пароль', showConfirmButton: false, timer: 1500 })
            }
            else {
                Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
            }
        }).catch(() => {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Что-по пошло не так! Попробуйте позднее', showConfirmButton: false, timer: 1500 })
        }).finally(() => {
            this.setState({ loading: false })
        })
    }


    render = () => {

        const { email, password, loading, secure } = this.state

        return (
            <div className="login-page">

                <div className="login-box">

                    <div className="login-logo">
                        <img src="/pictures/icon.png" alt="Icon" />
                        <h2>T&N GROUP</h2>
                    </div>

                    <h2 className="login-title">Панель управление</h2>
                    <p className="login-text">Чтобы войти в систему, введите Email и пароль</p>

                    <div className="form-box">
                        <img src="/pictures/mail.png" alt="Mail" />
                        <input placeholder="Email" value={email} onChange={event => this.setState({ email: event.target.value })} />
                    </div>

                    <div className="form-box">
                        <img src="/pictures/lock.png" alt="Lock" />
                        <input placeholder="Пароль" type={secure ? "text" : "password"} value={password} onChange={event => this.setState({ password: event.target.value })} />
                        <div className="login-secure" onClick={() => this.setState({ secure: !secure })}>
                            {secure ? <img className="hide" src="/pictures/hide.png" alt="Eye" /> : <img src="/pictures/show.png" alt="Eye" />}
                        </div>
                    </div>

                    <button onClick={() => this.login()} disabled={loading} className="login-button">
                        {loading ? <LoadingOutlined /> : "Войти"}
                    </button>

                    <p className="login-link">У вас нет аккаунта? <Link to="/register">Создать аккаунт</Link></p>

                </div>

            </div>
        )
    }

}

export default Login