import axios from "axios"
import { env } from '../constants'


/* User */
export const list = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}user/list`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Register */
export const register = data => {
    const uri = `${env.startpoint}api/user/registration`
    return axios.post(uri, data).then(response => response).catch(error => error.response)
}

/* Partners */
export const admins = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}user/admins`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Count */
export const count = () => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}user/count`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* Remove */
export const remove = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}user/delete`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Status */
export const status = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}user/type`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Debt */
export const user_debt = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}user/debt`
    const headers = { headers: { Authorization: `Bearer ${token}` }, params: data }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}