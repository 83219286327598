import React, { Component } from "react"

/* Icons */
import { LoadingOutlined } from '@ant-design/icons'

/* REST API */
import { order_list } from '../../api/Statistics'

import { list as list_of_products } from "../../api/Product"

/* Pagination */
import { Pagination } from '../../components'

/* React Router */
import { Link } from "react-router-dom"

/* Moment */
import moment from "moment"

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

/* Helpers */
import { utils } from '../../helpers'

import { SelectMenuProduct } from "./widgets"

/* Permission */
import { Permission } from "../Auth"

/* Page Order Statistics */
class OrderStatistics extends Component {

    constructor() {
        super()

        this.state = {

            id: null,
            userId: null,

            loading: false,
            data: [],
            page: 1,
            count: 0,

            search: "",
            productID: null,
            products_all: [],
            selected_products: [],

            product: 0,

            searchMethod: null,

            permission: false,

            startDate: new Date(),
            endDate: new Date(),

            start_at: null,
            end_at: null,
        }
    }


    componentDidMount = () => {
        setTimeout(() => {
            const id = localStorage.getItem('demoProductId')
            const userId = localStorage.getItem('demoPartnerId')
            if (id) {
                let selected_products = [{ productID: id, name: '' }]
                this.setState({ selected_products, userId: userId, searchMethod:"product" })
                setTimeout(() => {
                    this.load()
                }, 200)
            }
        }, 100)

        this.products()
    }

    /* Load */
    load = (page = 1) => {

        const { selected_products, searchMethod } = this.state



        let data = {}

        if (selected_products[0] && searchMethod === 'product') {
            const productID = selected_products[0].productID
            data = { page, productID }
        } else {
            const { start_at, end_at } = this.state
            data = { page, startDate: start_at, endDate: end_at }
        }

        this.setState({ page, loading: true })

        order_list(data).then(response => {
            if (response.status === 200) {
                console.log(response.data)
                this.setState({ data: response.data.data, loading: false })
            } else if (response.status === 403) {
                this.setState({ permission: true })
            }
        })

    }

    products = (search = null) => {
        list_of_products({ ...search }).then(response => {
            if (response.status === 200) {
                const array = response.data.data.slice(0, 10)
                this.setState({ products_all: array })
            }
        })
    }

    setProduct = (data) => {
        this.setState({ selected_products: data })
        localStorage.removeItem('demoProductId')
        localStorage.removeItem('demoPartnerId')
        setTimeout(() => {
            if (data.length > 0) {
                this.load()
            }
        }, 250)
    }

    setSearchMethod = (event) => {
        this.setState({ searchMethod: event.target.value })
        localStorage.removeItem('demoProductId')
        localStorage.removeItem('demoPartnerId')
    }

    /* Search action */
    search = text => {
        this.setState({ search: text })

        if (text !== "") {
            this.setState({ loading: true })

            if (this._timeout) {
                clearTimeout(this._timeout)
            }

            this._timeout = setTimeout(() => {
                this.load(1, text.trim())
            }, 1000)
        }
    }



    filter = () => {
        const { startDate, endDate } = this.state

        const start_at = moment(startDate).format('YYYY-MM-DD')
        const end_at = moment(endDate).add(1, 'd').format('YYYY-MM-DD')

        this.setState({ start_at, end_at })

        setTimeout(() => {
            this.load()
        })
    }

    clearFilter = () => {
        this.setState({ start_at: null, end_at: null })
        setTimeout(() => {
            this.load()
        }, 250)
    }

    addZero = (number) => {
        if (parseInt(number) < 10) {
            return '0' + number
        }
        return number
    }


    /* Draw Top */
    _top = () => {

        const { count, products_all, selected_products, searchMethod, startDate, endDate, start_at, end_at } = this.state

        return (
            <div className="content-top stat">

                <div className="content-name stat">
                    <img src="/pictures/product.png" alt="Product" />
                    <p>Cтатистика продаж</p>
                    <span className={count === 0 ? "hide" : "show"}>{count}</span>
                </div>

                <p>Поиск</p>
                <select onChange={event => this.setSearchMethod(event)} defaultValue={0} value={searchMethod}>
                    <option value='date'>По дате</option>
                    <option value='product'>По товару</option>
                </select>

                {
                    searchMethod === "product" ?
                        <div className="content-form-box">
                            <SelectMenuProduct
                                list={products_all}
                                items={selected_products}
                                search={(data) => this.products(data)}
                                update={(data) => this.setProduct(data)}
                            />
                        </div>
                        :
                        <div className="statTime">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => this.setState({ startDate: date })}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd/MM/yyyy"
                                showConfirmButton
                                shouldCloseOnSelect
                            />
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => this.setState({ endDate: date })}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd/MM/yyyy"
                                showConfirmButton
                            />

                            {start_at && end_at && <div className="clear-button stat" onClick={() => this.clearFilter()}>Очистить фильтр</div>}

                            <div className="content-create">
                                <div className="content-create-button blue" onClick={() => this.filter()}>Фильтр</div>
                            </div>
                        </div>
                }
            </div>
        )
    }



    /* Draw Content */
    _content = () => {

        const { data, loading, page, userId, selected_products } = this.state
        if (loading) {
            return (
                <div className="content-element">
                    <LoadingOutlined />
                </div>
            )
        }

        if (data.length === 0) {
            return (
                <div className="content-element">
                    Здесь пока пусто
                </div>
            )
        }

        let productId = selected_products[0] ? selected_products[0].productID : null        

        return (
            <div className="content-data-box">
                {data.map((item, index) => {
                    let total = 0
                    item.items.forEach(product => {
                        if (productId) {
                            if (product.productID === parseInt(productId)) {
                                total += product.price * product.quantity
                            }  
                        }
                        
                    })
                    const is_admin_order = item.employee ? 'admin' : ''
                    const is_user_selected = (parseInt(userId) === item.userID) ? 'red' : ''
                    return (
                        <div className={`content-box-body ${is_admin_order} ${is_user_selected}`} key={`${index}`}>
                            <div className="content-item number">{index + 1 + (page - 1) * 10}</div>
                            <div className="content-item">{utils.check(item.number)}</div>
                            <div className="content-item">{utils.convertor(total)}</div>
                            <div className="content-item">{item.user ? item.user.name : ''}</div>
                            <div className="content-item">{item.deliveryStatus}</div>
                            <div className="content-item">{item.paymentMethod}</div>
                            <div className="content-item">{item.paymentStatus === 'paid' ? 'Оплачен' : item.paymentStatus === 'notpaid' ? 'Не оплачен' : 'Резерв'}</div>
                            <div className="content-item">{moment(item.boughtAt).format("DD.MM.YY")}</div>
                            <Link className="content-item-button blue" to={`/order/products/${item.id}`}>Посмотреть</Link>
                            <div className="content-item content-buttons name"></div>
                        </div>
                    )
                }
                )}
            </div>
        )
    }


    render = () => {

        const { count, page, search, permission } = this.state

        if (permission) {
            return <Permission />
        }

        return (
            <div className="content">

                {this._top()}
                <div className="content-box">
                    <div className="content-box-head">
                        <div className="content-head number">#</div>
                        <div className="content-head">Номер заказа</div>
                        <div className="content-head">Цена</div>
                        <div className="content-head">Партнер</div>
                        <div className="content-head">Статус</div>
                        <div className="content-head">Метод оплаты</div>
                        <div className="content-head">Статус оплаты</div>
                        <div className="content-head">Дата</div>
                        <div className="content-head">Продукты</div>
                        <div className="content-head name order-stat"></div>
                    </div>

                    {this._content()}

                    <Pagination size={10} page={page} total={count} onPageChange={page => this.load(page, search)} />

                </div>

            </div>
        )
    }

}

export default OrderStatistics