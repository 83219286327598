import React, { Component } from "react"

/* REST API */
import { create, view } from '../../api/Accounting'
import { list as list_of_products } from "../../api/Product"

/* Sweet alert */
import Swal from "sweetalert2"

/* Helpers */
import { withRouter } from '../../helpers'

/* Widgets */
import SelectMenuAccounting from "./widgets/SelectMenuAccounting"


/*
    Page Create Sell
*/
class CreateAccounting extends Component {


    constructor(props) {
        super(props)
        const { params } = this.props

        this.state = {
            id: params.id,
            total: '',
            products_all: [],
            selected_products: [],
        }
    }

    componentDidMount = () => {
        this.load()
        this.products()
    }

    /* Load Categories */

    load = () => {
        const { id } = this.state
        view({ accountingID: id }).then(response => {
            if (response.status === 200) {

                const data = response.data

                let products = []

                data.items.map((item, index) => {
                    const product = {
                        "productID": item.id,
                        "product": item.product,
                        "article": item.itemProduct ? item.itemProduct.article : '',
                        "code": item.itemProduct ? item.itemProduct.code : '',
                        "remains": item.remains,
                        "facts": item.facts,
                        "difference": item.difference,
                        "mainPrice": item.itemProduct ? item.itemProduct.mainPrice : 0,
                        "sumDifference": item.sumDifference,
                        "place": item.place ? item.place : "",
                    }
                    products.push(product)
                })

                this.setState({
                    selected_products: products,
                    total: data.totalDifference
                })
            }
        })

    }

    products = (search = null) => {
        list_of_products({ ...search }).then(response => {
            if (response.status === 200) {
                const array = response.data.data.slice(0, 10)
                this.setState({ products_all: array })
            }
        })
    }

    /* Create */
    create = () => {

        const { selected_products, total } = this.state
        const { navigate } = this.props

        if (selected_products.length === 0) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите продукт', showConfirmButton: false, timer: 1500 })
            return
        }

        const data = {
            totalDifference: total,
            items: selected_products,
        }

        create(data).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Успешна добавлена',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        navigate('/accounting')
                    }
                })

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })
    }

    setProducts = (list) => {
        let total = 0
        list.map(item => {
            total += item.sumDifference
        })
        this.setState({ selected_products: list, total })
    }


    /* Draw Top */
    _top = () => {

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/category.png" alt="Category" />
                    <p>Изменить учет</p>
                </div>

            </div>
        )
    }

    render = () => {

        const { products_all, selected_products, total } = this.state

        return (
            <div className="content">
                {this._top()}

                <div className="content-box">

                    <div className="content-form-box">

                        <div className="content-form-half full">

                            <p>Название склада</p>
                            <input type="text" value={total} disabled placeholder="Общая разница" />


                            <p>Продукты</p>
                            <SelectMenuAccounting
                                list={products_all}
                                items={selected_products}
                                search={(value) => this.products(value)}
                                update={(data) => this.setProducts(data)}
                            />
                        </div>
                    </div>

                    <div className="content-form-bottom">
                        <div onClick={() => this.create()} className="content-form-button">Сохранить</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(CreateAccounting)