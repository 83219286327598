import axios from "axios"
import { env } from '../constants'


/* City */
export const list = (data) => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}city/list`
    const headers = { headers: { Authorization: `Bearer ${token}` }, params: data }
    return axios.get(uri, headers).then(response => response).catch(error => error.response)
}

/* Update */
export const update = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}city/edit`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Create */
export const create = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}city/create`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Remove */
export const remove = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}city/delete`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}

/* Status */
export const status = data => {
    const token = localStorage.getItem("token")
    const uri = `${env.startpoint}city/status`
    const headers = { headers: { Authorization: `Bearer ${token}` } }
    return axios.post(uri, data, headers).then(response => response).catch(error => error.response)
}
