import React, { Component } from "react"

/* REST API */
import { create } from '../../api/Order'
import { list as list_of_partners, user_debt } from "../../api/User"

/* Sweet alert */
import Swal from "sweetalert2"

/* Helpers */
import { utils, withRouter } from '../../helpers'

/* Widgets */
import { SelectItem, SelectMenu } from "../../widgets"


/*
    Page Create Sell
*/
class CreateSell extends Component {


    constructor(props) {
        super(props)

        const { params } = this.props

        this.state = {
            partners: [],
            partner: null,
            partnerID: null,
            productID: 0,

            partner_debt: 0,
            partner_total: 0,

            sale: 0,

            comment: '',

            paymentMethod: "",

            rezerv: parseInt(params.rezerv),

            selected_products: [],

            refund: false,
        }
    }

    componentDidMount = () => {
        this.load()
        this.partners()
    }

    /* Load Categories */
    load = () => {
    }

    partners = (search = null) => {
        list_of_partners({ search }).then(response => {
            if (response.status === 200) {
                this.setState({ partners: response.data.data })
            }
        })
    }

    /* Create */
    create = () => {
        const { selected_products, partner, paymentMethod, rezerv, comment, refund, sale } = this.state
        const { navigate } = this.props
        if (!partner) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите партнера', showConfirmButton: false, timer: 1500 })
            return
        }

        if (selected_products.length === 0) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите продукт', showConfirmButton: false, timer: 1500 })
            return
        }

        if (paymentMethod.length === 0) {
            Swal.fire({ icon: 'error', title: 'Ошибка!', text: 'Выберите метод оплаты', showConfirmButton: false, timer: 1500 })
            return
        }

        let products = []
        let total = 0

        selected_products.forEach((item, index) => {
            const obj = {
                id: item.productID,
                name: item.name,
                price: item.price,
                quantity: item.quantity,
                salePrice: 0,
            }
            total += item.price * item.quantity
            products.push(obj)
        })

        if (refund) {
            total *= -1
        }
        
        if (this.isNumber(Number(sale))) {
            total = total - (total * Number(sale))
        }

        let data = {
            userID: partner.id,
            products,
            total,
            paymentMethod,
            comment,
            sale: this.isNumber(Number(sale)) ? Number(sale): 0
        }

        if (rezerv === 1) {
            data.paymentStatus = 'reserve'
        } else if (refund) {
            data.paymentStatus = 'reserve'
        }

        create(data).then(response => {
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Успешна добавлена',
                    showConfirmButton: false,
                    timer: 1500,
                    didClose: () => {
                        if (rezerv === 2) {
                            navigate('/refund')
                        } else if (rezerv === 1) {
                            navigate('/rezerv')
                        }
                        else {
                            navigate('/sells')
                        }

                    }
                })

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Что-то пошло не так! Попробуйте позднее',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }).catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Что-то пошло не так! Попробуйте позднее',
                showConfirmButton: false,
                timer: 1500
            })
        })

    }

    setPartner = (data) => {
        this.setState({ partner: data, partnerID: data.id, selected_products: [] })

        user_debt({ userID: data.id }).then(response => {
            if (response.status === 200) {
                this.setState({ partner_debt: response.data.totalDebt, partner_total: response.data.totalAmount })
            }
        })

    }

    setRefund = (data) => {
        this.setState({ refund: data.target.checked })
    }


    /* Draw Top */
    _top = () => {

        const { rezerv } = this.state

        return (
            <div className="content-top">

                <div className="content-name">
                    <img src="/pictures/category.png" alt="Category" />
                    <p>Добавить {rezerv === 2 ? 'Возврат' : rezerv === 1 ? 'Резервный заказ' : 'продажу'}</p>
                </div>

            </div>
        )
    }

    isNumber(value) {
        return typeof value === 'number' && isFinite(value);
    }

    render = () => {

        const { partners, partner, selected_products, partner_debt, partner_total, comment, sale } = this.state

        let total = 0

        selected_products.forEach(item => {
            total += item.price * item.quantity
        })

        if (this.isNumber(Number(sale))) {
            total = total - (total * Number(sale))
        }



        return (
            <div className="content">
                {this._top()}

                <div className="content-box">

                    <div className="content-form-box">

                        <div className="content-form-half">

                            <p>Партнер</p>
                            <SelectItem
                                type={true}
                                list={partners}
                                partner={partner}
                                search={(value) => this.partners(value)}
                                setPartner={(data) => this.setPartner(data)}
                            />

                            <p>Метод оплаты</p>
                            <select onChange={event => this.setState({ paymentMethod: event.target.value })}>
                                <option seleted="true" value={0}>Не выбран</option>
                                <option value='debt'>Долг</option>
                                <option value='cash'>Наличными</option>
                                {/* <option value='qr'>QR</option> */}
                            </select>

                            <p>Описание</p>
                            <textarea value={comment} onChange={event => this.setState({ comment: event.target.value })} placeholder="Введите комментарий"></textarea>

                            <p>Резерв</p>
                            <input className="order-checkbox" onChange={data => this.setRefund(data)} type="checkbox"></input>
                            
                        </div>
                        <div className="content-form-half">

                            <p>Долг партнера</p>
                            <input type="text" value={utils.convertor(partner_debt)} disabled />

                            <p>Общая сумма закупок</p>
                            <input type="text" value={utils.convertor(partner_total)} disabled />

                            <p>Скидка</p>
                            <input type="text" value={sale} onChange={event => this.setState({ sale: event.target.value })} placeholder="Пример: 0.6"></input>

                            <p>Общая сумма</p>
                            <input type="text" value={total} disabled placeholder="Общая сумма"></input>
                        </div>
                    </div>
                    <div className="content-form-box">
                        <div className="content-form-half full">
                            {
                                partner ?
                                    <>
                                        <p>Продукты</p>
                                        <SelectMenu
                                            orderMenu={true}
                                            items={selected_products}
                                            partner={partner}
                                            search={(value) => this.products(value)}
                                            update={(data) => this.setState({ selected_products: data })}
                                        />
                                    </>
                                    : null
                            }
                        </div>
                    </div>

                    <div className="content-form-bottom">
                        <div onClick={() => this.create()} className="content-form-button">Добавить</div>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(CreateSell)