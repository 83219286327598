import React, { Component } from 'react'
import Modal from 'react-modal';
import { utils, withRouter } from '../../helpers';

import { list as list_of_products } from "../../api/Product"

const customStyles = {
    content: {
        transform: 'translate(calc((100vw - 1220px) / 2), 10%)',
        inset: 'auto',
        padding: '10px'
    },
};


let filterTypes = {
    "name": "simple",
    "article": "simple",
    "code": "simple",
    "stocks": "simple",
    "price": "simple",
    "salePrice": "simple",
    "mainPrice": "simple"
}

class SelectMenu extends Component {
    constructor() {
        super()
        this.state = {
            show: false,
            searchData: {},
            search: '',
            searchName: '',
            searchArticle: '',
            searchCode: '',

            activeIndex: -1,

            list: [],

            currentPage: 1,
            needToPaginate: true,
            loading: false
        }

        this.divRef = React.createRef();
        this.divRefClick = React.createRef();
        this.divRefInnerClick = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener("keydown", this.callback)
        this.products(null, true)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    products = (search = null, update = false) => {
        const { currentPage, list } = this.state
        if (search) {
            this.setState({ searchData: search })
        }
        setTimeout(() => {
            let page = 1
            if (!update) {
                page = currentPage + 1
                this.setState({ currentPage: currentPage + 1 })
            } else {
                this.setState({ currentPage: 1, needToPaginate: true })
            }
            list_of_products({ ...search, page }).then(response => {
                if (response.status === 200) {
                    let array = list
                    if (page === 1) {
                        array = response.data.data.slice(0, 10)
                    } else {
                        array = array.concat(response.data.data)
                        if (response.data.data.length < 10) {
                            this.setState({ needToPaginate: false })
                        }
                    }

                    this.setState({ list: array })
                }
            })
        }, 250)
    }

    handleScroll = () => {
        if (this.divRef.current && this.state.needToPaginate) {
            const { scrollTop, scrollHeight, clientHeight } = this.divRef.current;
            if (scrollTop + clientHeight > scrollHeight - 1) {
                this.products(null, false)
            }
        }
    };

    callback = (event) => {
        const { activeIndex, show, list } = this.state

        if (list.length > 0) {
            if (event.code === 'Enter') {
                if (show) {
                    if (activeIndex !== -1) {
                        this.setToList(list[activeIndex])
                    } else {
                        this.setState({ activeIndex: 0 })
                    }
                } else {
                    this.setState({ show: true })
                }

            }
            if (event.code === 'ArrowUp' && show) {
                if (activeIndex - 1 >= 0) {
                    this.setState({ activeIndex: activeIndex - 1 })
                }
            } else if (event.code === 'ArrowDown' && show) {
                if (list[activeIndex + 1]) {
                    this.setState({ activeIndex: activeIndex + 1 })
                }
            }
        }

    }

    handleClickOutside = (event) => {
        const { disabled } = this.props
        if (this.divRef.current && !this.divRef.current.contains(event.target)) {
            this.setState({
                show: false,
            });
        } else {
            if (this.divRefClick.current.contains(event.target) && !disabled) {
                this.setState({
                    show: true,
                });
            }
        }
    };

    checkList = (id) => {
        const { items } = this.props
        if (items.filter((product) => product.productID === id).length > 0) {
            return true
        } else {
            return false
        }
    }

    getProductInform = (product, partner) => {
        localStorage.setItem('demoProductId', product)
        localStorage.setItem('demoPartnerId', partner.id)
        setTimeout(() => {
            window.open('/statistics/order')
        }, 250)
    }

    setToList = (item) => {
        const { items, update, partner } = this.props

        if (item && item.stocks[0] && item.stocks[0].count) {
            let newData = items

            if (item.productID) {
                newData = items.filter((product) => product.productID !== item.productID)
            } else {
                if (items.filter((product) => product.productID === item.id).length > 0) {
                    newData = items.filter((product) => product.productID !== item.id)
                } else {
                    const product = {
                        "productID": item.id,
                        "name": item.name,
                        "price": (partner && partner.type) ? item.salePrice : item.price,
                        "quantity": 0,
                        "stocks": item.stocks,
                        "code": item.code,
                        "article": item.article
                    }

                    newData.push(product)

                    // this.setState({ show: false })

                    setTimeout(() => {
                        if (document.getElementById(`product_${item.id}`)) {
                            document.getElementById(`product_${item.id}`).focus()
                        }
                    }, 450)
                }
            }

            update(newData)
        }
    }

    updateValues = (tagetValue, id, type) => {
        const value = tagetValue.replace(/\D/g, "");
        console.log(value, id, type)
        let value_to_set = '0'
        if (value !== 0 && value.length !== 0 && value !== " " && value !== undefined && value !== null && value !== Object) {
            value_to_set = value
        }
        const { items, update } = this.props
        const index = items.findIndex((prod) => prod.productID === id)
        let demo = items
        if (type === 'quantity') {
            demo[index].quantity = parseFloat(value_to_set.match(/(\d+)/)[0])
        } else {
            demo[index].price = parseFloat(value_to_set.match(/(\d+)/)[0])
        }
        update(demo)
    }

    search = (text, key) => {

        let data = {}
        if (key === 'name') {
            data.searchName = text
            this.setState({ searchName: text })
        } else if (key === 'article') {
            data.searchArticle = text
            this.setState({ searchArticle: text })
        } else if (key === 'search') {
            data.search = text
            this.setState({ search: text })
        } else if (key === 'code') {
            data.searchCode = text
            this.setState({ searchCode: text })
        }

        if (this._timeout) {
            clearTimeout(this._timeout)
        }

        this._timeout = setTimeout(() => {
            this.setState({ activeIndex: -1 })
            this.products(data, true)
        }, 1000)
    }

    filter = (type) => {
        const { list } = this.state
        if (filterTypes[type] === 'simple' || filterTypes[type] === 'dec') {
            filterTypes[type] = 'inc'
            list.sort(function (a, b) {
                if (a[type] < b[type]) {
                    return -1;
                }
                if (a[type] > b[type]) {
                    return 1;
                }
                return 0;
            });
        } else {
            filterTypes[type] = 'dec'
            list.sort(function (a, b) {
                if (a[type] > b[type]) {
                    return -1;
                }
                if (a[type] < b[type]) {
                    return 1;
                }
                return 0;
            });
        }
        this.setState({ list })
    }

    filterStock = (type = 'stocks') => {
        const { list } = this.state
        if (list.length > 0 && list[0].stocks && list[0].stocks.length > 0) {
            if (filterTypes[type] === 'simple' || filterTypes[type] === 'dec') {
                filterTypes[type] = 'inc'
                list.sort(function (a, b) {
                    if (a[type][0].count < b[type][0].count) {
                        return -1;
                    }
                    if (a[type][0].count > b[type][0].count) {
                        return 1;
                    }
                    return 0;
                });
            } else {
                filterTypes[type] = 'dec'
                list.sort(function (a, b) {
                    if (a[type][0].count > b[type][0].count) {
                        return -1;
                    }
                    if (a[type][0].count < b[type][0].count) {
                        return 1;
                    }
                    return 0;
                });
            }
            this.setState({ list })
        }
    }

    render = () => {

        const { list, show, search, searchName, searchArticle, searchCode, activeIndex } = this.state
        const { items, partner, orderMenu, disabled, isEdit } = this.props

        const isDisabled = disabled ? true : false

        const style = isEdit ? 'edit' : ''

        return (
            <div className='select-menu'>
                <div className='items-list' ref={this.divRefClick}>
                    <div className='items-list-inner' onClick={() => { if (!isDisabled) { this.setState({ show: true }) } }}>
                        {
                            items.length > 0 ?
                                <div className={`product menu-mini header ${style}`}>
                                    <div className='number'>#</div>
                                    <div className={`menu-text big ${style}`}>Название</div>
                                    <div className='menu-text small'>Код</div>
                                    <div className='menu-text small'>Артикул</div>

                                    <div className='input-values header'>
                                        <p>Кол-во</p>
                                        <p>Цена (₸)</p>
                                    </div>
                                </div>
                                : null
                        }

                        {
                            items.map((item, index) => {
                                return (
                                    <div key={`sp_${index}`} className={`product menu-mini ${style}`}>
                                        <div className='number'>{index + 1}</div>
                                        <div className={`inOneLine menu-text big ${style}`}>

                                            {item.name}
                                            {orderMenu ? <img src='/pictures/point.png' className='point' alt='point' onClick={() => this.getProductInform(item.productID, partner)} /> : null}
                                        </div>
                                        <div className='menu-text small'>{item.code}</div>
                                        <div className='menu-text small'>{item.article}</div>

                                        <div className='input-values'>
                                            <input type='text' disabled={isDisabled} id={`product_${item.productID}`} value={(item.quantity)} className='quantity' onChange={event => this.updateValues(event.target.value, item.productID, 'quantity')} />
                                            <input type='text' disabled={isDisabled} value={utils.numberWithSpaces(item.price)} className='price' onChange={event => this.updateValues(event.target.value, item.productID, 'price')} />
                                            {!isDisabled ? <img src='/pictures/close.png' className='close' alt='a' onClick={() => this.setToList(item)} /> : null}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='img-block'>
                        <img src='/pictures/menu/arrow.png' alt='a' />
                    </div>

                </div>
                <Modal
                    isOpen={show}
                    onRequestClose={() => this.setState({ show: false })}
                    style={customStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                >
                    <div className='select-dropdown multi' ref={this.divRef} onScroll={this.handleScroll}>
                        <div className='inputs'>
                            <input
                                className='search-input'
                                type='text'
                                value={searchName} onChange={event => this.search(event.target.value, 'name')} placeholder="Название"
                            />
                            <input
                                className='search-input'
                                type='text'
                                value={searchArticle} onChange={event => this.search(event.target.value, 'article')} placeholder="Артикул"
                            />
                            <input
                                className='search-input'
                                type='text'
                                value={searchCode} onChange={event => this.search(event.target.value, 'code')} placeholder="Код"
                            />
                            <input
                                className='search-input'
                                type='text'
                                value={search} onChange={event => this.search(event.target.value, 'search')} placeholder="Еще"
                            />
                        </div>
                        <div className='select-item head'>
                            <p className={`clickable`} onClick={() => this.filter('name')}>Название</p>
                            <p className='clickable' onClick={() => this.filter('article')}>Артикул</p>
                            <p className='clickable' onClick={() => this.filter('code')}>Код</p>
                            <p className='clickable' onClick={() => this.filterStock('stocks')}>Остаток</p>
                            <p>Место</p>

                            <p className='clickable' onClick={() => this.filter('price')}>Роз.цена</p>
                            <p className='clickable' onClick={() => this.filter('salePrice')}>Опт.цена</p>
                            <p className='clickable' onClick={() => this.filter('mainPrice')}>Закуп.цена</p>
                            <p>В списке</p>
                        </div>
                        {
                            list.map((item, index) => {
                                const active = activeIndex === index ? "active" : ''
                                const style = item.stocks[0] && item.stocks[0].count ? '' : "disabled"
                                return (
                                    <div key={`sp_${index}`} className={`select-item ${style} ${active}`} onClick={() => this.setToList(item)}>
                                        <p>{item.name}</p>
                                        <p className='withScroll'>{item.article}</p>
                                        <p>{item.code}</p>
                                        <p>{(item.stocks && item.stocks[0]) ? item.stocks[0].count : ''}</p>
                                        <p>{item.place ? item.place : ''}</p>

                                        <p>{utils.convertor(item.price)}</p>
                                        <p>{utils.convertor(item.salePrice)}</p>
                                        <p>{utils.convertor(item.mainPrice)}</p>
                                        <p>{this.checkList(item.id) ? <img src='/pictures/check.png' alt='a' /> : <img className='hidden' src='/pictures/check.png' alt='a' />}</p>
                                    </div>
                                )
                            })
                        }
                        <div className='space-bottom' />
                    </div>
                </Modal>
            </div>
        )
    }

}

export default withRouter(SelectMenu)